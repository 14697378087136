import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import QuestionnaireControlScreen from "./QuestionnaireControlScreen";
import SocialOperator from "../Social/SocialOperator";
import LightShowHome from "../LightShow/LightShowHome";
import { COLORS } from "../../utils/colors";

export default function EventInteractionControl() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="eicContainer">
      <div
        className="eicButtonsContainer"
        style={{ backgroundColor: COLORS.eventDetailBackground }}
      >
        <Box>
          <Button
            className={activeTab === 0 ? "eicActiveTabButton" : "eicTabButton"}
            onClick={() => handleTabChange(0)}
            variant="contained"
          >
            Social Scroll
          </Button>
          <Button
            className={activeTab === 1 ? "eicActiveTabButton" : "eicTabButton"}
            onClick={() => handleTabChange(1)}
            variant="contained"
          >
            Question Games
          </Button>
          <Button
            className={activeTab === 2 ? "eicActiveTabButton" : "eicTabButton"}
            onClick={() => handleTabChange(2)}
            variant="contained"
          >
            Light Show Control
          </Button>
        </Box>
      </div>
      <div className="eicContentContainer">
        {activeTab === 0 && (
          <div className="eicTabDiv">
            <SocialOperator />
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <QuestionnaireControlScreen />
          </div>
        )}
        {activeTab === 2 && (
          <div className="eicTabDiv">
            <LightShowHome />
          </div>
        )}
      </div>
    </div>
  );
}
