import React, { useEffect, useRef, useState } from "react";
import { connect } from "redux-zero/react";
import CustomModal from "./Modal";
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  Divider,
  Tooltip,
  Switch,
  Typography,
} from "@mui/material";
import {
  AddAPhotoOutlined as AddAPhotoOutlinedIcon,
  Close,
} from "@mui/icons-material";
import AdPreviewBox from "../../screens/AdsSetupManager/AdPreviewBox";
import "./styles.css";
import { COLORS } from "../../utils/colors";
import { getMediaMimeType } from "../../utils/mediaFunctions";

function getImageUri(data) {
  return data && data.brandingOverlayImageURI
    ? data.brandingOverlayImageURI
    : data.brandingOverlayImage;
}

function getBrandingEnabled(data) {
  return data && data.brandingOverlayImageEnabled
    ? data.brandingOverlayImageEnabled
    : false;
}

export default connect((state) => {
  return {
    currentUser: state.currentUser,
  };
}, null)(function ({
  modalVisible,
  handleCloseModal,
  brandingModal,
  setBrandingModal,
  currentUser,
}) {
  const [brandingModalObj, setBrandingModalObj] = useState(null);

  const [imageDataUri, setImageDataUri] = useState();
  let checkedStatus = useRef(false);

  const [mimeType, setMimeType] = useState("image");
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setBrandingModalObj(brandingModal);
    setImageDataUri(getImageUri(brandingModal));
    checkedStatus.current = getBrandingEnabled(brandingModal);

    getMediaMimeType(getImageUri(brandingModal)).then((type) => {
      setMimeType(type);
    });
  }, [brandingModal]);

  const delegateClickToFileElement = () =>
    document.getElementById(`selectBrandingOverlayImage`).click();

  const handleImageUpload = async (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      setMimeType(file.type);
      onChangeValues(reader.result, checkedStatus.current);
    };
    reader.readAsDataURL(file);
  };

  const onChangeValues = (uri, status) => {
    let liveAdObj = brandingModalObj;
    if (uri && uri.split(":")[0] === "data") {
      setImageDataUri(uri);
      liveAdObj.brandingOverlayImage = uri;
      liveAdObj.brandingOverlayImageURI = uri;
      liveAdObj._id = null;
    }
    liveAdObj.brandingOverlayImageEnabled = status;
    setBrandingModalObj(liveAdObj);
  };

  const handleRemove = () => {
    setImageDataUri(null);
    let modalObj = brandingModalObj;
    modalObj.brandingOverlayImage = null;
    modalObj.brandingOverlayImageURI = null;
    modalObj._id = null;
    setBrandingModalObj(modalObj);
  };

  return (
    <CustomModal
      open={modalVisible}
      handleClose={() => {
        handleCloseModal();
      }}
      handleSubmit={async () => {
        const brandingModalObjCopy = brandingModalObj;
        setBrandingModal(brandingModalObjCopy);
        handleCloseModal();
      }}
      title={"Set output overlay"}
    >
      <div className="advertParentDiv">
        <Card
          className="backgroundRoot"
          style={{ backgroundColor: COLORS.popupBackground }}
        >
          <CardContent className="adsCardContent">
            <Badge
              overlap="rectangular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={
                <div
                  className="badgeDiv"
                  style={{
                    display: imageDataUri ? null : "none",
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  onClick={handleRemove}
                >
                  <Tooltip title={"Remove"}>
                    <Avatar className="closeAvatar">
                      <Close />
                    </Avatar>
                  </Tooltip>
                </div>
              }
            >
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <div
                    className="badgeDiv"
                    style={{ marginRight: -70, marginBottom: -40 }}
                    onClick={delegateClickToFileElement}
                    onChange={(e) => handleImageUpload(e)}
                  >
                    <input
                      id={`selectBrandingOverlayImage`}
                      hidden
                      type="file"
                      accept="image/*, video/*"
                    />
                    <Tooltip title={"Select branding overlay water mark"}>
                      <Avatar
                        style={{ border: `1px solid ${COLORS.textHeader}` }}
                      >
                        <AddAPhotoOutlinedIcon />
                      </Avatar>
                    </Tooltip>
                  </div>
                }
              >
                <div onClick={() => imageDataUri && setShowPreview(true)}>
                  {mimeType.includes("video") ? (
                    <video
                      autoPlay
                      playsInline
                      loop
                      controls={false}
                      muted
                      src={imageDataUri || "#"}
                      disableRemotePlayback={true}
                      className="bgMedia"
                      style={{
                        borderColor: COLORS.contactAvatar,
                      }}
                    />
                  ) : (
                    <Avatar
                      src={imageDataUri || "#"}
                      variant={"square"}
                      className="bgMedia"
                      style={{
                        borderColor: COLORS.contactAvatar,
                      }}
                      imgProps={{
                        style: {
                          objectFit: "contain",
                        },
                      }}
                    />
                  )}
                </div>
              </Badge>
            </Badge>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                Enable overlay
              </Typography>
              <Switch
                defaultChecked={checkedStatus.current}
                onChange={(e) => {
                  checkedStatus.current = e.target.checked;
                  let modalObj = brandingModalObj;
                  modalObj.brandingOverlayImageEnabled = checkedStatus.current;
                  setBrandingModalObj(modalObj);
                }}
              />
            </div>
          </CardContent>
        </Card>
        <AdPreviewBox
          previewTitle={"OUTPUT OVERLAY PREVIEW"}
          previewOpen={showPreview}
          setPreviewOpen={(status) => {
            setShowPreview(status);
          }}
          adImageDataUri={imageDataUri}
          adMimeType={mimeType}
        />
      </div>
    </CustomModal>
  );
});
