import React, { useContext, useEffect, useState } from "react";
import "./SideBarLayout.css";
import { connect } from "redux-zero/react";
import { TopLayout } from "../../../Layout";
import {
  Switch,
  Typography,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Link, Edit } from "@mui/icons-material";
import actions from "../../../store/actions";
import { JOIN_EVENT_OPTIONS, getJoinEventLiveLink } from "../constants";
import { AppContext } from "../../../contexts/App.Context";
import { COLORS } from "../../../utils/colors";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({
  currentUser,
  event,
  doLogout,
  allowUsersData,
  overlayData,
  eventInteractionBtn,
  userScreenToggleBtn,
  socialOperatorBtn,
  gamifyBtn,
  lightshowBtn,
  metricsScreenToggleBtn,
  children,
  initialState,
}) => {
  const appContext = useContext(AppContext);
  const [userCounts, setUserCounts] = useState(initialState.userCounts);
  const navigate = useNavigate();
  const joinEventOptions = JOIN_EVENT_OPTIONS;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const directConnectLinkClicked = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuOptionSelected = (option) => {
    if (option.value < 0) return;
    navigator.clipboard.writeText(getJoinEventLiveLink(event._id, option));
    handleClose();
    appContext.triggerToast(true, {
      type: "success",
      message: "Link copied to clipboard.",
    });
  };

  useEffect(() => {
    setUserCounts(initialState.userCounts);
  }, [initialState.userCounts]);

  const location = useLocation();
  return (
    <div className="sidebar-container">
      <div
        className="sidebar"
        style={{ backgroundColor: COLORS.sideBarBackground }}
      >
        <TopLayout
          currentUser={currentUser}
          title={event.name}
          doLogout={doLogout}
        />

        <div className="profilePicture">
          <img src={event.eventImageUrl} alt="Profile" className="image" />
        </div>
        <div className="EventTitle">
          <div>{event.name}</div>
          <IconButton
            onClick={() => navigate("/editEvent", { state: { event } })}
            className="actionIconStyle"
            style={{
              position: "absolute",
              right: 6,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Tooltip title={"Edit Event"}>
              <Edit
                fontSize="small"
                className="actionIconStyle"
                style={{
                  color: COLORS.gray,
                  "&:hover": {
                    color: COLORS.textHeader,
                  },
                }}
              />
            </Tooltip>
          </IconButton>
        </div>
        <List className="drawerList">
          <ListItem
            sx={{
              color: COLORS.textHeader,
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            onClick={() =>
              allowUsersData.allowUsersStatusChanged(
                !allowUsersData.checkedStatus
              )
            }
          >
            <ListItemText primary="Allow users to join" />
            <Switch
              sx={{
                ".MuiSwitch-thumb": {
                  color: "red",
                  opacity: 1,
                },
                ".MuiSwitch-track": {
                  backgroundColor: "white",
                  opacity: 1,
                },
                "& .MuiSwitch-switchBase": {
                  "&.Mui-checked": {
                    "+ .MuiSwitch-track": {
                      backgroundColor: "white",
                      opacity: 1,
                    },
                    ".MuiSwitch-thumb": {
                      color: "green",
                      opacity: 1,
                    },
                  },
                },
              }}
              className="toggleSwitch"
              checked={allowUsersData.checkedStatus}
            />
          </ListItem>

          <ListItem
            sx={{
              color: COLORS.textHeader,
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            onClick={directConnectLinkClicked}
          >
            <ListItemText primary="Copy Connection Links" />
            <Link />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={(e) => {
                e.stopPropagation();
                handleClose();
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {joinEventOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    menuOptionSelected(option);
                  }}
                >
                  {option.value < 0 ? (
                    <div style={{ fontWeight: "bold" }}>{option.name}</div>
                  ) : (
                    <div>{option.name}</div>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </ListItem>

          <ListItem
            sx={{
              color: userScreenToggleBtn.selected
                ? COLORS.primary
                : COLORS.textLabel,
              backgroundColor: userScreenToggleBtn.selected
                ? COLORS.sideBarSelectedItemBackground
                : "transparent",
              "&:hover": {
                color: COLORS.textHeader,
                backgroundColor: COLORS.primary,
              },
              cursor: "pointer",
            }}
            onClick={userScreenToggleBtn.handleUserScreenToggle}
          >
            <ListItemText primary="CrowdVue Live" />
          </ListItem>

          <ListItem
            sx={{
              color: userScreenToggleBtn.productionSelected
                ? COLORS.primary
                : COLORS.textLabel,
              backgroundColor: userScreenToggleBtn.productionSelected
                ? COLORS.sideBarSelectedItemBackground
                : "transparent",
              "&:hover": {
                color: COLORS.textHeader,
                backgroundColor: COLORS.primary,
              },
              cursor: "pointer",
            }}
            onClick={userScreenToggleBtn.handleProdUserScreenToggle}
          >
            <ListItemText primary="StreamVue Production Hub" />
          </ListItem>

          <ListItem
            sx={{
              color: socialOperatorBtn.selected
                ? COLORS.primary
                : COLORS.textLabel,
              backgroundColor: socialOperatorBtn.selected
                ? COLORS.sideBarSelectedItemBackground
                : "transparent",
              "&:hover": {
                color: COLORS.textHeader,
                backgroundColor: COLORS.primary,
              },
              cursor: "pointer",
            }}
            onClick={socialOperatorBtn.handleSocialOperator}
          >
            <ListItemText primary="CanvasVue Social Wall" />
          </ListItem>

          <ListItem
            sx={{
              color: gamifyBtn.selected ? COLORS.primary : COLORS.textLabel,
              backgroundColor: gamifyBtn.selected
                ? COLORS.sideBarSelectedItemBackground
                : "transparent",
              "&:hover": {
                color: COLORS.textHeader,
                backgroundColor: COLORS.primary,
              },
              cursor: "pointer",
            }}
            onClick={gamifyBtn.handleGamify}
          >
            <ListItemText primary="PollVue Designer" />
          </ListItem>

          <ListItem
            sx={{
              color: lightshowBtn.selected ? COLORS.primary : COLORS.textLabel,
              backgroundColor: lightshowBtn.selected
                ? COLORS.sideBarSelectedItemBackground
                : "transparent",
              "&:hover": {
                color: COLORS.textHeader,
                backgroundColor: COLORS.primary,
              },
              cursor: "pointer",
            }}
            onClick={lightshowBtn.handleLightshow}
          >
            <ListItemText primary="LumiVue Show Control" />
          </ListItem>

          <ListItem
            sx={{
              color: metricsScreenToggleBtn.selected
                ? COLORS.primary
                : COLORS.textLabel,
              backgroundColor: metricsScreenToggleBtn.selected
                ? COLORS.sideBarSelectedItemBackground
                : "transparent",
              "&:hover": {
                color: COLORS.textHeader,
                backgroundColor: COLORS.primary,
              },
              cursor: "pointer",
            }}
            onClick={metricsScreenToggleBtn.handleMetricsScreenToggle}
          >
            <ListItemText primary="Event Metrics" />
          </ListItem>
        </List>

        {userCounts && (
          <div className="onlineUsersStrip">
            <div className="connectedUsers">
              <div className="usersDot" style={{ backgroundColor: "green" }} />
              <Typography
                className="userConnectionText"
                style={{ color: COLORS.textHeader }}
              >
                Connected users
              </Typography>
            </div>
            <Typography
              className="userConnectionCount"
              style={{ color: COLORS.textHeader }}
            >
              {userCounts.onlineUsers}
            </Typography>
            <div className="connectedUsers">
              <div className="usersDot" style={{ backgroundColor: "blue" }} />
              <Typography
                className="userConnectionText"
                style={{ color: COLORS.textHeader }}
              >
                Event Total
              </Typography>
            </div>
            <Typography
              className="userConnectionCount"
              style={{ color: COLORS.textHeader }}
            >
              {userCounts.totalUsers}
            </Typography>
          </div>
        )}
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    initialState: state.initialState,
  };
};

export default connect(mapStateToProps, actions)(Sidebar);
