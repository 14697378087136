import { CDN_URLS, URLS } from "../../constants";
import { TABS } from "../JoinEventLive/constants";

const JOIN_EVENT_LIVE = `${window.location.origin}/joinEventLive#`;
export const JOIN_EVENT_OPTIONS = [
  { name: "Output Links:", value: -1 },
  { name: "Super Screen Output", value: 14 },
  { name: "Social Media Output", value: 15 },
  { name: "Live User Camera Output", value: 16 },
  { name: "User Camera Links:", value: -2 },
  { name: "Geo User Cam", value: 1 },
  { name: "Geo Production Cam", value: 2 },
  { name: "Direct VIP Cam", value: 3 },
  { name: "Direct Production Cam", value: 4 },
  { name: "User Social Links:", value: -3 },
  { name: "Geo Social Send", value: 5 },
  { name: "Direct Social Send", value: 6 },
  { name: "Geo Social Scroll", value: 7 },
  { name: "Direct Social Scroll", value: 8 },
  { name: "Geo Production Social", value: 9 },
  { name: "Direct Production Social", value: 10 },
  { name: "Geo Quote Send", value: 19 },
  { name: "Direct Quote Send", value: 20 },
  { name: "User Game/Poll Links:", value: -4 },
  { name: "Geo Game/Poll", value: 11 },
  { name: "Direct Game/Poll", value: 12 },
  { name: "Generic Links:", value: -5 },
  { name: "Social Media JSON Data", value: 17 },
  { name: "Generic Join Event", value: 13 },
  { name: "Join Light Show", value: 18 },
];

export const getJoinEventLiveLink = (eventId, option) => {
  let linkTobeCopied = "";
  switch (option.value) {
    case 1:
      linkTobeCopied = `${JOIN_EVENT_LIVE}page=${TABS.LIVE}`;
      break;
    case 2:
      linkTobeCopied = `${JOIN_EVENT_LIVE}loadwithoptions=true&page=${TABS.LIVE}`;
      break;
    case 3:
      linkTobeCopied = `${JOIN_EVENT_LIVE}eventId=${eventId}&page=${TABS.LIVE}`;
      break;
    case 4:
      linkTobeCopied = `${JOIN_EVENT_LIVE}eventId=${eventId}&loadwithoptions=true&page=${TABS.LIVE}`;
      break;
    case 5:
      linkTobeCopied = `${JOIN_EVENT_LIVE}page=${TABS.SOCIAL_SEND}`;
      break;
    case 6:
      linkTobeCopied = `${JOIN_EVENT_LIVE}eventId=${eventId}&page=${TABS.SOCIAL_SEND}`;
      break;
    case 7:
      linkTobeCopied = `${JOIN_EVENT_LIVE}page=${TABS.SOCIAL_FEED}`;
      break;
    case 8:
      linkTobeCopied = `${JOIN_EVENT_LIVE}eventId=${eventId}&page=${TABS.SOCIAL_FEED}`;
      break;
    case 9:
      linkTobeCopied = `${JOIN_EVENT_LIVE}page=${TABS.SOCIAL}&loadwithoptions=true`;
      break;
    case 10:
      linkTobeCopied = `${JOIN_EVENT_LIVE}eventId=${eventId}&page=${TABS.SOCIAL}&loadwithoptions=true`;
      break;
    case 11:
      linkTobeCopied = `${JOIN_EVENT_LIVE}page=${TABS.GAMES}`;
      break;
    case 12:
      linkTobeCopied = `${JOIN_EVENT_LIVE}eventId=${eventId}&page=${TABS.GAMES}`;
      break;
    case 14:
      linkTobeCopied = `${URLS.SUPER_SCREEN_LINK}#${eventId}`;
      break;
    case 15:
      linkTobeCopied = URLS.SOCIAL_OUTPUT_LINK(eventId);
      break;
    case 16:
      linkTobeCopied = URLS.EVENT_SCREEN_LINK(eventId);
      break;
    case 17:
      linkTobeCopied = CDN_URLS.EVENT_JSON_DATA_LINK(eventId);
      break;
    case 18:
      linkTobeCopied = `${JOIN_EVENT_LIVE}eventId=${eventId}&page=${TABS.LIGHT_SHOW}`;
      break;
    case 19:
      linkTobeCopied = `${JOIN_EVENT_LIVE}page=${TABS.QUOTE_SEND}`;
      break;
    case 20:
      linkTobeCopied = `${JOIN_EVENT_LIVE}eventId=${eventId}&page=${TABS.QUOTE_SEND}`;
      break;
    default:
      linkTobeCopied = `${JOIN_EVENT_LIVE}`;
      break;
  }
  return linkTobeCopied;
};
