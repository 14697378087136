import React, { useState, useEffect, useRef } from "react";
import { IconButton, Switch, FormControlLabel } from "@mui/material";
import Moveable from "react-moveable";
import { Refresh } from "@mui/icons-material";
import { flushSync } from "react-dom";
import "./SuperScreen.css";
const SuperScreenInstance = ({
  src,
  _id,
  boundingRect,
  settings,
  isSwitchOn,
  transform = "",
  screenNumber,
  isEditEnabled,
}) => {
  const savedStyle = boundingRect
    ? {
        width: boundingRect.width,
        height: boundingRect.height,
        transform: transform,
      }
    : JSON.parse(localStorage.getItem(`style-${_id}`)) || {
        width: 300,
        height: 200,
        transform: "translate(20px, 20px)",
      };

  const [updatedStyle, setUpdatedStyle] = useState(savedStyle);
  const [border, setBorder] = useState("none");
  const [dragMode, setDragMode] = useState(true);
  const [isResizing, setIsResizing] = useState(false);
  const iframeRef = useRef(null);

  const handleChangeDragMode = () => {
    setDragMode(!dragMode);
  };
  const [random, setRandom] = useState(0);
  const reloadClicked = () => {
    setRandom(random + 1);
  };

  let targetRef = useRef(null);
  let movableRef = useRef(null);
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseClicked, setMouseClicked] = useState(false);
  const handleMouseEnter = () => {
    setBorder("1px solid red");
    setMouseEntered(true);
  };
  const handleMouseLeave = () => {
    setBorder("none");
    setMouseEntered(false);
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (targetRef.current.contains(e.target)) {
        setMouseClicked(true);
      } else {
        setMouseClicked(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <>
      <div
        ref={targetRef}
        id={_id}
        style={{
          transform: transform || updatedStyle.transform || "",
          width: boundingRect?.width || updatedStyle.width || 300,
          height: boundingRect?.height || updatedStyle.height || 300,
          top: boundingRect?.top || updatedStyle.top || 0,
          left: boundingRect?.left || updatedStyle.left || 0,
          border: mouseClicked ? "none" : border,
          zIndex: isSwitchOn ? 10 : "auto",
          position: "absolute",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="rect-target superscreeninstance"
      >
        <div className="ssDragText">
          <div className="ssDragTextInner">
            <span className="ssScreenIdText">
              {screenNumber ? `#${screenNumber}` : ""}
            </span>
            <div>
              <FormControlLabel
                sx={{ ml: 1, color: "white", fontSize: "10px" }}
                control={
                  <Switch
                    checked={dragMode}
                    onChange={handleChangeDragMode}
                    title={
                      dragMode
                        ? "Toggle to Change Settings"
                        : "Toggle to Drag Screen"
                    }
                    inputProps={{
                      "aria-label": "controlled",
                    }}
                  />
                }
              />
              <IconButton
                onClick={reloadClicked}
                style={{ cursor: "pointer" }}
                size="large"
                title="Reload Screen Content"
              >
                <Refresh sx={{ color: "white" }} />
              </IconButton>
            </div>
          </div>
        </div>
        {dragMode ? <div className="handle ssDraggingStyle" /> : ""}
        <iframe
          key={random}
          className={`ssIframeStyle${isResizing ? " pointerEventsNone" : ""}`}
          title={_id}
          src={src}
          sandbox="allow-scripts allow-same-origin"
          name={_id}
          ref={iframeRef}
          onLoad={(e) => {
            if (e.target && e.target.contentWindow) {
              e.target.contentWindow.postMessage(settings);
            }
          }}
        ></iframe>
        <div className="dragText" style={{ opacity: mouseEntered ? 1 : 0 }}>
          <div
            className="ssDragTextInner"
            style={{ color: "rgba(200, 200, 200, 0.8)" }}
          >
            click here to resize
          </div>
        </div>
      </div>

      <Moveable
        ref={movableRef}
        target={targetRef}
        origin={false}
        originDraggable={false}
        draggable={isEditEnabled}
        onDragStart={(e) => {
          setIsResizing(true);
        }}
        onDragEnd={(e) => {
          setIsResizing(false);
        }}
        onDrag={(e) => {
          e.target.style.transform = e.transform;
          setUpdatedStyle({
            width: updatedStyle.width,
            height: updatedStyle.height,
            transform: e.transform,
          });
        }}
        resizable={isEditEnabled}
        onResizeStart={(e) => {
          setIsResizing(true);
        }}
        onResizeEnd={(e) => {
          setIsResizing(false);
        }}
        onResize={(e) => {
          e.target.style.width = `${e.width}px`;
          e.target.style.height = `${e.height}px`;
          e.target.style.transform = e.drag.transform;
          setUpdatedStyle({
            width: e.width,
            height: e.height,
            transform: updatedStyle.transform,
          });
        }}
        rotatable={isEditEnabled && mouseClicked}
        onRotate={(e) => {
          e.target.style.transform = e.drag.transform;
          setUpdatedStyle({
            width: updatedStyle.width,
            height: updatedStyle.height,
            transform: e.drag.transform,
          });
        }}
        hideDefaultLines={!mouseClicked}
        renderDirections={mouseClicked ? undefined : []}
        flushSync={flushSync}
      />
    </>
  );
};

export default SuperScreenInstance;
