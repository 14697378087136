import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Drawer,
  IconButton,
  Box,
} from "@mui/material";
import {
  ArrowForwardIos,
  SettingsBackupRestore,
  ViewModule as GridIcon,
  Apps as Grid4Icon,
  CheckCircle,
} from "@mui/icons-material";
import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import UserPreviewView from "./UserPreviewView";
import { insights } from "../../../ApplicationInsightsService";
import Loader from "../../../components/Loader/Loader";
import "../styles.css";
import { globals } from "../globals";
import { liveUsersGlobalState } from "./liveUsersGlobalState";
import { COLORS } from "../../../utils/colors";

export function RenderOnlineUsers({
  event,
  usersType,
  onUserSelected,
  onUserBlocked,
}) {
  const [gridView, setGridView] = useState("grid3x3");
  const [isLiveUser, setIsLiveUser] = useState("");
  const [perPage, setPerPage] = useState(9); // Number of users per page

  const [onlineUsers, setOnlineUsers] = useState();
  const [activeUsers, setActiveUsers] = useState([]);
  const [lastEventUserId, setLastEventUserId] = useState("");
  const [userLoading, setUserLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  let selectedTab = 0;

  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    setActiveUsers([]);
    setActiveTab(usersType === "live" ? 0 : 2);
  }, [usersType]);

  useEffect(() => {
    getOnlineUsers(activeTab);
    return () => {
      liveUsersGlobalState.currentPreviewUserId = "";
    };
  }, [activeTab]);

  useEffect(() => {
    let intervalId;

    if (onlineUsers && onlineUsers?.json?.onlineUsers.length > 0) {
      setUserLoading(true);
      setActiveUsers(onlineUsers?.json?.onlineUsers);
    } else {
      // Call getOnlineUsers every 10 seconds
      intervalId = setInterval(() => {
        getOnlineUsers(activeTab);
      }, 10000);
      setActiveUsers([]);
      setLastEventUserId("");
    }
    globals.updateUserCounts();
    setUserLoading(false);
    // Clean up the interval when the condition is met or the component is unmounted
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [onlineUsers]);

  // Whenever the users change, detect if the current user
  // is not in there then close the pc and set noUserSelected.
  useEffect(() => {
    if (
      onlineUsers?.json?.totalOnlineUserCount > perPage &&
      activeUsers.length === perPage
    ) {
      setLastEventUserId(activeUsers[activeUsers.length - 1].eventUserId);
    }
    const userExists =
      activeUsers &&
      activeUsers.length &&
      activeUsers.find(
        (u) => u.eventUserId === liveUsersGlobalState.currentPreviewUserId
      );
    if (!userExists) {
      if (tabChangeOperation.current === true) {
        tabChangeOperation.current = false;
      } else {
        // If the current live user was also the current user.
        // Then we need to disconnect the screen too.
        if (
          liveUsersGlobalState.currentPreviewUserId != "" &&
          liveUsersGlobalState.currentOutputUserId ===
            liveUsersGlobalState.currentPreviewUserId
        ) {
          liveUsersGlobalState.currentOutputUserId = "";
          liveUsersGlobalState.sendCommandToMainScreen(event._id, `none`);
        }
      }
      liveUsersGlobalState.currentPreviewUserId = "";
      liveUsersGlobalState.reloadPreview({
        noUserSelection: true,
        loadingStatus: false,
      });
    }
    const ongoing_live_user_id = localStorage.getItem("ongoing_live_user_id");
    if (activeUsers.length > 0) {
      if (activeUsers.find((u) => u.eventUserId === ongoing_live_user_id)) {
        setIsLiveUser(ongoing_live_user_id);
        liveUsersGlobalState.currentOutputUserId = ongoing_live_user_id;
      }
    }
  }, [activeUsers]);

  useEffect(() => {
    if (gridView === "grid3x3") {
      setPerPage(9);
    } else {
      setPerPage(12);
    }
  }, [gridView]);

  const getOnlineUsers = async (selection, LastEventUserId = "") => {
    setLoading(true);
    selectedTab = activeTab;
    if (selectedTab !== selection) {
      setOnlineUsers();
      setActiveUsers([]);
      setLastEventUserId("");
    }

    const sourceTypes = ["geo", "vip", "production"];
    const sourceName = sourceTypes[selection];
    try {
      if (!navigator.onLine) return;
      let onlineUsers = await uvenuFetcher({
        method: "GET",
        url: URLS.GET_ONLINE_USERS(
          event._id,
          sourceName,
          LastEventUserId,
          perPage
        ),
      });
      setOnlineUsers(onlineUsers);
      setLoading(false);
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        info: "Exception in getting online users list",
        error: error,
        adminSessionId: globals.adminSessionId,
      });
    }
  };

  const OnResetCLick = () => {
    liveUsersGlobalState.currentPreviewUserId = "";
    tabChangeOperation.current = true;
    setUserLoading(true);
    setOnlineUsers();
    setActiveUsers([]);
    setLastEventUserId("");
    getOnlineUsers(activeTab);
  };

  const OnNextCLick = () => {
    liveUsersGlobalState.currentPreviewUserId = "";
    tabChangeOperation.current = true;
    setUserLoading(true);
    getOnlineUsers(activeTab, lastEventUserId);
  };

  const OnRefreshClick = () => {
    liveUsersGlobalState.currentPreviewUserId = "";
    liveUsersGlobalState.reloadPreview({
      noUserSelection: true,
      loadingStatus: false,
    });
    setUserLoading(true);
    if (lastEventUserId) getOnlineUsers(activeTab, lastEventUserId);
    else getOnlineUsers(activeTab);
  };

  liveUsersGlobalState.updateLiveUserStatus = (liveUserId) => {
    setIsLiveUser("");
    liveUsersGlobalState.currentOutputUserId = "";
    if (liveUserId === liveUsersGlobalState.currentPreviewUserId) {
      liveUsersGlobalState.currentPreviewUserId = "";
      liveUsersGlobalState.reloadPreview({
        noUserSelection: true,
        loadingStatus: false,
      });
    }
  };

  liveUsersGlobalState.onCurrentStreamChanged = (userId) => {
    setIsLiveUser(userId);
    localStorage.setItem("ongoing_live_user_id", userId);
  };

  const handleGridViewChange = (newGridView) => {
    setGridView(newGridView);
  };

  let tabChangeOperation = useRef(false);
  const handleTabChange = (newValue) => {
    liveUsersGlobalState.currentPreviewUserId = "";
    setActiveTab(newValue);
    tabChangeOperation.current = true;
    getOnlineUsers(newValue);
  };

  return (
    <div id="drawer-container" className="rouDrawerOuterDiv">
      {usersType === "live" && (
        <Box className="tabsBox">
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 0 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => {
              handleTabChange(0);
            }}
            variant="contained"
            startIcon={activeTab === 0 ? <CheckCircle /> : null}
          >
            GEO
          </Button>
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 1 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => {
              handleTabChange(1);
            }}
            variant="contained"
            startIcon={activeTab === 1 ? <CheckCircle /> : null}
          >
            VIP
          </Button>
          {/* <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 2 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => {
              handleTabChange(2);
            }}
            variant="contained"
            startIcon={activeTab === 2 ? <CheckCircle /> : null}
          >
            PRODUCTION
          </Button> */}
        </Box>
      )}
      {activeUsers?.length > 0 && (
        <Drawer
          className="rouDrawer"
          open={true}
          PaperProps={{
            style: {
              position: "absolute",
              width: "100%",
              backgroundColor: "transparent",
            },
          }}
          BackdropProps={{ style: { position: "absolute" } }}
          ModalProps={{
            container: document.getElementById("drawer-container"),
            style: {
              position: "absolute",
              marginTop: usersType === "live" ? 60 : 0,
              backgroundColor: "transparent",
            },
          }}
          anchor="left"
        >
          {activeUsers?.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                className="usersText"
                style={{ color: COLORS.textHeader }}
              >
                USERS
              </Typography>
              <div>
                <IconButton
                  onClick={() => handleGridViewChange("grid3x3")}
                  style={{ color: gridView === "grid3x3" ? "white" : "" }}
                  size="large"
                >
                  <GridIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleGridViewChange("grid4x3")}
                  style={{ color: gridView === "grid3x3" ? "" : "white" }}
                  size="large"
                >
                  <Grid4Icon />
                </IconButton>
              </div>
            </div>
          )}
          {activeUsers?.length > 0 && activeUsers ? (
            loading ? (
              <div style={{ position: "absolute", alignSelf: "center" }}>
                <Loader />
              </div>
            ) : (
              <Grid container spacing={1.5} padding={1.5}>
                {activeUsers.map((usr) => (
                  <Grid
                    item
                    xs={gridView === "grid3x3" ? 4 : 3}
                    key={usr.eventUserId}
                  >
                    <UserPreviewView
                      key={usr.eventUserId}
                      event={event}
                      user={usr}
                      handleClicked={() => {
                        onUserSelected(usr);
                      }}
                      updateStream={() => {
                        onUserSelected(usr);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )
          ) : (
            <p style={{ textAlign: "center", color: "white" }}>
              Users on the page are not active anymore!
            </p>
          )}
        </Drawer>
      )}

      <div
        className="bottomPaginationStrip"
        style={{ backgroundColor: COLORS.previewViewBackground }}
      >
        <div className="pagination">
          <IconButton onClick={OnResetCLick} size="large">
            <SettingsBackupRestore />
          </IconButton>
          {activeUsers?.length === perPage ? (
            <IconButton
              disabled={userLoading}
              onClick={OnNextCLick}
              size="medium"
            >
              NEXT
              <ArrowForwardIos />
            </IconButton>
          ) : (
            <IconButton
              disabled={userLoading}
              onClick={OnRefreshClick}
              size="medium"
            >
              REFRESH
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}
