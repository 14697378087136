import { COLORS } from "../../utils/colors";

export const getVideoDuration = (data) => {
  return data && data.videoDuration ? data.videoDuration : 15;
};

export const getStillsEnabled = (data) => {
  return data && data.stillsEnabled !== undefined ? data.stillsEnabled : true;
};

export const getRecordingEnabled = (data) => {
  return data && data.recordingEnabled !== undefined
    ? data.recordingEnabled
    : true;
};

export const getGalleryEnabled = (data) => {
  return data && data.galleryEnabled !== undefined ? data.galleryEnabled : true;
};

export const getEnableSocialSharingLinks = (data) => {
  return data && data.enableSocialSharingLinks
    ? data.enableSocialSharingLinks
    : false;
};

export const getHashTags = (data) => {
  return data && data.hashTags ? data.hashTags : "";
};

export const getItemBackgroundColor = (data) => {
  return data && data.itemBackgroundColor
    ? data.itemBackgroundColor
    : COLORS.socialFeedCardBackground;
};
