import io from "socket.io-client";
import {
  BASE_URL,
  WEB_PUBSUB_CLIENT_PATH,
  WEB_PUBSUB_SOCKETIO_ENDPOINT,
} from "./constants";
// "undefined" means the URL will be computed from the `window.location` object
let URL = "";
const configObject = {
  withCredentials: false,
  autoConnect: false,
};
if (WEB_PUBSUB_SOCKETIO_ENDPOINT && WEB_PUBSUB_CLIENT_PATH) {
  URL = WEB_PUBSUB_SOCKETIO_ENDPOINT;
  configObject.path = WEB_PUBSUB_CLIENT_PATH;
} else {
  URL =
    process.env.NODE_ENV === "production"
      ? "https://dev-apiuvenu.uvenu.com"
      : BASE_URL;
}
export const socket = io(URL, configObject);
