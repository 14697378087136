import React, { useCallback, memo } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { VariableSizeList, areEqual } from "react-window";

const InfiniteScrollList = ({
  items,
  pageSize,
  hasNextPage,
  loadMoreItems,
  RowItem,
  onSocialFeedScroll,
}) => {
  let isItemLoaded = (index) => !hasNextPage || !!items[index];
  const getItemSize = useCallback(
    (index) => {
      if(items[index]?.isUnsupportedMedia){
        return 0;
      }
      return items[index].height || 200; // Default size if not yet calculated
    },
    [items]
  );
  const Row = memo(({ index, style }) => {
    if (items[index].height) {
      return (
        <div style={style}>
          <RowItem item={items[index]} />
        </div>
      );
    } else {
      if(items[index]?.isUnsupportedMedia){
        return <div className="invisibleItem"></div>;
      }
      return <div style={style}>Loading...</div>;
    }
  }, areEqual);
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
              itemCount={hasNextPage ? items.length + 1 : items.length}
              minimumBatchSize={pageSize}
            >
              {({ onItemsRendered, ref }) => (
                <VariableSizeList
                  width={width}
                  height={height}
                  itemCount={items.length}
                  itemSize={getItemSize}
                  itemKey={(index) => items[index]._id}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  onScroll={onSocialFeedScroll}
                  overscanCount={2}
                >
                  {Row}
                </VariableSizeList>
              )}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default InfiniteScrollList;
