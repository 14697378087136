import React, { useContext, useEffect, useState } from "react";
import { connect } from "redux-zero/react";
import { uvenuFetcher } from "../../utils/uvenu-fetcher";
import { URLS } from "../../constants";
import { Box, Button, Divider } from "@mui/material";

import Modal from "./Modal";
import InEventAdCard from "./InEventAdCard";
import SocialAdCard from "./SocialAdCard";
import PostLiveCard from "./PostLiveCard";
import Loader from "../../components/Loader/Loader";
import {
  AD_TYPES,
  getCollectContactInfoAt,
  getEmailEntryConfig,
  getEmailEntryConfigForGames,
  validateUrl,
} from "./functions";
import { uploader } from "../../utils/uploader";
import "./styles.css";
import PostGameCard from "./PostGameCard";
import { AppContext } from "../../contexts/App.Context";
import { CheckCircle } from "@mui/icons-material";
import { COLORS } from "../../utils/colors";
import LightShowCard from "./LightShowCard";

export default connect((state) => {
  return {
    currentUser: state.currentUser,
  };
}, null)(function ({
  event,
  modalVisible,
  handleCloseModal,
  initialAdvertIds,
  setAdvertIds,
  currentUser,
}) {
  const appContext = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(0);

  const inEventAdFormat = {
    _id: null,
    interval: 5,
    adType: AD_TYPES.IN_EVENT,
    adTitle: "",
    adText: "",
    adLink: "",
  };
  const [inEventData, setInEventData] = useState(inEventAdFormat);
  const [inEventAdsData, setInEventAdsData] = useState([]);

  const socialAdFormat = {
    _id: null,
    interval: 5,
    adType: AD_TYPES.SOCIAL_AD,
    adTitle: "",
    adText: "",
    adLink: "",
    adSelection: false,
  };
  const [socialAdData, setSocialAdData] = useState(socialAdFormat);
  const [socialAdsData, setSocialAdsData] = useState([]);

  const postLiveAdFormat = {
    _id: null,
    interval: 10,
    adType: AD_TYPES.POST_EVENT,
    adTitle: "",
    adText: "",
    adLink: "",
    adSelection: false,
  };
  const [postLiveAdData, setPostLiveAdData] = useState(postLiveAdFormat);

  const postGameAdFormat = {
    _id: null,
    interval: 10,
    adType: AD_TYPES.POST_GAME,
    adTitle: "",
    adText: "",
    adLink: "",
    adSelection: false,
  };
  const [postGameAdData, setPostGameAdData] = useState(postGameAdFormat);

  const lightShowAdFormat = {
    _id: null,
    interval: 10,
    adType: AD_TYPES.LIGHT_SHOW,
    adTitle: "",
    adText: "",
    adLink: "",
    adSelection: false,
  };
  const [lightShowAdData, setLightShowAdData] = useState(lightShowAdFormat);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInEventAdsData(
      initialAdvertIds.filter((a) => a.adType === AD_TYPES.IN_EVENT)
    );
    setSocialAdsData(
      initialAdvertIds.filter(
        (a) =>
          a.adType === AD_TYPES.SOCIAL_AD ||
          a.adType === AD_TYPES.SOCIAL_SUBMISSION_AD
      )
    );

    const postliveAds = initialAdvertIds.filter(
      (a) => a.adType === AD_TYPES.POST_EVENT
    );
    const postLiveAd = { ...postLiveAdData, ...postliveAds[0] };
    postLiveAd.emailEntryConfig = getEmailEntryConfig(event);
    postLiveAd.adSelection = getCollectContactInfoAt(event).includes(
      AD_TYPES.POST_EVENT
    );
    setPostLiveAdData(postLiveAd);

    const postGameAds = initialAdvertIds.filter(
      (a) => a.adType === AD_TYPES.POST_GAME
    );
    const postGameAd = { ...postGameAdData, ...postGameAds[0] };
    postGameAd.emailEntryConfigForGames = getEmailEntryConfigForGames(event);
    postGameAd.adSelection = getCollectContactInfoAt(event).includes(
      AD_TYPES.POST_GAME
    );
    setPostGameAdData(postGameAd);

    const lightShowAds = initialAdvertIds.filter(
      (a) => a.adType === AD_TYPES.LIGHT_SHOW
    );
    const lightShowAd = { ...lightShowAdData, ...lightShowAds[0] };
    setLightShowAdData(lightShowAd);
  }, [initialAdvertIds]);

  const onChangeAdvert = (
    uri,
    file,
    interval,
    index,
    deleteEntry = false,
    adTitle = "",
    adText = "",
    adLink = "",
    mimeType = "",
    adSelection = false,
    headerText = "",
    subHeaderText = "",
    position = "bottom"
  ) => {
    if (activeTab === 0) {
      //updating in event ad changes
      if (index === 0) {
        //updating draft item
        const newData = { ...inEventData };
        if (deleteEntry) {
          newData._id = null;
          newData.dataUri = null;
          newData.file = null;
        } else {
          if (uri && uri.split(":")[0] === "data") {
            newData._id = null;
            newData.dataUri = uri;
            newData.file = file;
          }
          if (interval) {
            newData.interval = interval;
          }
          if (mimeType) {
            newData.mimeType = mimeType;
          }
        }
        setInEventData(newData);
      } else {
        //updating already saved item
        const inEventsData = [...inEventAdsData];
        const updateData = inEventsData[index - 1];
        if (deleteEntry) {
          inEventsData.splice(index - 1, 1);
        } else {
          if (uri && uri.split(":")[0] === "data") {
            updateData._id = null;
            updateData.adMediaUrl = null;
            updateData.dataUri = uri;
            updateData.file = file ? file : updateData.file;
          }
          if (interval) {
            updateData.interval = interval;
          }
          if (mimeType) {
            updateData.mimeType = mimeType;
          }
        }
        setInEventAdsData(inEventsData);
      }
    } else if (activeTab === 1) {
      //updating social feed ad changes
      if (index === 0) {
        //updating draft item
        const newData = { ...socialAdData };
        if (deleteEntry) {
          newData._id = null;
          newData.dataUri = null;
          newData.file = file;
        } else {
          if (uri && uri.split(":")[0] === "data") {
            newData._id = null;
            newData.dataUri = uri;
            newData.file = file;
          }
          if (interval) {
            newData.interval = interval;
          }
          if (adText !== null || adText !== undefined) {
            newData.adText = adText;
          }
          if (adLink !== null || adLink !== undefined) {
            newData.adLink = adLink;
          }
          if (mimeType) {
            newData.mimeType = mimeType;
          }
          newData.adSelection = false;
        }
        setSocialAdData(newData);
      } else {
        //updating already saved item
        let socialAdsData_local = [...socialAdsData];
        if (adSelection) {
          socialAdsData_local = socialAdsData_local.map((ad) => {
            const adCopy = { ...ad };
            adCopy.adType = AD_TYPES.SOCIAL_AD;
            adCopy.adSelection = false;
            return adCopy;
          });
        }
        const newData = socialAdsData_local[index - 1];
        if (deleteEntry) {
          socialAdsData_local.splice(index - 1, 1);
        } else {
          if (uri && uri.split(":")[0] === "data") {
            newData._id = null;
            newData.adMediaUrl = null;
            newData.dataUri = uri;
            newData.file = file ? file : newData.file;
          }
          if (interval) {
            newData.interval = interval;
          }
          if (adText !== null || adText !== undefined) {
            newData.adText = adText;
          }
          if (adLink !== null || adLink !== undefined) {
            newData.adLink = adLink;
          }
          if (mimeType) {
            newData.mimeType = mimeType;
          }
          newData.adSelection = adSelection;
          if (adSelection) {
            newData.adType = AD_TYPES.SOCIAL_SUBMISSION_AD;
          } else {
            newData.adType = AD_TYPES.SOCIAL_AD;
          }
        }
        setSocialAdsData(socialAdsData_local);
      }
    } else if (activeTab === 2) {
      //updating post live event ad changes
      const newData = { ...postLiveAdData };
      if (deleteEntry) {
        newData._id = null;
        newData.adMediaUrl = null;
        newData.dataUri = null;
        newData.file = file;
        newData.mimeType = "image";
      } else {
        if (uri && uri.split(":")[0] === "data") {
          newData._id = null;
          newData.adMediaUrl = null;
          newData.dataUri = uri;
          newData.file = file;
          newData.mimeType = "image";
        }
        if (interval) {
          newData.interval = interval;
        }
        if (adTitle !== null || adTitle !== undefined) {
          newData.adTitle = adTitle;
        }
        if (adText !== null || adText !== undefined) {
          newData.adText = adText;
        }
        if (adLink !== null || adLink !== undefined) {
          newData.adLink = adLink;
        }
        if (mimeType) {
          newData.mimeType = mimeType;
        }
        newData.adSelection = adSelection;
        newData.emailEntryConfig = {
          ...newData.emailEntryConfig,
          headerText,
          subHeaderText,
          position,
        };
      }
      setPostLiveAdData(newData);
    } else if (activeTab === 3) {
      //updating post game ad changes
      const newData = { ...postGameAdData };
      if (deleteEntry) {
        newData._id = null;
        newData.adMediaUrl = null;
        newData.dataUri = null;
        newData.file = file;
        newData.mimeType = "image";
      } else {
        if (uri && uri.split(":")[0] === "data") {
          newData._id = null;
          newData.adMediaUrl = null;
          newData.dataUri = uri;
          newData.file = file;
          newData.mimeType = "image";
        }
        if (interval) {
          newData.interval = interval;
        }
        if (adTitle !== null || adTitle !== undefined) {
          newData.adTitle = adTitle;
        }
        if (adText !== null || adText !== undefined) {
          newData.adText = adText;
        }
        if (adLink !== null || adLink !== undefined) {
          newData.adLink = adLink;
        }
        if (mimeType) {
          newData.mimeType = mimeType;
        }
        newData.adSelection = adSelection;
        newData.emailEntryConfigForGames = {
          ...newData.emailEntryConfigForGames,
          headerText,
          subHeaderText,
        };
      }
      setPostGameAdData(newData);
    } else if (activeTab === 4) {
      //updating light show ad changes
      const newData = { ...lightShowAdData };
      if (deleteEntry) {
        newData._id = null;
        newData.adMediaUrl = null;
        newData.dataUri = null;
        newData.file = file;
        newData.mimeType = "image";
      } else {
        if (uri && uri.split(":")[0] === "data") {
          newData._id = null;
          newData.adMediaUrl = null;
          newData.dataUri = uri;
          newData.file = file;
          newData.mimeType = "image";
        }
        if (interval) {
          newData.interval = interval;
        }
        if (adTitle !== null || adTitle !== undefined) {
          newData.adTitle = adTitle;
        }
        if (adText !== null || adText !== undefined) {
          newData.adText = adText;
        }
        if (adLink !== null || adLink !== undefined) {
          newData.adLink = adLink;
        }
        if (mimeType) {
          newData.mimeType = mimeType;
        }
      }
      setLightShowAdData(newData);
    }
  };

  //HANDLE UPLOAD ADVERT BANNER
  const uploadAdvert = async (
    file,
    interval,
    adType,
    adTitle,
    adText,
    adLink
  ) => {
    try {
      const uploadResponse = await uploader({
        url: URLS.UPLOAD_ADVERTISEMENT_BANNER,
        body: {
          interval: interval,
          adType: adType,
          adTitle: adTitle,
          adText: adText,
          adLink: adLink,
          file: file,
        },
        headers: {
          token: `${currentUser.token}`,
        },
      });
      if (uploadResponse.statusCode === 200) {
        return uploadResponse.json.advertId;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  const updateAdvert = async (
    advertId,
    interval,
    adType,
    adTitle,
    adText,
    adLink
  ) => {
    try {
      let payload = {
        advertId,
        interval,
        adType,
        adTitle,
        adText,
      };
      if (adLink && adLink !== "null") {
        payload.adLink = adLink;
      }
      const response = await uvenuFetcher({
        method: "PATCH",
        url: URLS.UPDATE_ADVERTISEMENT_BANNER,
        body: payload,
        headers: {
          token: `${currentUser.token}`,
        },
      });
      if (response.statusCode === 200) {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  const handleSaveButton = () => {
    if (activeTab === 0) {
      //saving in event ad changes
      if (!inEventData.dataUri) {
        appContext.triggerToast(true, {
          type: "error",
          message: "Ad media is mandatory",
        });
        return;
      }

      const inEventsData = [...inEventAdsData];
      inEventsData.push(inEventData);
      setInEventAdsData(inEventsData);
      setInEventData({ ...inEventAdFormat });
    } else if (activeTab === 1) {
      //saving social feed ad changes
      if (!socialAdData.dataUri) {
        appContext.triggerToast(true, {
          type: "error",
          message: "Social feed ad media is mandatory",
        });
        return;
      }

      if (!validateUrl(socialAdData.adLink)) {
        appContext.triggerToast(true, {
          type: "error",
          message: "Please enter valid url",
        });
        return;
      }

      const socialAdsData_local = [...socialAdsData];
      socialAdsData_local.push(socialAdData);
      setSocialAdsData(socialAdsData_local);
      setSocialAdData({ ...socialAdFormat });
    } else {
    }
  };

  const doneButtonClicked = async () => {
    const advertDataCopy = [...inEventAdsData, ...socialAdsData];

    if (postLiveAdData.dataUri || postLiveAdData._id) {
      if (
        postLiveAdData.adTitle.length === 0 ||
        (postLiveAdData.adLink &&
          postLiveAdData.adLink.length > 0 &&
          !validateUrl(postLiveAdData.adLink))
      ) {
        appContext.triggerToast(true, {
          type: "error",
          message: "Please provide valid data for Fullscreen Ad",
        });
        return;
      }
      if (
        postLiveAdData.adSelection &&
        postLiveAdData.emailEntryConfig.headerText.length === 0
      ) {
        appContext.triggerToast(true, {
          type: "error",
          message: "Please enter header text for Fullscreen Ad",
        });
        return;
      }
      advertDataCopy.push(postLiveAdData);
    }

    if (postGameAdData.dataUri || postGameAdData._id) {
      if (
        postGameAdData.adTitle.length === 0 ||
        (postGameAdData.adLink &&
          postGameAdData.adLink.length > 0 &&
          !validateUrl(postGameAdData.adLink))
      ) {
        appContext.triggerToast(true, {
          type: "error",
          message: "Please provide valid data for Game/Poll Ad",
        });
        return;
      }
      if (
        postGameAdData.adSelection &&
        (!postGameAdData.emailEntryConfigForGames ||
          postGameAdData.emailEntryConfigForGames.headerText.length === 0)
      ) {
        appContext.triggerToast(true, {
          type: "error",
          message: "Please enter header text for Game/Poll Ad",
        });
        return;
      }
      advertDataCopy.push(postGameAdData);
    }

    if (lightShowAdData.dataUri || lightShowAdData._id) {
      if (
        lightShowAdData?.adTitle?.length === 0 ||
        (lightShowAdData?.adLink?.length > 0 &&
          !validateUrl(lightShowAdData.adLink))
      ) {
        appContext.triggerToast(true, {
          type: "error",
          message: "Please provide valid data for Light Show Ad",
        });
        return;
      }
      advertDataCopy.push(lightShowAdData);
    }

    for (const ad of socialAdsData) {
      if (ad.adLink && !validateUrl(ad.adLink)) {
        appContext.triggerToast(true, {
          type: "error",
          message:
            "Please provide valid url's for hyperlinks in Social Feed Ads",
        });
        return;
      }
    }

    setLoading(true);
    for (const ad of advertDataCopy) {
      if (ad._id) {
        await updateAdvert(
          ad._id,
          ad.interval,
          ad.adType,
          ad.adTitle,
          ad.adText,
          ad.adLink
        );
      } else if (ad.dataUri) {
        const advertId = await uploadAdvert(
          ad.file,
          ad.interval,
          ad.adType,
          ad.adTitle,
          ad.adText,
          ad.adLink
        );
        // delete ad.dataUri;
        ad._id = advertId;
      }
    }
    setAdvertIds(advertDataCopy);
    setLoading(false);
    handleCloseModal();
  };

  const handleTabChange = (newValue) => setActiveTab(newValue);

  return (
    <Modal
      open={modalVisible}
      handleClose={() => {
        handleCloseModal();
      }}
      handleSubmit={async () => {
        doneButtonClicked();
      }}
      title="Setup Ads"
    >
      {loading && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            zIndex: 222,
          }}
        >
          <Loader />
        </div>
      )}
      <div
        className="buttonsContainer"
        style={{ backgroundColor: COLORS.sideBarBackground }}
      >
        <Box>
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 0 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => handleTabChange(0)}
            variant="contained"
            startIcon={activeTab === 0 ? <CheckCircle /> : null}
          >
            In Event Ads
          </Button>
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 1 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => handleTabChange(1)}
            variant="contained"
            startIcon={activeTab === 1 ? <CheckCircle /> : null}
          >
            Social Feed Ads
          </Button>
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 2 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => handleTabChange(2)}
            variant="contained"
            startIcon={activeTab === 2 ? <CheckCircle /> : null}
          >
            Fullscreen Ad
          </Button>
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 3 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => handleTabChange(3)}
            variant="contained"
            startIcon={activeTab === 3 ? <CheckCircle /> : null}
          >
            Game/Poll Ad
          </Button>
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 4 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => handleTabChange(4)}
            variant="contained"
            startIcon={activeTab === 4 ? <CheckCircle /> : null}
          >
            Light Show Ad
          </Button>
        </Box>
      </div>
      <div
        className="creationContainer"
        style={{ backgroundColor: COLORS.eventDetailBackground }}
      >
        {activeTab === 0 ? (
          <InEventAdCard
            onChangeAdvert={onChangeAdvert}
            advertData={inEventData}
            index={0}
          />
        ) : activeTab === 1 ? (
          <SocialAdCard
            onChangeAdvert={onChangeAdvert}
            advertData={socialAdData}
            index={0}
          />
        ) : activeTab === 2 ? (
          <PostLiveCard
            onChangeAdvert={onChangeAdvert}
            initialPostLiveAd={postLiveAdData}
          />
        ) : activeTab === 3 ? (
          <PostGameCard
            onChangeAdvert={onChangeAdvert}
            initialPostGameAd={postGameAdData}
          />
        ) : (
          <LightShowCard
            onChangeAdvert={onChangeAdvert}
            initialLightShowAd={lightShowAdData}
          />
        )}
        {activeTab < 2 && (
          <div>
            <Button
              color="primary"
              className="saveButton"
              style={{
                color: COLORS.textHeader,
                "&:hover": {
                  backgroundColor: COLORS.appBlueColor,
                },
              }}
              onClick={() => handleSaveButton()}
              variant="contained"
            >
              Save AD
            </Button>
          </div>
        )}
      </div>
      {activeTab < 2 && (
        <div
          className="listingContainer"
          style={{
            backgroundColor: COLORS.eventDetailBackground,
          }}
        >
          {activeTab === 0 ? (
            <>
              {inEventAdsData.map((adData, index) => (
                <InEventAdCard
                  key={index + 1}
                  onChangeAdvert={onChangeAdvert}
                  advertData={adData}
                  index={index + 1}
                />
              ))}
            </>
          ) : activeTab === 1 ? (
            <>
              {socialAdsData.map((adData, index) => (
                <SocialAdCard
                  key={index + 1}
                  onChangeAdvert={onChangeAdvert}
                  advertData={adData}
                  index={index + 1}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </Modal>
  );
});
