import { useEffect, useRef } from "react";
import { useState } from "react";
import { InView } from "react-intersection-observer";
import { URLS } from "../../constants";
import ScrollingMasonryItem from "./ScrollingMasonryItem";
import { mergeAndDeduplicate } from "../../utils/utility";
import { checkMediaOrientation } from "./fixedMasonry/CheckOrientation";
const Slide = ({
  pageNo,
  eventId,
  perPage,
  bg = "transparent",
  isLast = false,
  totalSlides,
  roundedCorners,
  borderWidth,
  borderColor,
  gap,
  numberOfColumns,
  isScroll,
  resetCallback,
}) => {
  const [items, setItems] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [fetchNewItems, setFetchNewItems] = useState(false);
  const [doFetchForLast, setDoFetchForLast] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(pageNo);

  const [bufferItems, setBufferItems] = useState([]);

  const mediaTagRef = useRef(null);
  const [classifiedMedia, setClassifiedMedia] = useState({
    landscape: [],
    portrait: [],
  });
  let totalPages = useRef(1).current;

  let intervalId = null;

  const checkImageOrientationAsync = (imageUrl, mimeType) => {
    return new Promise((resolve, reject) => {
      checkMediaOrientation(imageUrl, mimeType, (error, result, errorEvent) => {
        if (error) {
          console.error(error, errorEvent);
          reject(new Error(error));
        } else {
          resolve(result);
        }
      });
    });
  };

  const fetchContent = () => {
    return fetch(
      URLS.GET_SOCIAL_SCROLL(
        eventId,
        currentPageNo,
        perPage,
        undefined,
        undefined,
        "desc"
      ),
      {
        method: "GET",
      }
    )
      .then((rawResponse) => {
        return rawResponse.json();
      })
      .then((response) => {
        totalPages = Math.ceil(response.totalCount / perPage);
        if (totalPages > 1) {
          clearInterval(intervalId);
        }
        setCurrentPageNo((pre) => {
          if (pre < totalPages - 1) {
            return pre + 1;
          } else {
            return 0;
          }
        });
        const mediaFiles = response?.mediaFiles || [];
        if (response.totalCount <= numberOfColumns) {
          resetCallback();
        }
        if (mediaFiles && mediaFiles.length > 0) {
          return mediaFiles;
        } else {
          return [];
        }
      })
      .then(async (mediaFiles) => {
        const _classifiedMedia = { landscape: [], portrait: [] };
        for (const item of mediaFiles) {
          try {
            const result = await checkImageOrientationAsync(
              item.mediaFileUrl,
              item.mimeType
            );
            const { orientation, width, height } = result;
            item.orientation = orientation;
            item.width = width;
            item.height = height;
            _classifiedMedia[orientation].push(item);
          } catch (error) {
            console.error(
              "Error determining image orientation:",
              error.message
            );
          }
        }
        setClassifiedMedia(_classifiedMedia);
        return _classifiedMedia.portrait || [];
      });
  };

  const fetchAndSetNewItems = () => {
    fetchContent().then((newItems) => {
      if (newItems.length) {
        setBufferItems((pre) => mergeAndDeduplicate(pre, newItems));
      }
    });
  };
  useEffect(() => {
    if (fetchNewItems || doFetchForLast) {
      fetchAndSetNewItems();
      if (fetchNewItems) {
        setFetchNewItems(false);
      }
      if (doFetchForLast) {
        setDoFetchForLast(false);
      }
    }
  }, [fetchNewItems, doFetchForLast]);

  const fetchAndSetFirstItems = () => {
    fetchContent().then((newItems) => {
      if (newItems.length) {
        setItems(newItems);
      }
    });
  };
  useEffect(() => {
    if (isScroll) {
      clearInterval(intervalId);
    } else {
      clearInterval(intervalId);
      setCurrentPageNo(0);
      intervalId = setInterval(() => {
        fetchAndSetFirstItems();
      }, 3000);
    }
  }, [isScroll, numberOfColumns]);

  useEffect(() => {
    fetchAndSetFirstItems();
  }, [perPage]);
  const getLayoutStyles = () => {
    return {
      gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
      gridGap: `${gap}px`,
      padding: `${gap / 2}px ${gap}px`,
    };
  };

  const getDisplayItems = (rawItems) => {
    const displayItems = [...rawItems];
    if (rawItems.length) {
      const fillUpCount = rawItems.length % numberOfColumns;
      if (fillUpCount) {
        for (let i = 0; i < numberOfColumns - fillUpCount; i++) {
          if (rawItems[i]) {
            displayItems.push({ _id: rawItems[i]._id + i, ...rawItems[i] });
          } else {
            displayItems.push({ _id: rawItems[0]._id + i, ...rawItems[0] });
          }
        }
      }
    }
    return displayItems;
  };
  return (
    <InView
      as="div"
      style={{
        ...getLayoutStyles(),
        // background: bg,
      }}
      className="uv-fixed-masonry-grid scrolling-slide"
      onChange={(inView, entry) => {
        if (inView) {
          //setIsVisible(inView);
        } else {
          try {
            if (entry && entry.boundingClientRect.top < 0) {
              setFetchNewItems(true);
            } else if (isLast) {
              setFetchNewItems(true);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }}
    >
      {getDisplayItems(items).map((item, i) => (
        <ScrollingMasonryItem
          key={item._id + i}
          data={item}
          requestNewItemCallBack={(
            { imageElements, videoElements },
            parent
          ) => {
            const newMedia = bufferItems.shift();
            if (newMedia && parent) {
              if (newMedia.mimeType.includes("video") && videoElements.length) {
                parent.classList.remove("img-media-card");
                parent.classList.add("video-media-card");
                videoElements.forEach((el) => {
                  el.src = newMedia.mediaFileUrl;
                });
              } else if (imageElements.length) {
                parent.classList.remove("video-media-card");
                parent.classList.add("img-media-card");
                imageElements.forEach((el) => {
                  el.src = newMedia.mediaFileUrl;
                });
              }
            }
          }}
          length={items.length}
          index={i}
          roundedCorners={roundedCorners}
          borderWidth={borderWidth}
          borderColor={borderColor}
          ref={mediaTagRef}
          isLastSlide={isLast}
        />
      ))}
    </InView>
  );
};
export default Slide;
