import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { APP_INSIGHTS_CONNECTION_STRING } from "./constants";

const reactPlugin = new ReactPlugin();
const appInsights =
  APP_INSIGHTS_CONNECTION_STRING === ""
    ? null
    : new ApplicationInsights({
        config: {
          connectionString: APP_INSIGHTS_CONNECTION_STRING,
          extensions: [reactPlugin],
          enableAutoRouteTracking: true,
          disableAjaxTracking: false,
          autoTrackPageVisitTime: true,
          enableCorsCorrelation: true,
          correlationHeaderExcludedDomains: ['*cdn.uvenu.com','*googleapis.com'],
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true,
          isStorageUseDisabled: true,
          maxBatchInterval: 0,
        },
      });
appInsights && appInsights.loadAppInsights();

const insights = {
  trackUvenuClick: (name, json) => {
    appInsights && appInsights.trackEvent({ name: name }, { eventInfo: json });
  },

  trackUvenuException: (error, json) => {
    appInsights && appInsights.trackException({ error: error }, { info: json });
  },
};

export { reactPlugin, appInsights, insights };
