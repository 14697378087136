import { motion } from "framer-motion";
import { memo } from "react";
const SingleColorOpacity = memo(
  ({ color, color2, color1HoldTime, color2HoldTime, animationDuration }) => {
    return (
      <motion.div
        style={{
            backgroundColor: color,
            display: "flex",
            position: "absolute",
            inset: 0,
        }}
        animate={{ opacity: [0, 0.5, 1] }}
        transition={{
          duration: animationDuration,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      ></motion.div>
    );
  }
);
export default SingleColorOpacity;
