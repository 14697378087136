export const AD_TYPES = {
  IN_EVENT: "InEvent",
  SOCIAL_AD: "SocialAd",
  SOCIAL_SUBMISSION_AD: "SocialMediaUploadAd",
  POST_EVENT: "PostEvent",
  POST_GAME: "PostGame",
  LIGHT_SHOW: "LightShow",
};

export const getImageUri = (data) => {
  return data && data.adMediaUrl ? data.adMediaUrl : data.dataUri;
};

export const getFileMimeType = (data) => {
  return data && data.mimeType ? data.mimeType : "";
};

export const getAdInterval = (data) => {
  return data && data.interval ? data.interval : "";
};

export const getAdTitle = (data) => {
  return data && data.adTitle ? data.adTitle : "";
};

export const getAdDescription = (data) => {
  return data && data.adText ? data.adText : "";
};

export const getHyperlink = (data) => {
  return data && data.adLink ? data.adLink : "";
};

export const getCollectUserInfoAt = (data) => {
  return data && data.adSelection ? data.adSelection : false;
};

export const getSocialMediaUploadAd = (data) => {
  return data && data.adType === "SocialMediaUploadAd";
};

export const getCollectContactInfoAt = (data) => {
  return data && data.collectContactInfoAt
    ? data.collectContactInfoAt
    : "Never";
};

export const getEmailEntryConfig = (data) => {
  return data && data.emailEntryConfig
    ? data.emailEntryConfig
    : { headerText: "", subHeaderText: "", position: "bottom" };
};

export const getEmailEntryConfigForGames = (data) => {
  return data && data.emailEntryConfigForGames
    ? data.emailEntryConfigForGames
    : { headerText: "", subHeaderText: ""};
};

export const getFullscreenAdHeaderText = (data) => {
  return data && data.headerText ? data.headerText : "";
};

export const getFullscreenAdSubHeaderText = (data) => {
  return data && data.subHeaderText ? data.subHeaderText : "";
};

export const getFullscreenAdPosition = (data) => {
  return data && data.position ? data.position : "bottom";
};

export const validateUrl = (url) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  return regex.test(url);
};
