import { useEffect, useRef, useState } from "react";
import { connect } from "redux-zero/react";
import {
  Card,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import {
  downloadMedia,
  extractPoster,
  isValidHttpUrl,
} from "../../../utils/utility";
import { format } from "date-fns";
import Editor from "./VideoEditor/Editor";
import { uploader } from "../../../utils/uploader";
import { URLS } from "../../../constants";
import "./SocialOperator.css";
import "./VideoEditor/editor.css";

import "../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
import "../../../../node_modules/@syncfusion/ej2-image-editor/styles/bootstrap5.css";

import { ImageEditorComponent } from "@syncfusion/ej2-react-image-editor";

// Registering Syncfusion license key
import { registerLicense } from "@syncfusion/ej2-base";
import Loader from "../Loader_social";
import { reviewMedia } from "./networkCallsSocial";
import {
  Close,
  FileDownload,
  GpsFixed,
  PlayCircle,
  StarBorder,
  Star,
} from "@mui/icons-material";
import { COLORS } from "../../../utils/colors";
import {
  dataURItoBlob,
  imageDataToDataURL,
} from "../../../utils/mediaFunctions";

registerLicense(
  "ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdEZjXn1Zc3VUQmNV"
);
const EventInteractionCard = ({
  media,
  showApproveButton,
  handleReviewMedia,
  handleReviewQuote,
  emptyCard,
  initialState,
  approved,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mediaPreviewOpen, setMediaPreviewOpen] = useState(false);
  const [username, setUsername] = useState(media && media.userId);
  const [description, setDescription] = useState(media && media.comment);
  const [showOnQuotesChecked, setShowOnQuotesChecked] = useState(
    media && media.showOnQuotesFeed
  );
  const [editorTimings, setEditorTimings] = useState([]);
  const imageEditorRef = useRef(null);
  const videoMetaData = useRef({ width: 0, height: 0 });
  const imageMetaData = useRef({ width: 0, height: 0 });
  let updatedVideo = null;
  let updatedImage = null;
  const approveButtonClicked = async () => {
    setIsLoading(true);
    try {
      let approvalId = media._id;
      if (updatedVideo || updatedImage) {
        //reject to remove old media item
        await handleReviewMedia(
          initialState.loggedInUserObj,
          approvalId,
          false,
          username,
          description,
          showOnQuotesChecked
        );

        //upload the updated/trimmed video file
        const payload = {
          eventId: initialState.event_id,
          userId: username,
          comment: description,
          userLocation: JSON.stringify(media.userLocation),
        };
        if (media.videoPosterUrl) {
          payload.videoPosterUrl = media.videoPosterUrl;
        }
        payload.mediaFile = updatedVideo
          ? updatedVideo.blob
          : updatedImage.blob;
        const uploadResponse = await uploader({
          url: URLS.UPLOAD_MEDIA(),
          body: payload,
        });
        //update the uploaded id to approve
        approvalId = uploadResponse.json.sharedMedia._id;

        await reviewMedia(
          initialState.loggedInUserObj,
          approvalId,
          true,
          username,
          description,
          showOnQuotesChecked,
          getMetaData(media?.mimeType.includes("video"))
        );
      } else {
        if (media?.isQuoteOnly) {
          handleReviewQuote({
            loggedInUserObj: initialState.loggedInUserObj,
            isReviewed: true,
            ...media,
            comment: description,
            userId: username,
          });
        } else {
          // approve the media item with updated id
          const metadata = getMetaData(media?.mimeType.includes("video"));
          await handleReviewMedia(
            initialState.loggedInUserObj,
            approvalId,
            true,
            username,
            description,
            showOnQuotesChecked,
            metadata
          );
        }
      }

      setMediaPreviewOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const RenderImageEditor = () => {
    return (
      <div className="e-img-editor-sample">
        <ImageEditorComponent
          id="image-editor"
          ref={imageEditorRef}
          created={imageEditorCreated}
        />

        {showApproveButton && (
          <div
            className="controls"
            style={{
              position: "absolute",
              top: 55,
              right: 28,
            }}
          >
            <button
              title="Save changes"
              className="trim-control"
              onClick={() => {
                if (imageEditorRef.current) {
                  const imageData = imageEditorRef.current.getImageData();
                  if (imageData && imageData.width && imageData.height) {
                    imageMetaData.current = {
                      width: imageData.width,
                      height: imageData.height,
                    };
                  }
                  const imageDataUrl = imageDataToDataURL(imageData);
                  const blob = dataURItoBlob(imageDataUrl);
                  updatedImage = { blob: blob };
                }
              }}
            >
              Save
            </button>
          </div>
        )}
      </div>
    );
  };
  const imageEditorCreated = () => {
    imageEditorRef.current.open(media.mediaFileUrl);
  };

  useEffect(() => {
    getLocationAddress();
  }, []);

  const [address, setAddress] = useState("0, 0");
  const getLocationAddress = () => {
    try {
      if (media && media.userLocation && media.userLocation.name) {
        setAddress(media.userLocation.name);
      } else {
        setAddress("0, 0");
      }
    } catch (error) {
      setAddress("0, 0");
    }
  };
  const getMetaData = (isVideo) => {
    if (isVideo) {
      return videoMetaData.current;
    } else if (imageMetaData.current.width && imageMetaData.current.height) {
      return imageMetaData.current;
    } else {
      if (imageEditorRef.current) {
        const imageData = imageEditorRef.current.getImageData();
        if (imageData && imageData.width && imageData.height) {
          imageMetaData.current = {
            width: imageData.width,
            height: imageData.height,
          };
        }
      }
      return imageMetaData.current;
    }
  };

  return emptyCard ? (
    <Card
      className="soCard soEventInteractionCard"
      style={{ borderWidth: "0px !important" }}
    >
      <div></div>
    </Card>
  ) : (
    <Card
      className={`soCard soEventInteractionCard${
        media?.isStarred ? " starredCard" : ""
      } ${media?.isQuoteOnly ? "quoteOnlyCardOuter" : ""}`}
    >
      <Dialog
        open={mediaPreviewOpen}
        onClose={() => setMediaPreviewOpen(false)}
        classes={{
          paper: "soDialogPaper",
        }}
      >
        <DialogContentText className="soDialogHeader">
          <h3 className="soDialogHeader">PREVIEW</h3>
          <h3
            className="soDialogClose"
            onClick={() => setMediaPreviewOpen(false)}
          >
            X
          </h3>
        </DialogContentText>
        <DialogContent sx={{ display: "flex" }}>
          {!media?.isQuoteOnly && (
            <div className="soMediaPreview">
              {media?.mimeType?.includes("video") ? (
                <Editor
                  videoUrl={media?.mediaFileUrl}
                  showControls={showApproveButton}
                  timings={editorTimings}
                  setTimings={setEditorTimings}
                  hasVideoPoster={media?.videoPosterUrl ? true : false}
                  updatedVideoBlob={async (newFile) => {
                    updatedVideo = newFile;
                    if (newFile?.blob) {
                      const videoPosterUrl = await extractPoster(
                        undefined,
                        newFile.blob
                      );
                      media.videoPosterUrl = videoPosterUrl;
                    }
                  }}
                  updateVideoDimensions={(videoWidth, videoHeight) => {
                    videoMetaData.current.width = videoWidth;
                    videoMetaData.current.height = videoHeight;
                  }}
                  onVideoDataLoaded={async (fileBlob) => {
                    if (
                      fileBlob &&
                      media.videoPosterUrl !== "" &&
                      typeof media.videoPosterUrl !== "string"
                    ) {
                      const videoPosterUrl = await extractPoster(
                        undefined,
                        fileBlob
                      );
                      media.videoPosterUrl = videoPosterUrl;
                    }
                  }}
                  beforeUnload={async () => {
                    if (!updatedVideo) {
                      if (
                        media?.videoPosterUrl &&
                        isValidHttpUrl(media.videoPosterUrl)
                      ) {
                        // Do nothing
                      } else {
                        if (media?.videoPosterUrl?.size) {
                          const uploadResponse = await uploader({
                            url: URLS.UPLOAD_VIDEO_POSTER(),
                            body: {
                              sharedMediaId: media._id,
                              videoPosterUrl: media.videoPosterUrl,
                            },
                          });
                          if (
                            uploadResponse &&
                            uploadResponse?.json?.videoPosterUrl
                          ) {
                            const newPosterUrl =
                              uploadResponse?.json?.videoPosterUrl;
                            media.videoPosterUrl = newPosterUrl;
                            const el = document.getElementById(media._id);
                            const blurEl = document.getElementById(
                              "blur" + media._id
                            );
                            if (el) {
                              el.poster = newPosterUrl;
                              el.src = "";
                            }
                            if (blurEl) {
                              blurEl.poster = newPosterUrl;
                              blurEl.src = "";
                            }
                          }
                        }
                      }
                    }
                  }}
                />
              ) : showApproveButton ? (
                <RenderImageEditor />
              ) : (
                <img
                  alt="media"
                  src={media?.mediaFileUrl}
                  className="approvedImage"
                />
              )}
            </div>
          )}

          <div className="mediaPreviewInputFields">
            <div
              className="soShowOnQuotesCheckbox"
              onClick={() => {
                setShowOnQuotesChecked(!showOnQuotesChecked);
              }}
            >
              <Checkbox
                checked={showOnQuotesChecked}
                sx={{
                  color: "white",
                  "&.Mui-checked": {
                    color: "white",
                  },
                }}
              />
              <Typography color={"white"}>Approve Quote</Typography>
            </div>
            <TextField
              variant="outlined"
              onInput={(e) => {}}
              defaultValue={username}
              margin="dense"
              id="username"
              label="Username"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              fullWidth
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              inputProps={{ maxLength: 20 }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "rgba(255, 255, 255, 0.7)",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.5)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.5)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.5)",
                  },
                  "&.Mui-disabled fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.5)",
                  },
                },
              }}
            />

            <TextField
              variant="outlined"
              onInput={(e) => {}}
              defaultValue={description}
              margin="dense"
              id="description"
              label="Media Description"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              fullWidth
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              multiline={true}
              sx={{
                marginTop: "10px",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "rgba(255, 255, 255, 0.7)",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.5)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.5)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.5)",
                  },
                  "&.Mui-disabled fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.5)",
                  },
                },
              }}
            />
            <div
              style={{
                border: "none",
                width: "100%",
                cursor: "default",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {address.length > 4 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps/search/?api=1&query=${media.userLocation.coordinates[1]},${media.userLocation.coordinates[0]}`
                    );
                  }}
                >
                  <GpsFixed style={{ color: COLORS.textLabel }} />
                  <Typography
                    style={{
                      color: COLORS.textLabel,
                      textAlign: "start",
                      fontSize: 12,
                      marginLeft: 5,
                    }}
                  >
                    {address}
                  </Typography>
                </div>
              )}
              <Typography
                style={{
                  color: COLORS.textLabel,
                  fontSize: 12,
                  width: "100%",
                  padding: "10px 0px",
                }}
              >
                {"Uploaded on: " +
                  format(
                    new Date(media?.createdAt * 1000),
                    "dd MMM yyyy hh:mm a"
                  )}
              </Typography>
            </div>
          </div>

          {isLoading && (
            <div className="soLoaderStyle">
              <Loader />
            </div>
          )}
        </DialogContent>
        <DialogActions className="soDialogActions">
          <Button
            style={{
              backgroundColor: "white",
              margin: 4,
              width: "100%",
              color: "red",
            }}
            variant="contained"
            onClick={() => {
              media?.isQuoteOnly
                ? handleReviewQuote({
                    loggedInUserObj: initialState.loggedInUserObj,
                    ...media,
                    comment: description,
                    userId: username,
                  })
                : handleReviewMedia(
                    initialState.loggedInUserObj,
                    media._id,
                    false,
                    username,
                    description,
                    showOnQuotesChecked
                  );
              setMediaPreviewOpen(false);
            }}
          >
            {showApproveButton ? "Reject" : "Remove"}
          </Button>
          <Button
            style={{
              backgroundColor: "green",
              margin: 4,
              width: "100%",
              color: "white",
            }}
            variant="contained"
            onClick={async () => {
              approveButtonClicked();
            }}
          >
            {showApproveButton ? "Approve" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ height: "100%" }}>
        {media?.mimeType?.includes("video") ||
        media?.mimeType?.includes("text") ? (
          <div
            className="soImageMedia skeleton"
            onClick={(e) => {
              setMediaPreviewOpen(true);
            }}
          >
            <video
              className="soVideoMedia"
              id={"blur" + media._id}
              src={media.videoPosterUrl ? "" : media?.mediaFileUrl}
              playsInline
              loop
              controls={false}
              muted
              style={{
                objectFit: "fill",
                filter: "blur(10px)",
              }}
              disableRemotePlayback={true}
              poster={media.videoPosterUrl}
            />
            <video
              className="soVideoMedia"
              id={media._id}
              src={media.videoPosterUrl ? "" : media?.mediaFileUrl}
              playsInline
              loop
              controls={false}
              muted
              style={{
                zIndex: 11,
              }}
              disableRemotePlayback={true}
              poster={media.videoPosterUrl}
            />
            <PlayCircle
              style={{
                position: "absolute",
                alignSelf: "center",
                color: "lightgray",
                zIndex: 12,
                top: "calc(50% - 25px)",
                left: "calc(50% - 25px)",
                width: "50px",
                height: "50px",
              }}
            />
          </div>
        ) : media?.mimeType?.includes("image") ? (
          <div
            className="soImageMedia skeleton"
            onClick={(e) => {
              setMediaPreviewOpen(true);
            }}
          >
            <img
              alt="media"
              className="soImageMedia"
              src={media?.mediaFileUrl}
              style={{
                objectFit: "fill",
                filter: "blur(10px)",
              }}
            />
            <img
              alt="media"
              className="soImageMedia"
              src={media?.mediaFileUrl}
              style={{
                position: "absolute",
                left: 0,
                objectFit: "contain",
              }}
            />
          </div>
        ) : media.isQuoteOnly ? (
          <div
            className="soImageMedia quoteOnlyCard"
            onClick={(e) => {
              setMediaPreviewOpen(true);
            }}
          >
            {media.comment}
          </div>
        ) : (
          <div
            className="soImageMedia skeleton"
            onClick={(e) => {
              setMediaPreviewOpen(true);
            }}
          >
            <img
              alt="media"
              className="soImageMedia"
              src={media?.mediaFileUrl}
              style={{
                objectFit: "fill",
                filter: "blur(10px)",
              }}
            />
            <img
              alt="media"
              className="soImageMedia"
              src={media?.mediaFileUrl}
              style={{
                position: "absolute",
                left: 0,
                objectFit: "contain",
              }}
            />
          </div>
        )}
      </div>

      <IconButton
        className="soApproveButton"
        onClick={() =>
          media?.isQuoteOnly
            ? handleReviewQuote({
                loggedInUserObj: initialState.loggedInUserObj,
                ...media,
                comment: description,
                userId: username,
                isReviewed: false,
              })
            : handleReviewMedia(
                initialState.loggedInUserObj,
                media._id,
                false,
                username,
                description,
                showOnQuotesChecked
              )
        }
        size="medium"
      >
        <Close />
      </IconButton>
      {approved && (
        <IconButton
          className="star"
          title="Starred media will not be purged."
          onClick={(e) => {
            e.stopPropagation();
            media?.isQuoteOnly
              ? handleReviewQuote({
                  loggedInUserObj: initialState.loggedInUserObj,
                  ...media,
                  comment: description,
                  userId: username,
                  isStarred: media?.isStarred ? false : true,
                })
              : handleReviewMedia(
                  initialState.loggedInUserObj,
                  media._id,
                  approved,
                  username,
                  description,
                  showOnQuotesChecked,
                  undefined,
                  media?.isStarred ? false : true
                );
          }}
          size="medium"
        >
          {media.isStarred ? (
            <Star sx={{ color: "#FFD700" }} />
          ) : (
            <StarBorder />
          )}
        </IconButton>
      )}
      {!media?.isQuoteOnly && (
        <IconButton
          className="soDownloadButton"
          onClick={() => downloadMedia(media?.mediaFileUrl, media._id)}
          size="medium"
        >
          <FileDownload />
        </IconButton>
      )}
    </Card>
  );
};

export default connect((state) => ({ initialState: state.initialState }))(
  EventInteractionCard
);
