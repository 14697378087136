import { motion } from "framer-motion";
const TwoToneSplit = ({
  color,
  color2,
  color1HoldTime,
  color2HoldTime,
  animationDuration,
}) => {
  return (
    <div
      style={{
        backgroundColor: color,
        display: "flex",
        position: "absolute",
        inset: 0,
      }}
    >
      <motion.div
        className="moving-div bottom-div"
        style={{ backgroundColor: color2 }}
        animate={{
          width: ["70vh", "0vh", "70vh"],
        }}
        transition={{ ease: "linear", duration: animationDuration, repeat: Infinity }}
      ></motion.div>
    </div>
  );
};
export default TwoToneSplit;
