import React, { useRef, useState } from "react";
import {
  Avatar,
  Card,
  Tooltip,
  CardContent,
  Badge,
  TextField,
  Divider,
  Typography,
} from "@mui/material";
import {
  AddAPhotoOutlined as AddAPhotoOutlinedIcon,
  Close,
} from "@mui/icons-material";
import { getAdInterval, getFileMimeType, getImageUri } from "./functions";
import AdPreviewBox from "./AdPreviewBox";
import { COLORS } from "../../utils/colors";
import "./styles.css";

export default (function ({ advertData, index, onChangeAdvert }) {
  const dataUri = getImageUri(advertData);
  const mimeType = getFileMimeType(advertData);
  const advertTime = getAdInterval(advertData);
  let selectedFile = useRef(null);
  const [showPreview, setShowPreview] = useState(false);

  const handleRemove = () =>
    onChangeAdvert(null, null, advertTime, index, true, "", "", "", "");

  const delegateClickToFileElement = () =>
    document.getElementById(`selectAdvert${index}`).click();

  const handleImageUpload = async (e) => {
    selectedFile.current = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile.current);
    reader.onloadend = () => {
      onChangeAdvert(
        reader.result,
        selectedFile.current,
        advertTime,
        index,
        false,
        "",
        "",
        "",
        selectedFile.current.type
      );
    };
  };

  return (
    <Card
      className="adRoot"
      style={{
        overflow: "unset",
        backgroundColor: COLORS.popupBackground,
      }}
    >
      <CardContent className="adsCardContent">
        <Badge
          style={{ margin: 30, marginTop: 5 }}
          overlap="rectangular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={
            <div
              className="badgeDiv"
              style={{
                display: dataUri ? null : "none",
              }}
              onClick={handleRemove}
            >
              <Tooltip title={"Remove"}>
                <Avatar className="closeAvatar">
                  <Close />
                </Avatar>
              </Tooltip>
            </div>
          }
        >
          <Badge
            overlap="rectangular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <div
                className="badgeDiv"
                onClick={delegateClickToFileElement}
                onChange={(e) => handleImageUpload(e)}
              >
                <input
                  id={`selectAdvert${index}`}
                  hidden
                  type="file"
                  accept="image/*, video/*"
                />
                <Tooltip title={"Select In Event Ad"}>
                  <Avatar className="selectAvatar">
                    <AddAPhotoOutlinedIcon />
                  </Avatar>
                </Tooltip>
              </div>
            }
          >
            <div onClick={() => dataUri && setShowPreview(true)}>
              {mimeType.includes("video") ? (
                <video
                  autoPlay
                  playsInline
                  loop
                  className="adMedia"
                  style={{
                    borderColor: COLORS.contactAvatar,
                  }}
                  controls={false}
                  muted
                  src={dataUri ? dataUri : "#"}
                  disableRemotePlayback={true}
                />
              ) : (
                <Avatar
                  src={dataUri ? dataUri : "#"}
                  variant={"rounded"}
                  className="adMedia"
                  style={{
                    borderColor: COLORS.contactAvatar,
                  }}
                  imgProps={{ style: { objectFit: "contain" } }}
                />
              )}
            </div>
          </Badge>
        </Badge>
        <Divider
          sx={{
            width: "100%",
            marginTop: 1,
            marginBottom: 1,
            backgroundColor: COLORS.textLabel,
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Time in seconds
          <span
            style={{
              color: COLORS.error,
            }}
          >
            {" *"}
          </span>
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          value={advertTime}
          type="number"
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 2);
          }}
          onChange={(e) => {
            onChangeAdvert(
              dataUri,
              selectedFile.current,
              parseInt(e.target.value),
              index,
              false,
              "",
              "",
              "",
              selectedFile.current ? selectedFile.current.type : mimeType
            );
          }}
        />
        <AdPreviewBox
          previewOpen={showPreview}
          setPreviewOpen={(status) => {
            setShowPreview(status);
          }}
          adImageDataUri={dataUri}
          adMimeType={mimeType}
        />
      </CardContent>
    </Card>
  );
});
