/**
 * @param {*} currentPeerConnectionObject SimplePeerController object
 * @returns undefined or the MediaStreamTrack for the received video
 */
export function getVideoTrackFromPeerConnection(currentPeerConnectionObject) {
  const underlyingPc = currentPeerConnectionObject &&
    currentPeerConnectionObject._peer &&
    currentPeerConnectionObject._peer.getRTCPeerConnection();
  if(!underlyingPc) return undefined;
  // Extract the received video RTCRtpReceiver
  const receivers = underlyingPc.getReceivers();
  if(!receivers || !receivers.length) return false;
  const videoReceivers = receivers.filter(recvr => recvr.track.kind === 'video');
  const firstVideoReceiver = videoReceivers.length ? videoReceivers[0] : undefined;
  // create a stream object and attach to video element
  if(!firstVideoReceiver) return undefined;
  const videoTrack = firstVideoReceiver.track;
  return videoTrack;
}

export const getVideoAudioTracks = (currentPeerConnectionObject) => {
  const underlyingPc =
    currentPeerConnectionObject &&
    currentPeerConnectionObject._peer &&
    currentPeerConnectionObject._peer.getRTCPeerConnection();
  if (!underlyingPc) return undefined;
  // Extract the received video audio RTCRtpReceiver
  const receivers = underlyingPc.getReceivers();
  if (!receivers || !receivers.length) return false;
  const videoAudioReceivers = receivers.filter(
    (recvr) => recvr.track.kind === "video" || recvr.track.kind === "audio"
  );
  return videoAudioReceivers.map((recvr) => recvr.track);
};
