import { useState } from "react";

const Panel = ({ id, onRemove, children, title }) => {
  const [scale, setScale] = useState(1);
  const DEFAULT_FONT_SIZE = "20px";
  const [size, setSize] = useState({
    width: "0px",
    height: "0px",
    fontSize: DEFAULT_FONT_SIZE,
  });

  const [editable, setEditable] = useState(false);
  return (
    <div
      className={`poc-body ${editable ? "visible" : ""}`}
      onDoubleClick={() => {
        setEditable((pre) => !pre);
      }}
    >
      <div style={{ height: "100%" }}>{children}</div>
    </div>
  );
};
export default Panel;
