import React, { useEffect, useState, useContext, useRef } from "react";
import SuperScreenInstance from "./SuperScreenInstance";
import {
  Add,
  Delete,
  Save,
  Settings,
  Wallpaper,
  AddCard,
  Edit,
  SaveAs,
} from "@mui/icons-material";
import {
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";

import Switch from "@mui/material/Switch";
import {
  createSuperScreenLayout,
  updateSuperScreenLayout,
} from "../Games/networkCalls/networkCalls";
import SavedLayouts from "./SavedLayouts";
import UvDialog from "../../components/UI/UvDialog";
import { AppContext } from "../../contexts/App.Context";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./SuperScreen.css";
import { COLORS } from "../../utils/colors";
import Drawer from "../../components/UI/Drawer";
import { isVideoOrImage } from "../../utils/utility";
import { Lock, LockOpen } from "@mui/icons-material";
const validDataUrl = require("valid-data-url");
export default function SuperScreen() {
  const appContext = useContext(AppContext);
  const [backgroundColor, setBackgroundColor] = useState("black");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [overlayFile, setOverlayFile] = useState(null);
  const [settingDialogOpen, setSettingDialogOpen] = useState(false);
  const [urlDialogOpen, setUrlDialogOpen] = useState(false);

  const [screens, setScreens] = useState([]);
  const [editScreens, setEditScreens] = useState([]);

  const [urls, setUrls] = useState([""]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [screenToRemove, setScreenToRemove] = useState(null);
  const [manageScreensDialogOpen, setManageScreensDialogOpen] = useState(false);

  const [overlayImage, setOverlayImage] = useState(null);
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const [showSaveLayoutDialog, setShowSaveLayoutDialog] = useState(false);
  const [showUpdateLayoutDialog, setShowUpdateLayoutDialog] = useState(false);
  const [layoutName, setLayoutName] = useState("");
  const [selectedSavedLayoutKey, setSelectedSavedLayoutKey] = useState(0);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const location = useLocation();
  const childWindowData = useRef(null);

  const onMessage = (event) => {
    if (event.data && event.data.dataFromIframe) {
      const data = event.data.dataFromIframe;
      if (childWindowData.current) {
        if (childWindowData.current[data._id]) {
          childWindowData.current[data._id] = {
            ...childWindowData.current[data._id],
            ...data,
          };
        } else {
          childWindowData.current[data._id] = data;
        }
      } else {
        childWindowData.current = { [data._id]: data };
      }
    }
  };

  onbeforeunload = (event) => {
    if (childWindowData.current) {
      const data = Object.values(childWindowData.current);
      if (
        screens.length ||
        data.length ||
        backgroundImage ||
        overlayImage ||
        backgroundColor !== "black"
      ) {
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", onMessage);
    window.addEventListener("beforeunload", onbeforeunload);

    return () => {
      window.removeEventListener("message", onMessage);
      window.removeEventListener("beforeunload", onbeforeunload);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  function handleMouseMove() {
    document.body.style.cursor = "default";
    clearTimeout(handleMouseMove.timer);
    handleMouseMove.timer = setTimeout(function () {
      document.body.style.cursor = "none";
    }, 2000);
  }

  const handleOpenManageScreensDialog = () => {
    setEditScreens([...screens]);
    setManageScreensDialogOpen(true);
  };

  const handleCloseManageScreensDialog = () => {
    setManageScreensDialogOpen(false);
    setEditScreens([]);
  };

  const handleUrlChange = (index, value) => {
    setUrls((prevUrls) => {
      const newUrls = [...prevUrls];
      newUrls[index] = value;
      return newUrls;
    });
  };

  const handleOverlayImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setOverlayFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setOverlayImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveOverlayImage = (event) => {
    setOverlayImage(null);
    setOverlayFile(null);
  };

  const handleSwitchChange = (event) => {
    setIsSwitchOn(event.target.checked);
  };

  const handleRemoveScreen = (id) => {
    setScreenToRemove(id);
    setConfirmDialogOpen(true);
  };

  const confirmRemoveScreen = () => {
    setScreens((prevScreens) =>
      prevScreens.filter((screen) => screen._id !== screenToRemove)
    );
    setEditScreens((prevScreens) =>
      prevScreens.filter((screen) => screen._id !== screenToRemove)
    );
    setConfirmDialogOpen(false);
    setScreenToRemove(null);
  };

  const cancelRemoveScreen = () => {
    setConfirmDialogOpen(false);
    setScreenToRemove(null);
  };

  const handleAddScreen = () => {
    setScreens((prevScreens) => [
      ...prevScreens,
      ...urls.map((url, index) => ({
        _id: uuidv4(),
        src: url,
        width: "400",
        height: "200",
      })),
    ]);

    setUrlDialogOpen(false);
    setUrls([""]);
  };

  const handleUpdateScreens = () => {
    setScreens([...editScreens]);
    handleCloseManageScreensDialog();
  };

  const handleUrlUpdates = (screenToUpdate, value) => {
    setEditScreens((prevScreens) => {
      const newScreens = [...prevScreens];
      const targetScreen = newScreens.find(
        (screen) => screen._id === screenToUpdate._id
      );
      if (targetScreen) {
        targetScreen.src = value;
      }
      return newScreens;
    });
  };

  const handleAddUrlField = () => {
    setUrls((prevUrls) => [...prevUrls, ""]);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBackgroundFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setBackgroundImage(null);
    setBackgroundFile(null);
  };

  const changeBackgroundColor = (newColor) => {
    const element = document.getElementById("superScreenPortal");
    if (element) {
      element.style.backgroundColor = newColor;
    }
  };

  const handleColorChange = (newColor) => {
    changeBackgroundColor(newColor);
    setBackgroundColor(newColor);
  };
  const onSave = async () => {
    const screensWithBoundingRectData = screens.map((screen) => {
      const element = document.getElementById(screen._id);
      const boundingRect = element.getBoundingClientRect();
      const transform = element?.style.transform;
      return {
        src: screen.src,
        boundingRect,
        transform: transform.split("rotate")[1]
          ? `rotate${transform.split("rotate")[1]}`
          : "",
        settings:
          childWindowData.current && childWindowData.current[screen._id]
            ? childWindowData.current[screen._id]
            : null,
      };
    });
    if (!layoutName) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Layout name is required.",
      });
      return;
    }

    const payload = {
      eventId: location?.hash.replace("#", ""),
      name: layoutName,
      screens: JSON.stringify(screensWithBoundingRectData),
      enableOverlay: isSwitchOn,
    };
    if (backgroundColor) {
      payload["backgroundColor"] = backgroundColor;
    }
    if (backgroundFile) {
      payload["backgroundImage"] = backgroundFile;
    }
    if (overlayFile) {
      payload["overlayImage"] = overlayFile;
    }

    const response = await createSuperScreenLayout(payload);
    setShowSaveLayoutDialog(false);
    setLayoutName("");
    setSelectedSavedLayoutKey(response.layoutId);
    appContext.triggerToast(true, {
      type: "success",
      message: response.message,
    });
  };
  const onUpdate = () => {
    setShowUpdateLayoutDialog(true);
  };

  const getScreenSettingsPayload = (screen) => {
    const newScreen =
      childWindowData.current && childWindowData.current[screen._id]
        ? childWindowData.current[screen._id]
        : screen.screenSettings;
    if (
      newScreen &&
      newScreen.screenSettings.backgroundImage !== null &&
      newScreen.screenSettings.backgroundImage !== undefined &&
      !validDataUrl(newScreen.screenSettings.backgroundImage)
    ) {
      delete newScreen.screenSettings.backgroundImage;
    }

    if (
      newScreen &&
      newScreen.screenSettings.quoteCards &&
      newScreen.screenSettings.quoteCards.length
    ) {
      newScreen.screenSettings.quoteCards =
        newScreen.screenSettings.quoteCards.map((card) => {
          if (
            card.bgImage !== null &&
            card.bgImage !== undefined &&
            !validDataUrl(card.bgImage)
          ) {
            delete card.bgImage;
          }
          return card;
        });
    }
    return newScreen;
  };
  const update = async () => {
    const screensWithBoundingRectData = screens.map((screen) => {
      const element = document.getElementById(screen._id);
      const boundingRect = element.getBoundingClientRect();
      const transform = element?.style.transform;
      const screenPayload = {
        _id: screen._id,
        src: screen.src,
        boundingRect,
        settings: getScreenSettingsPayload(screen),
      };
      if (selectedLayout && selectedLayout.screens) {
        const isNew = !selectedLayout.screens.some((s) => s._id === screen._id);
        if (isNew) {
          delete screenPayload._id;
        }
      }
      if (transform && transform.split("rotate")[1]) {
        screenPayload["transform"] = `rotate${transform.split("rotate")[1]}`;
      }
      return screenPayload;
    });
    if (!selectedLayout.name) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Layout name is required.",
      });
      return;
    }
    const payload = {
      layoutId: selectedLayout._id,
      eventId: location?.hash.replace("#", ""),
      name: selectedLayout.name,
      screens: JSON.stringify(screensWithBoundingRectData),
      enableOverlay: isSwitchOn,
    };
    if (backgroundColor) {
      payload["backgroundColor"] = backgroundColor;
    }
    if (validDataUrl(backgroundImage) || backgroundImage === null) {
      payload["backgroundImage"] = backgroundFile;
    }
    if (validDataUrl(overlayImage) || overlayImage === null) {
      payload["overlayImage"] = overlayFile;
    }

    const response = await updateSuperScreenLayout(payload);
    setShowSaveLayoutDialog(false);
    setShowUpdateLayoutDialog(false);
    setLayoutName("");
    setSelectedSavedLayoutKey(0);
    setSelectedSavedLayoutKey(selectedLayout._id);
    appContext.triggerToast(true, {
      type: "success",
      message: response.message,
    });
  };

  const onLayoutSelect = (layout) => {
    if (layout) {
      if (layout.screens) {
        setScreens(layout.screens);
      } else {
        setScreens([]);
      }
      if (layout.backgroundImage) {
        setBackgroundImage(layout.backgroundImage);
      } else {
        setBackgroundImage(null);
        setBackgroundFile(null);
      }
      if (layout.overlayImage) {
        setOverlayImage(layout.overlayImage);
      } else {
        setOverlayFile(null);
        setOverlayImage(null);
      }
      if (layout.backgroundColor) {
        setBackgroundColor(layout.backgroundColor);
      } else {
        setBackgroundColor("rgba(0, 0, 0)");
      }
      if (layout.enableOverlay !== undefined && layout.enableOverlay !== null) {
        setIsSwitchOn(layout.enableOverlay);
      } else {
        setIsSwitchOn(false);
      }
      setSelectedLayout(layout);
    } else {
      setScreens([]);
      setBackgroundImage(null);
      setBackgroundFile(null);
      setOverlayImage(null);
      setOverlayFile(null);
      setBackgroundColor("rgba(0, 0, 0)");
      setSelectedLayout(null);
    }
  };

  return (
    <>
      <div
        style={{ backgroundColor }}
        className="container ssSuperScreenPortal"
      >
        <UvDialog
          key="add"
          show={showSaveLayoutDialog}
          onClose={() => {
            setLayoutName("");
            setShowSaveLayoutDialog(false);
          }}
          onSubmit={onSave}
          title="Add Layout"
          submitButtonLable="Add"
        >
          <TextField
            label="Layout Name"
            variant="outlined"
            fullWidth
            value={layoutName}
            onChange={(e) => {
              setLayoutName(e.target.value);
            }}
            margin="normal"
            sx={{ marginBottom: "20px" }}
          />
        </UvDialog>
        <UvDialog
          key="update"
          show={showUpdateLayoutDialog}
          onClose={() => {
            setLayoutName("");
            setShowSaveLayoutDialog(false);
          }}
          onSubmit={update}
          title="Update Layout"
          submitButtonLable="Update"
        >
          <TextField
            label="Layout Name"
            variant="outlined"
            fullWidth
            value={selectedLayout?.name || ""}
            onChange={(e) => {
              setSelectedLayout((pre) => {
                return {
                  ...pre,
                  name: e.target.value,
                };
              });
            }}
            margin="normal"
            sx={{ marginBottom: "20px" }}
          />
        </UvDialog>
        <Dialog open={urlDialogOpen} onClose={() => setUrlDialogOpen(false)}>
          <DialogTitle>Add Screens</DialogTitle>
          <DialogContent className="ssDialogContent">
            {urls.map((url, index) => (
              <div key={index} className="ssUrlTextContainer">
                <TextField
                  label="URL"
                  variant="outlined"
                  fullWidth
                  value={url}
                  onChange={(e) => handleUrlChange(index, e.target.value)}
                  style={{ marginRight: "8px", marginBottom: "8px" }}
                />
                {url && (
                  <IconButton
                    color="primary"
                    onClick={handleAddUrlField}
                    size="large"
                  >
                    <Add />
                  </IconButton>
                )}
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUrlDialogOpen(false)}>Cancel</Button>
            <Button color="primary" onClick={handleAddScreen}>
              Add Screens
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={manageScreensDialogOpen}
          onClose={handleCloseManageScreensDialog}
          fullWidth
        >
          <DialogTitle>Manage Screens</DialogTitle>
          <DialogContent>
            {editScreens.map((screen, index) => (
              <div key={screen._id} className="ssEditScreensContainer">
                <span style={{ marginRight: 8 }}>{`Screen #${index + 1}`}</span>
                <TextField
                  label="URL"
                  variant="outlined"
                  value={screen.src}
                  style={{ marginRight: "8px", flex: 1 }}
                  onChange={(e) => handleUrlUpdates(screen, e.target.value)}
                />
                <IconButton
                  onClick={() => handleRemoveScreen(screen._id)}
                  size="large"
                  style={{ cursor: "pointer", color: COLORS.gray }}
                >
                  <Delete />
                </IconButton>
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseManageScreensDialog}>Close</Button>
            {/* <Button color="primary" onClick={handleUpdateScreens}>
            Update Screens
          </Button> */}
          </DialogActions>
        </Dialog>
        <Dialog
          open={settingDialogOpen}
          onClose={() => setSettingDialogOpen(false)}
        >
          <DialogTitle>Settings</DialogTitle>
          <DialogContent className="ssDialogContent">
            <MuiColorInput
              value={backgroundColor}
              onChange={handleColorChange}
            />

            <div>
              <label htmlFor="overlay-image">Overlay:</label>
              <input
                type="file"
                id="overlay-image"
                onChange={handleOverlayImageChange}
                accept="image/*, video/*"
                style={{ display: "none" }}
              />
              <label htmlFor="overlay-image">
                <IconButton
                  component="span"
                  color="primary"
                  aria-label="upload"
                  size="large"
                >
                  <Wallpaper />
                </IconButton>
                Upload Overlay Image/Video
              </label>
            </div>
            {overlayImage && (
              <>
                {overlayImage.startsWith("data:image") ||
                isVideoOrImage(overlayImage) === "image" ? (
                  <img
                    className="ssOverlayImageSetting"
                    src={overlayImage}
                    alt="Overlay Preview"
                  />
                ) : (
                  <video
                    className="ssOverlayImageSetting"
                    style={{ objectFit: "contain" }}
                    src={overlayImage}
                    autoPlay
                    loop
                    muted
                  />
                )}
                <Button variant="outlined" onClick={handleRemoveOverlayImage}>
                  Remove Overlay Image/Video
                </Button>
              </>
            )}
            <div>
              <label htmlFor="background-image">Background:</label>
              <input
                type="file"
                id="background-image"
                onChange={handleImageChange}
                accept="image/*, video/*"
                style={{ display: "none" }}
              />
              <label htmlFor="background-image">
                <IconButton
                  component="span"
                  color="primary"
                  aria-label="upload"
                  size="large"
                >
                  <Wallpaper />
                </IconButton>
                Upload Background Image/Video
              </label>
            </div>
            {backgroundImage && (
              <>
                {backgroundImage.startsWith("data:image") ||
                isVideoOrImage(backgroundImage) === "image" ? (
                  <img
                    className="ssBackgroundImageSetting"
                    src={backgroundImage}
                    alt="Background Preview"
                  />
                ) : (
                  <video
                    className="ssBackgroundImageSetting"
                    style={{ objectFit: "contain" }}
                    src={backgroundImage}
                    autoPlay
                    loop
                    muted
                  />
                )}
                <Button variant="outlined" onClick={handleRemoveImage}>
                  Remove Background Image/Video
                </Button>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSettingDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmDialogOpen} onClose={cancelRemoveScreen}>
          <DialogTitle>Confirm Removal</DialogTitle>
          <DialogContent>
            Are you sure you want to remove this screen?
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelRemoveScreen}>Cancel</Button>
            <Button onClick={confirmRemoveScreen}>Remove</Button>
          </DialogActions>
        </Dialog>
        {backgroundImage &&
          (backgroundImage.startsWith("data:image") ||
          isVideoOrImage(backgroundImage) === "image" ? (
            <img
              className="ssOverlayImage"
              style={{ zIndex: 0 }}
              src={backgroundImage}
              alt="Overlay"
            />
          ) : (
            <video
              className="ssOverlayImage"
              style={{ objectFit: "contain", zIndex: 0 }}
              src={backgroundImage}
              autoPlay
              loop
              muted
            />
          ))}

        {overlayImage &&
          (overlayImage.startsWith("data:image") ||
          isVideoOrImage(overlayImage) === "image" ? (
            <img className="ssOverlayImage" src={overlayImage} alt="Overlay" />
          ) : (
            <video
              className="ssOverlayImage"
              style={{ objectFit: "contain" }}
              src={overlayImage}
              autoPlay
              loop
              muted
            />
          ))}

        {screens.map((comp, i) => (
          <SuperScreenInstance
            {...comp}
            key={comp._id}
            isSwitchOn={isSwitchOn}
            screenNumber={i + 1}
            isEditEnabled={isEditEnabled}
          />
        ))}
      </div>
      <Drawer>
        <div className="ssControlIconsContainer">
          <SavedLayouts
            eventId={location?.hash.replace("#", "")}
            onLayoutChange={onLayoutSelect}
            selected={selectedSavedLayoutKey}
            onUpdate={onUpdate}
          />
          <Box>
            <Switch
              checked={isSwitchOn}
              onChange={handleSwitchChange}
              color="default"
              title="Enable Overlay"
            />
            Enable Overlay
          </Box>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setUrlDialogOpen(true)}
            fullWidth={true}
            sx={{ mt: 2 }}
          >
            Add Screen
          </Button>
          <Button
            variant="outlined"
            startIcon={<Settings />}
            onClick={() => setSettingDialogOpen(true)}
            fullWidth={true}
            sx={{ mt: 2 }}
          >
            Settings
          </Button>

          <Button
            variant="outlined"
            startIcon={<Edit />}
            onClick={handleOpenManageScreensDialog}
            fullWidth={true}
            sx={{ mt: 2 }}
          >
            Manage Screens
          </Button>

          {selectedLayout && selectedLayout._id ? (
            <Button
              variant="outlined"
              startIcon={<SaveAs />}
              onClick={() => {
                setShowUpdateLayoutDialog(true);
              }}
              fullWidth={true}
              sx={{ mt: 2 }}
              disabled={!screens.length}
            >
              Save Layout
            </Button>
          ) : (
            <Button
              variant="outlined"
              startIcon={<AddCard />}
              onClick={() => {
                setShowSaveLayoutDialog(true);
              }}
              fullWidth={true}
              sx={{ mt: 2 }}
              disabled={!screens.length}
            >
              Add New Layout
            </Button>
          )}
          {/* Lock Icon */}
          {isEditEnabled ? (
            <IconButton
              onClick={() => setIsEditEnabled((pre) => !pre)}
              size="large"
              title="Disable Drag and Resize"
              sx={{ mt: 2 }}
            >
              <Lock fontSize="48"/>
            </IconButton>
          ) : (
            <IconButton
              onClick={() => setIsEditEnabled((pre) => !pre)}
              size="large"
              title="Enable Drag and Resize"
              sx={{ mt: 2 }}
            >
              <LockOpen fontSize="48"/>
            </IconButton>
          )}

          {/* End: Lock icon */}
        </div>
      </Drawer>
    </>
  );
}
