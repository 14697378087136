import "./PercentageBar.css";
const PercentageBar = ({
  percentage,
  correctAnswer,
  correctAnswerValue,
  option,
  highlightBarColor = "gold",
  gameType = 2,
  optionTextColor = "white",
  isActive,
  index,
  settings,
}) => {
  const formatPercentage = (value) => {
    if (Math.floor(value) === value) {
      return value; // return as is if no decimal places
    }

    // Round up to 1 decimal place and return
    const oneDecimalPlace = Math.round(value * 10) / 10;

    if (Math.floor(oneDecimalPlace) === oneDecimalPlace) {
      return oneDecimalPlace;
    }

    // Round up to 2 decimal places and return
    return Math.round(value * 100) / 100;
  };
  const roundedPercentage = formatPercentage(percentage);

  function getAvatarStyle() {
    const border = {
      border: `${settings.borderWidth}px solid ${settings.borderColor}`,
      background:"transparent"
    };
    const scale = { scale: `${settings.mediaCardScale / 100}` };
    const transform = {
      transform: `translateY(-${50 / (settings.mediaCardScale / 100)}%)`,
    };
    let position = {};
    if (settings.alternatePosition) {
      position =
        index % 2 === 0
          ? { right: `calc(100% + ${settings.mediaItemX}px)` }
          : { left: `calc(100% + ${settings.mediaItemX}px)` };
    } else {
      position = {
        [settings.position === "left"
          ? "right"
          : "left"]: `calc(100% + ${settings.mediaItemX}px)`,
      };
    }
    return { ...position, ...border, ...scale, ...transform };
  }

  return (
    <div className={`progressBarWrapper ${index % 2 === 0 ? "left" : "right"}`}>
      {option.optionImage && (
        <img
          className="avatar"
          src={option.optionImage}
          alt={option.optionText}
          style={getAvatarStyle(index)}
        />
      )}

      <div className="progressBarContainer">
        <div className="optionContent" style={{ color: optionTextColor }}>
          <div>{option.optionText}</div>

          <div className="percentageContainer">
            <span className="percentage">{`${roundedPercentage}%`}</span>
          </div>
        </div>
        <div
          style={{
            height: "100%",
            width: `${percentage}%`,
            position: "absolute",
            inset: 0,
            backgroundColor: isActive
              ? highlightBarColor
              : correctAnswer || gameType === "2"
              ? highlightBarColor
              : "#A3A4AA",
          }}
        ></div>
      </div>
    </div>
  );
};
export default PercentageBar;
