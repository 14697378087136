import { useEffect, useRef, useState } from "react";
import "./LightShowMobileWeb.css";
import { socket } from "../../socket";
import { detectionThreshold } from "../LightShow/Common";
import generateUserid from "../../utils/generateUserid";
import Logo from "./Logo";
import { AD_TYPES } from "../AdsSetupManager/functions";
import LightShowEffects from "./LightShowEffects";
import TorchShow from "./TorchShow";
import {
  getStreamingLightShow,
  getLightShowSettingsForEvent,
} from "../Games/networkCalls/networkCalls";
import { CDN_URLS } from "../../constants";
const beatThreshold = 15;
const LightShowMobileWeb = ({ userId, eventData, advertsData }) => {
  const [cameraPermissionMessage, setCameraPermissionMessage] = useState("");
  const [savedSettings, setSavedSettings] = useState(null);
  const getPostEventAdData = () => {
    let data = null;
    if (advertsData && advertsData.length) {
      const postEventAd = advertsData.find(
        (ad) => ad?.adType === AD_TYPES.LIGHT_SHOW
      );
      if (postEventAd) {
        data = postEventAd;
      }
      return data;
    }
  };
  const [postEventAd, setPostEventAd] = useState(getPostEventAdData);
  const [showAd, setShowAd] = useState(() => {
    return !socket.connected;
  });
  const [showLogo, setShowLogo] = useState(true);
  const [show, setShow] = useState(null);

  const [pulse, setPulse] = useState(false);
  const getEventSettings = async () => {
    const response = await getLightShowSettingsForEvent(eventData._id);
    if (response) {
      setSavedSettings(response);
    }
  };
  const logErrors = () => {
    socket.on("connect_error", (err) => {
      // the reason of the error, for example "xhr poll error"
      console.log(err.message);

      // some additional description, for example the status code of the initial HTTP response
      console.log(err.description);

      // some additional context, for example the XMLHttpRequest object
      console.log(err.context);
    });
  };

  let userID = useRef(userId);

  const onDisconnect = () => {
    switchOffFlashlight();
    setPulse(false);
    setShow(null);
  };

  const switchOffFlashlight = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: "environment" },
    });
    const track = stream.getVideoTracks()[0];
    const capabilities = track.getCapabilities();

    if (capabilities.torch) {
      await track.applyConstraints({
        advanced: [{ torch: false }],
      });
      track.stop();
    }
  };
  const onDataArray = ({ triggerDisconnect, dataArray, settings, eventId }) => {
    if (triggerDisconnect) {
      onDisconnect();
      if (!eventId) {
        setShowLogo(false);
        setShowAd(true);
      } else {
        setShowAd(false);
        setShowLogo(true);
      }
      return;
    }
    if (eventId !== eventData._id) {
      return;
    }

    if (dataArray !== null && dataArray !== undefined && settings) {
      const intensity = dataArray;
      const threshold =
        settings.targetFrequency === 0 ? beatThreshold - 1 : detectionThreshold;

      const show = settings;

      // Play on audience phone screen
      setPulse(intensity > threshold);
      setShow(show);
      setShowAd(false);
      setShowLogo(true);
    }
  };

  const getAlreadyStreamingShow = async () => {
    if (eventData?._id) {
      const response = await getStreamingLightShow(eventData._id);
      if (response) {
        if (
          response?.isStreaming &&
          response?.emitData &&
          Object.keys(response.emitData).length
        ) {
          onDataArray(response.emitData);
        }
      }
    }
  };
  useEffect(() => {
    setPostEventAd(getPostEventAdData);
  }, [advertsData]);
  useEffect(() => {
    getEventSettings();
    checkCameraAccess();
    getAlreadyStreamingShow();
    if (userID.current === "" || userID.current === null) {
      const databaseUniqueUserID = localStorage.getItem("databaseUniqueUserID");
      if (databaseUniqueUserID) {
        userID.current = databaseUniqueUserID;
      } else {
        userID.current = generateUserid();
      }
    }

    socket.connect();
    socket.on("dataArray", onDataArray);
    socket.on("disconnect", onDisconnect);

    logErrors();
    return () => {
      socket.disconnect();
      socket.off("dataArray", onDataArray);
      socket.off("disconnect", onDisconnect);
    };
  }, []);
  useEffect(() => {
    if (!pulse) {
      switchOffFlashlight();
    }
  }, [pulse]);
  const checkCameraAccess = () => {
    navigator.permissions
      .query({ name: "camera" })
      .then((permissionStatus) => {
        if (permissionStatus.state !== "granted") {
          console.log("Camera permission is granted.");
          // You can start accessing the camera here
          requestCameraAccess();
        } else {
          setCameraPermissionMessage("");
        }
      })
      .catch((error) => {
        console.error("Error checking camera permission:", error);
      });
  };

  const requestCameraAccess = () => {
    setCameraPermissionMessage(
      "To enable flash effects please allow camera access."
    );
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        // Camera access granted
        setCameraPermissionMessage("");
        console.log("Camera access granted");
      })
      .catch((error) => {
        // Camera access denied or other error occurred
        console.error("Camera access denied", error);
      });
  };

  const getAd = () => {
    if (!showAd) {
      return null;
    }
    if (!postEventAd) {
      return (
        <div className="lsAdContainer">
          {" "}
          <video
            src={CDN_URLS.LIGHTSHOW_DEFAULT_AD}
            playsInline
            autoPlay
            loop
            muted
            className="lsAdMedia"
          />
        </div>
      );
    }
    if (postEventAd.mimeType.includes("video")) {
      return (
        <div className="lsAdContainer">
          <video
            src={postEventAd?.adMediaUrl || CDN_URLS.LIGHTSHOW_DEFAULT_AD}
            playsInline
            autoPlay
            loop
            muted
            className="lsAdMedia"
          />
        </div>
      );
    } else if (postEventAd.mimeType.includes("image")) {
      return (
        <div className="lsAdContainer">
          <img src={postEventAd.adMediaUrl} className="lsAdMedia" alt="Ad" />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="light-show-web-app-container">
      <div className="light-show-preview-web-app">
        {cameraPermissionMessage ? (
          <p
            style={{
              color: "#fff",
              fontSize: "24px",
              textAlign: "center",
              position: "fixed",
              top: 40,
            }}
          >
            {cameraPermissionMessage}
          </p>
        ) : null}
        {pulse && show ? (
          show?.isTorchShow ? (
            <TorchShow animationDuration={show?.animationDuration} />
          ) : (
            <LightShowEffects
              switchOffFlashlight={switchOffFlashlight}
              {...show}
            />
          )
        ) : null}
      </div>

      {showLogo &&
      !showAd &&
      savedSettings &&
      savedSettings.mediaForAudienceScreen ? (
        <Logo {...savedSettings} />
      ) : null}
      {getAd()}
    </div>
  );
};
export default LightShowMobileWeb;
