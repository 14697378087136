import React, { useContext, useEffect, useRef, useState } from "react";
import { gameSubmit } from "../../Games/networkCalls/networkCalls";
import Loader from "../../../components/Loader/Loader";
import "./styles.css";
import { Button, CircularProgress } from "@mui/material";
import {
  formatHyperlink,
  getItemFromLocalStorage,
  isValidEmail,
  saveItemToLocalStorage,
} from "../../../utils/utility";
import { Link, TextField, Typography } from "@mui/material";
import muted from "../../../../src/assets/muted.png";
import unmuted from "../../../../src/assets/unmuted.png";
import {
  AD_TYPES,
  getFullscreenAdHeaderText,
  getFullscreenAdSubHeaderText,
} from "../../AdsSetupManager/functions";
import { SUBMITTED_GAME_IDS_KEY, URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { COLORS } from "../../../utils/colors";
import { AppContext } from "../../../contexts/App.Context";

export function Game(props) {
  const appContext = useContext(AppContext);
  const { pageParams, eventData, userId, advertsData, activeGame } = props;
  const [loading, setLoading] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [gameWon, setGameWon] = useState(null);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [isTimedGame, setIsTimedGame] = useState(false);
  const [timeValue, setTimeValue] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [hasAlreadySubmitted, setHasAlreadySubmitted] = useState(false);
  const [postGameAd, setPostGameAd] = useState(null);
  let enterredEmail = useRef("");

  useEffect(() => {
    if (advertsData === null || advertsData === undefined) return;
    let newAdverts = advertsData.filter((a) => a.adType === AD_TYPES.POST_GAME);
    if (newAdverts.length > 0) setPostGameAd(newAdverts[0]);
  }, [advertsData]);
  const isAlreadySubmitted = () => {
    const savedGamesStateArray = getItemFromLocalStorage(
      SUBMITTED_GAME_IDS_KEY
    );
    if (savedGamesStateArray) {
      const thisGame = savedGamesStateArray.find(
        (game) => game.gameId === activeGame._id
      );
      if (thisGame) {
        // This game has been played at least once

        if (thisGame.lastResetTime) {
          // Game has been reset by the admin at least once
          if (thisGame.lastResetTime === activeGame?.resetAt) {
            // Game has been reset by the admin and the user has played the game
            return true;
          } else {
            // Game has been reset by the admin and the user has not played the game post this reset time
            return false;
          }
        } else {
          if (activeGame?.resetAt) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    }

    return false;
  };
  useEffect(() => {
    if (activeGame) {
      const isSubmitted = isAlreadySubmitted();
      setHasAlreadySubmitted(isSubmitted);
      if (activeGame.isTimed === true && activeGame.timedSeconds !== "0") {
        setIsTimedGame(true);
        setTimeValue(activeGame.timedSeconds);
      }
    }
  }, [activeGame]);

  const handleActivateGame = () => {
    if (activeGame) {
      if (!hasAlreadySubmitted) {
        setGameStarted(true);
        if (isTimedGame) {
          startTimer();
        }
      }
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setCorrectAnswer(activeGame.correctAnswerText); // Set the correct answer
  };

  const handleSubmitResponse = async () => {
    if (!selectedOption) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Please choose an answer",
      });
      return;
    }
    try {
      const submitResponse = await gameSubmit(
        {
          gameId: activeGame._id,
          userId,
          answer: selectedOption.optionText,
        },
        activeGame?.resetAt
      );
      if (activeGame.correctAnswerText === selectedOption.optionText) {
        setGameWon(true);
      } else {
        setGameWon(false);
      }
      if (selectedOption.optionText === correctAnswer) {
        setIsAnswerCorrect(true); // Selected answer is correct
      } else {
        setIsAnswerCorrect(false); // Selected answer is incorrect
      }
      setAnswerSubmitted(true);
      if (isTimedGame && timeLeft !== 0) {
        // Time is still remaining, do not proceed
        return;
      }
      setGameFinished(true);
    } catch (error) {
      console.log("Error submitting game answer:", error);
      // Handle the error as needed
    }

    // Use the submitResponse value here or further process it if necessary
  };

  const startTimer = () => {
    setTimeLeft(timeValue); // Set the initial time value
  };

  const stopTimer = () => {
    setTimeLeft(null);
  };

  // Update the timer every second
  useEffect(() => {
    let timer;
    if (timeLeft !== null && timeLeft > 0) {
      timer = setTimeout(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      // Handle case when time is up
      if (answerSubmitted) {
        if (selectedOption) {
          handleSubmitResponse();
          return;
        }
      }
      setGameWon(false);
      setIsAnswerCorrect(false);
      setAnswerSubmitted(true);
      setGameFinished(true);
    }
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [timeLeft]);

  // Start the timer when the game starts
  useEffect(() => {
    if (gameStarted && isTimedGame) {
      startTimer();
    }
  }, [gameStarted, isTimedGame]);

  const handleGameOver = () => {
    if (isTimedGame) {
      if (timeLeft !== 0) {
        // Time is still remaining, do not proceed
        return;
      }
    }
    setGameFinished(true);
  };

  const goToHome = async () => {
    if (
      postGameAd &&
      eventData.collectContactInfoAt.includes(AD_TYPES.POST_GAME)
    ) {
      if (enterredEmail.current.length === 0) {
        appContext.triggerToast(true, {
          type: "warning",
          message: "Please enter email to receive rewards",
        });
        return;
      }

      if (!isValidEmail(enterredEmail.current)) {
        appContext.triggerToast(true, {
          type: "warning",
          message: "Please enter a valid email address",
        });
        return;
      }
      setLoading(true);
      let body = {
        emailAddress: enterredEmail.current,
        captureSource: AD_TYPES.POST_GAME,
      };
      await uvenuFetcher({
        url: URLS.SUBMIT_USERS_EMAILS(eventData._id),
        method: "POST",
        body: body,
      });
    }
    window.location.reload();
  };

  const RenderPostGameAd = () => {
    const [postGameAdMuted, setPostGameAdMuted] = useState(true);
    const [userEmail, setUserEmail] = useState("");
    return (
      <div className="postGameAd">
        <Link
          href={formatHyperlink(postGameAd.adLink)}
          target="_blank"
          underline="hover"
        >
          {postGameAd.mimeType.includes("video") ? (
            <>
              <video
                className="videoAd"
                src={postGameAd.adMediaUrl}
                autoPlay
                playsInline
                loop
                muted={postGameAdMuted}
                disableRemotePlayback={true}
              />

              <img
                style={{
                  width: 30,
                  height: 30,
                }}
                src={postGameAdMuted ? unmuted : muted}
                alt="mute"
                className="jelgMuteIcon"
                onClick={(e) => {
                  setPostGameAdMuted(!postGameAdMuted);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </>
          ) : (
            <img
              src={postGameAd.adMediaUrl}
              alt="social ad media"
              className="imageAd"
            />
          )}
          <div
            className="jelgActionBar"
            style={
              {
                // marginTop: postGameAd.mimeType.includes("video") ? 25 : 5,
                // backgroundColor:
                //   eventData && eventData.brandingBackgroundColor
                //     ? eventData.brandingBackgroundColor
                //     : COLORS.primary,
              }
            }
          >
            <span className="adTitle">
              {postGameAd.adTitle ? postGameAd.adTitle : "Sponsored Ad"}
            </span>
            <span className="adAction">{">"}</span>
          </div>
        </Link>
        {eventData.collectContactInfoAt.includes(AD_TYPES.POST_GAME) && (
          <div
            className="jelgFullscreenEmailContainer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Typography className="jelgFullscreenEmailText">
              {getFullscreenAdHeaderText(eventData.emailEntryConfigForGames)}
            </Typography>
            <TextField
              sx={{
                width: "calc(100% - 20px)",
                color: "white",
                backgroundColor: "transparent !important",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: COLORS.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLORS.primary,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: COLORS.primary,
                  },
                },
                "&:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 1000px red inset",
                },
              }}
              variant="outlined"
              onInput={(e) => {}}
              defaultValue={userEmail}
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              onChange={(e) => {
                setUserEmail(e.target.value);
                enterredEmail.current = e.target.value;
              }}
            />
            {getFullscreenAdSubHeaderText(eventData.emailEntryConfigForGames)
              .length > 0 && (
              <Typography className="fullscreenAdinfoText">
                {getFullscreenAdSubHeaderText(
                  eventData.emailEntryConfigForGames
                )}
              </Typography>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="jelgMainContainer">
          {" "}
          {activeGame ? (
            <>
              {gameStarted && !gameFinished ? (
                <div className="gameContainer">
                  <img
                    src={activeGame && activeGame.gameImageUrl}
                    alt="Event poster"
                    className="jelgImage"
                  />
                  <div className="questionContainer">
                    <p className="questionText">
                      {activeGame && activeGame.questionText}
                    </p>
                  </div>
                  {isTimedGame ? (
                    <div className="timerContainer">
                      {timeLeft !== null && timeLeft > 0 ? (
                        <>
                          <CircularProgress
                            variant="determinate"
                            value={(timeLeft / timeValue) * 100}
                            size={30}
                            thickness={4}
                            color="secondary"
                          />
                          <div className="timerOverlay">
                            <p className="timerText">{timeLeft}</p>
                            {/* <p className="timerSubtitle">seconds left</p> */}
                          </div>
                        </>
                      ) : (
                        <CircularProgress color="secondary" />
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="optionsContainer">
                    {activeGame.answerOptions.map((option) => (
                      <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        key={option._id}
                        className="web-app-game-option"
                        onClick={() => handleOptionClick(option)}
                      >
                        {option.optionText}
                      </Button>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="gameContainer">
                  {!gameFinished && (
                    <img
                      src={activeGame && activeGame.gameImageUrl}
                      alt="Scaled Img 2"
                      className="jelgImage"
                    />
                  )}
                  {gameFinished ? (
                    postGameAd ? (
                      <RenderPostGameAd />
                    ) : (
                      <img
                        src={activeGame && activeGame.gameImageUrl}
                        alt="Scaled Img 2"
                        className="jelgImage"
                      />
                    )
                  ) : (
                    <></>
                  )}
                  <Typography className="textBelowImage">
                    {gameFinished
                      ? postGameAd &&
                        eventData.collectContactInfoAt.includes(
                          AD_TYPES.POST_GAME
                        )
                        ? ""
                        : isAnswerCorrect
                        ? activeGame.postGameWinningMessage
                        : activeGame.postGameLosingMessage
                      : activeGame && activeGame.gameTitle}
                  </Typography>
                </div>
              )}
              {hasAlreadySubmitted ? (
                <span
                  style={{
                    color: "white",
                    alignSelf: "center",
                    padding: "16px",
                    textAlign: "center",
                  }}
                >
                  You have already submitted your answer.
                </span>
              ) : (
                ""
              )}
            </>
          ) : (
            <span
              style={{ marginTop: "16px", color: "white", alignSelf: "center" }}
            >
              No active game available!
            </span>
          )}
          <div className="jelgButtonContainer">
            {activeGame && gameStarted && !gameFinished ? (
              <>
                {answerSubmitted ? (
                  <Button
                    variant="contained"
                    onClick={handleGameOver}
                    className="grey-button"
                    disabled={isTimedGame && timeLeft !== 0}
                  >
                    {timeLeft === 0 || timeLeft === null
                      ? "CONTINUE"
                      : "Waiting for timer..."}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleSubmitResponse}
                    className="grey-button"
                  >
                    SUBMIT
                  </Button>
                )}
              </>
            ) : gameFinished ? (
              <Button
                variant="contained"
                onClick={goToHome}
                className="grey-button"
                style={{ textTransform: "uppercase" }}
              >
                {postGameAd &&
                eventData.collectContactInfoAt.includes(AD_TYPES.POST_GAME)
                  ? "Submit & Go home"
                  : "Go home"}
              </Button>
            ) : hasAlreadySubmitted ? null : (
              <Button
                variant="contained"
                onClick={handleActivateGame}
                className="grey-button"
              >
                {activeGame ? "JOIN" : "RETRY"}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
