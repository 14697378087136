import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const drawerBleeding = 0;
const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
  height: 80,
  width: 12,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 6,
  position: "absolute",
  left: 8,
  top: "calc(50% - 15px)",
}));

const Puller2 = styled("div")(({ theme }) => ({
  height: 80,
  width: 12,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 6,
  position: "fixed",
  right: drawerBleeding,
  top: "calc(50% - 15px)",
  opacity: 0,
  zIndex: 1000000000,
  "&:hover": {
    opacity: 1,
  },
}));

const Drawer = (props) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container = window.document.body;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            overflow: "visible",
            minWidth: 240,
            padding: 8,
          },
        }}
      />

      {open === false ? <Puller2 onClick={toggleDrawer(true)} /> : null}

      <SwipeableDrawer
        container={container}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {props.children}
        <StyledBox
          sx={{
            position: "absolute",
            left: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            top: 0,
            bottom: 0,
          }}
        >
          {open === true ? <Puller /> : null}
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
};
export default Drawer;
