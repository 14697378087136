import { motion } from "framer-motion";
import "./FlippingQuoteCard.css";
import { FormatQuote } from "@mui/icons-material";
const QuoteIcon = ({ styles, cardWidth }) => (
  <>
    <div
      className="quote-icon-container-2 top-side"
      style={{ color: styles.color }}
    >
      <FormatQuote
        sx={{
          color: styles.contentColor,
          fontSize: `${cardWidth * 0.08}px`,
          width: `${cardWidth * 0.11}px`,
          height: `${cardWidth * 0.08}px`,
        }}
      />
    </div>
    <div
      className="quote-icon-container-2 bottom-side"
      style={{ color: styles.color }}
    >
      <FormatQuote
        sx={{
          color: styles.contentColor,
          fontSize: `${cardWidth * 0.08}px`,
          width: `${cardWidth * 0.11}px`,
          height: `${cardWidth * 0.08}px`,
        }}
      />
    </div>
  </>
);
const FlipCardInner = ({
  isFront = false,
  data,
  styles,
  cardWidth,
  mediaItemRotateBy,
  mediaItemX,
  mediaCardScale,
  borderWidth,
  enableBlur = false,
  showQuoteIcon = false,
  showQuoteOnly = true,
  roundedCorners = false,
  quoteFontSize,
}) => {
  return (
    <div
      className={isFront ? "flip-card-front" : "flip-card-back"}
      style={{
        borderRadius: `${cardWidth * 0.01}px ${cardWidth * 0.09}px`,
        backgroundColor: `${styles.color}`,
        backgroundImage: styles.bgImage ? `url(${styles.bgImage})` : "none",
      }}
    >
      <div className="cardBody" style={{ gap: `${cardWidth * 0.02}px` }}>
        {!showQuoteOnly ? (
          <div
            className="imageContainer-flip"
            style={{
              transform: `rotate(${mediaItemRotateBy}deg)`,
              left: `${mediaItemX}px`,
              scale: `${mediaCardScale / 100}`,
              border: `${borderWidth}px solid ${styles.contentColor}`,
              borderRadius: roundedCorners ? `${cardWidth * 0.02}px` : "0px",
            }}
          >
            <motion.div className="inner">
              {data.mediaFileUrl ? (
                data.mimeType.includes("video") ? (
                  <>
                    <video
                      className="content-video uv-main-media-tag"
                      autoPlay
                      playsInline
                      loop
                      controls={false}
                      muted
                      src={data.mediaFileUrl}
                      disableRemotePlayback={true}
                    />
                    {enableBlur ? (
                      <video
                        className="blur-bg-media"
                        disableRemotePlayback={true}
                        src={data?.mediaFileUrl}
                        playsInline=""
                        loop=""
                      ></video>
                    ) : null}
                  </>
                ) : (
                  <>
                    <img
                      src={data.mediaFileUrl}
                      alt="Social media"
                      className="uv-main-media-tag"
                    />
                    {enableBlur ? (
                      <img
                        className="blur-bg-media"
                        src={data?.mediaFileUrl}
                        alt="Social quote blur background"
                      />
                    ) : null}
                  </>
                )
              ) : (
                <div className="mediacard-placeholder skeleton"></div>
              )}
            </motion.div>
          </div>
        ) : null}

        <div
          className={
            !showQuoteOnly ? "textContainer" : "noMediaQuote textContainer"
          }
          style={{
            padding: `${cardWidth * 0.02}px`,
            color: styles.contentColor,
          }}
        >
          <h2
            style={{
              fontSize: `${cardWidth * ((quoteFontSize * 2.5) / 1000)}px`,
            }}
          >
            {data &&
              data.userId &&
              data.userId
                .split(" ")
                .map(
                  (str) =>
                    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
                )
                .join(" ")}
          </h2>
          <div
            className="description"
            style={{
              fontSize: `${cardWidth * ((quoteFontSize * 1.75) / 1000)}px`,
            }}
          >
            <p>{data.comment}</p>
            {/* <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.
            </p> */}
          </div>
        </div>
        {showQuoteIcon ? (
          <QuoteIcon styles={styles} cardWidth={cardWidth} />
        ) : null}
      </div>
    </div>
  );
};
export default FlipCardInner;
