import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL } from "@ffmpeg/util";
import { CDN_URLS } from "../constants";

// Function to convert ImageData to data URL
export const imageDataToDataURL = (imageData) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  context.putImageData(imageData, 0, 0);
  return canvas.toDataURL(); // Convert to data URL
};

// Function to convert data URL to Blob
export const dataURItoBlob = (dataURI) => {
  let byteString = atob(dataURI.split(",")[1]);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  let bb = new Blob([ab], { type: "image/jpeg" });
  return bb;
};

export const getFilename = (blob) => {
  if (blob.type.includes("image")) {
    return "uvenu_media." + "png";
  } else {
    let ext = "mp4";
    if (blob.type.includes("mov")) {
      ext = "mov";
    } else if (blob.type.includes("webm")) {
      ext = "webm";
    }
    return "uvenu_media." + ext;
  }
};

export const getMediaMimeType = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob.type;
  } catch {
    return 'unknown';
  }
};

export const openNativeShareControl = async (hashtags, url) => {
  if (navigator.share) {
    const response = await fetch(url);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], getFilename(blob), { type: blob.type });
    navigator
      .share({
        title: hashtags ? hashtags : "",
        files: [file],
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    console.log("Share not supported on this browser, do it manually.");
    if (
      window.confirm(
        "Sharing is not supported from your device. For priority event access and advanced features, download the Uvenu app."
      )
    ) {
      const platform = window.navigator.platform.toLowerCase();

      if (platform.startsWith("ip") || platform.startsWith("ma")) {
        // os = "ios"
        window.open("https://apps.apple.com/us/app/uvenu/id1541101568");
      } else if (platform.startsWith("li")) {
        // os = "android"
        window.open(
          "https://play.google.com/store/apps/details?id=com.uvenumobileapp"
        );
      }
    }
  }
};

//Function handling loading in ffmpeg
export const loadFFmpegLibrary = async () => {
  try {
    const ffmpeg = new FFmpeg();
    await ffmpeg.load({
      coreURL: await toBlobURL(CDN_URLS.FFMPEG_CORE, "text/javascript"),
      wasmURL: await toBlobURL(CDN_URLS.FFMPEG_WASM, "application/wasm"),
      workerURL: await toBlobURL(
        CDN_URLS.FFMPEG_CORE_WORKER,
        "text/javascript"
      ),
      log: true,
    });
    return ffmpeg;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const parseAddress = (addressComponents) => {
  let city = fetchValueForKey(addressComponents, "locality", "long_name");
  let stateCountry =
    fetchValueForKey(
      addressComponents,
      "administrative_area_level_1",
      "short_name"
    ) || fetchValueForKey(addressComponents, "country", "short_name");
  return city + ", " + stateCountry;
};

const fetchValueForKey = (addressComponents, key, preference) => {
  let value = "";
  addressComponents.forEach((component) => {
    if (component.types.includes(key)) {
      value = component[preference || "long_name"];
    }
  });
  return value;
};