import React, { useEffect, useState, useContext } from "react";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import QuestionSetterModal from "./QuestionSetterModal";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";
import {
  getAllGameData,
  deleteGameData,
  updateGame,
  deleteGameAnswers,
  cloneGame,
} from "./networkCalls/networkCalls";
import { CDN_URLS, URLS } from "../../../src/constants";
import Loader from "../Social/Loader_social";
import { AppContext } from "../../contexts/App.Context";
import {
  AddCircle,
  Edit,
  Link,
  RestartAlt,
  Delete,
  CopyAll,
} from "@mui/icons-material";
import "./QuestionnaireControlScreen.css";
import { COLORS } from "../../utils/colors";
import PreviewMonitor from "./PreviewMonitor";
import {
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "../../utils/utility";
const SELECTED_GAME_ID_STORAGE_KEY = "selectedGameId";
function DynamicContent({ selectedGameData }) {
  const { name, question, options, gameImageUrl } = selectedGameData;

  return (
    <div className="qcsDynamicParent">
      <div className="qcsImageContainer">
        {gameImageUrl && (
          <img src={gameImageUrl} alt="GameImage" className="qcsGameImage" />
        )}
      </div>
      {/* <h2>{name}</h2> */}
      <div
        className="qcsQuestionBox"
        style={{ backgroundColor: COLORS.gamesQuestionGray }}
      >
        <p className="qcsQuestion" style={{ color: COLORS.textHeader }}>
          {question}
        </p>
      </div>
      <div className="qcsOptionsBox">
        <div className="qcsOptionsContainer">
          {options && options.length > 0 ? (
            options.map((option, index) => (
              <div
                key={index}
                className="qcsOptionBox"
                style={{ backgroundColor: COLORS.gamesQuestionGray }}
              >
                <p
                  className="qcsOptionText"
                  style={{ color: COLORS.textHeader }}
                >
                  {option.optionText}
                </p>
              </div>
            ))
          ) : (
            <p className="qcsNoOptionsText" style={{ color: COLORS.gray }}>
              No options available.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function QuestionnaireControlScreen(props) {
  const appContext = useContext(AppContext);
  const { setAllGameData } = props;
  const [openQuestionSetter, setOpenQuestionSetter] = useState(false);
  const [gameData, setGameData] = useState([]);
  const [selectedGameData, setSelectedGameData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const openQuestionSetterToggle = (isEdit = true) => {
    setOpenQuestionSetter(!openQuestionSetter);
    selectedGameData && setEditMode(isEdit);
  };

  const fetchGameData = async () => {
    try {
      setLoading(true);

      const eventId = props.initialState.event_id;
      const loggedInUserObj = props.initialState.loggedInUserObj;

      const response = await getAllGameData(eventId, loggedInUserObj);

      const gameData = response.map((data) => ({
        question: data.questionText,
        options: data.answerOptions,
        correctAnswer: data.correctAnswerText,
        gameId: data._id,
        ...data,
      }));
      const storedSelectedGameId = getItemFromLocalStorage(
        SELECTED_GAME_ID_STORAGE_KEY
      );
      if (storedSelectedGameId) {
        const selectedGame = gameData.find(
          (game) => game.gameId === storedSelectedGameId
        );
        if (selectedGame) setSelectedGameData(selectedGame);
      } else {
        setSelectedGameData(gameData[0]);
      }

      setGameData(gameData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setGameData([]);
    }
  };

  useEffect(() => {
    fetchGameData();
  }, []);

  useEffect(() => {
    if (gameData.length) setAllGameData(gameData);
  }, [gameData, setAllGameData]);

  useEffect(() => {
    saveItemToLocalStorage(
      SELECTED_GAME_ID_STORAGE_KEY,
      selectedGameData?.gameId
    );
  }, [selectedGameData]);

  const handleCloneGame = async (game) => {
    setSelectedGameData(game);
    await cloneGame(game.gameId, props.initialState.loggedInUserObj);
    appContext.triggerToast(true, {
      type: "success",
      message: `Clone of ${game.gameTitle} created successfully.`,
    });
    await fetchGameData();
  };

  const handleEditGame = (game) => {
    setSelectedGameData(game);
    openQuestionSetterToggle();
  };

  const handleResetGameAnswers = async (game) => {
    await deleteGameAnswers(game.gameId, props.initialState.loggedInUserObj);
    appContext.triggerToast(true, {
      type: "success",
      message: "Game answers reset successfully.",
    });
    await fetchGameData();
  };

  const handleAddGame = () => {
    setSelectedGameData(null);
    openQuestionSetterToggle(false);
  };

  const handleDeleteGame = async (game) => {
    await deleteGameData(game.gameId, props.initialState.loggedInUserObj);
    await fetchGameData();
    const updatedData = gameData.filter((data) => data.gameId !== game.gameId);
    setGameData(updatedData);
    setSelectedGameData(null);
    appContext.triggerToast(true, {
      type: "success",
      message: "Game deleted successfully.",
    });
  };

  const setEditModetoggle = () => {
    setEditMode(!editMode);
  };

  const startStopGame = async (val) => {
    if (selectedGameData?.gameId) {
      const payload = {
        gameId: selectedGameData.gameId,
        isActive: val,
      };
      try {
        await updateGame(payload, props.initialState.loggedInUserObj);
        fetchGameData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleGameOutput = () => {
    if (selectedGameData) {
      navigator.clipboard.writeText(
        URLS.GAME_SCREEN_LINK(
          props.initialState.event_id,
          selectedGameData.gameId
        )
      );
    } else {
      appContext.triggerToast(true, {
        type: "error",
        message: "No game selected.",
      });
    }
  };

  return (
    <div className="games-home">
      {loading && (
        <div className="soOverlay">
          <Loader />
        </div>
      )}
      {openQuestionSetter && (
        <QuestionSetterModal
          openQuestionSetter={openQuestionSetter}
          setOpenQuestionSetter={setOpenQuestionSetter}
          gameData={selectedGameData}
          editMode={editMode}
          setEditModetoggle={setEditModetoggle}
          fetchGameData={fetchGameData}
        />
      )}
      <div className="leftSideContainer">
        <div className="header">
          <div className="actions">
            <div className="total">Games: {gameData.length}</div>
            <Button
              variant="contained"
              disableElevation
              sx={{
                backgroundColor: COLORS.completedColor,
              }}
              onClick={handleGameOutput}
              className="add-light-show-button"
            >
              Copy Output Link
              <Link />
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={handleAddGame}
              className="add-light-show-button"
            >
              <AddCircle />
              Add a Game
            </Button>
          </div>
        </div>
        <div className="list">
          {gameData.map((game, i) => (
            <div
              key={game._id}
              className={`qcs-list-item${
                selectedGameData && selectedGameData._id === game._id
                  ? " selected"
                  : ""
              }`}
              onClick={(e) => {
                setSelectedGameData(game);
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography style={{ color: COLORS.textHeader }}>
                  {game.gameTitle}
                </Typography>
                <div>
                  {game.isActive && (
                    <Typography
                      style={{
                        fontSize: 12,
                        padding: 5,
                        borderRadius: 5,
                        color: COLORS.completedColor,
                        backgroundColor: COLORS.completedBackground,
                        display: "inline-block",
                      }}
                    >
                      Active
                    </Typography>
                  )}
                  <IconButton onClick={(e) => handleEditGame(game)}>
                    <Tooltip title="Edit">
                      <Edit />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={(e) => handleCloneGame(game)}>
                    <Tooltip title="Clone">
                      <CopyAll />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={() => handleResetGameAnswers(game)}>
                    <Tooltip title={"Reset"}>
                      <RestartAlt />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={() => handleDeleteGame(game)}>
                    <Tooltip title={"Delete"}>
                      <Delete />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="monitor">
          {selectedGameData && (
            <PreviewMonitor
              selectedGameData={selectedGameData}
              eventId={props.initialState.event_id}
              key={selectedGameData.gameId}
            />
          )}
        </div>
      </div>

      <div className="games-preview">
        <h3>Preview</h3>
        <div
          className="phoneDiv"
          style={{
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flex: 1,
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="phoneDiv"
              style={{
                background: `url("${CDN_URLS["MOBILE_FRAME"]}") no-repeat`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                position: "absolute",
                zIndex: 2,
                height: "100%",
              }}
            ></div>
            <div
              className="phoneDiv"
              style={{
                position: "absolute",
                height: "100%",
                borderRadius: "10%",
                overflow: "hidden",
                zIndex: 1,
                paddingTop: "20px",
              }}
            >
              {selectedGameData ? (
                <DynamicContent selectedGameData={selectedGameData} />
              ) : (
                <div
                  style={{
                    color: COLORS.textHeader,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  No game selected.
                </div>
              )}
            </div>
            <div
              className="phoneDiv"
              style={{
                position: "absolute",
                height: "100%",
                borderRadius: "10%",
                overflow: "hidden",
                background: COLORS.lightShowBaseBG,
              }}
            ></div>
          </div>

          <div
            style={{
              padding: "20px 24px 0px 24px",
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              className="qcsButton"
              style={{
                backgroundColor: selectedGameData?.isActive
                  ? "#24B8E726"
                  : COLORS.primary,
                marginRight: "20px",
              }}
              onClick={() => startStopGame(true)}
              disabled={
                selectedGameData ? selectedGameData?.isActive === true : true
              }
            >
              Start
            </Button>
            <Button
              variant="contained"
              className="qcsButton"
              style={{
                backgroundColor: !selectedGameData?.isActive
                  ? "#24B8E726"
                  : COLORS.primary,
              }}
              onClick={() => startStopGame(false)}
              disabled={
                selectedGameData ? selectedGameData?.isActive === false : true
              }
            >
              Stop
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({ initialState: state.initialState }),
  actions
)(QuestionnaireControlScreen);
