import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FixedMasonry from "../Social/fixedMasonry/FixedMasonry";
import SocialSlideshow from "./socialSlide/SocialSlideshow";
import SocialCarousel from "./socialCarousel/SocialCarousel";
import SocialQuotes from "./quotes/SocialQuotes";
import QuotesGridLayout from "./quotes/QuotesGridLayout";
import { Dashboard } from "@mui/icons-material";
import { Menu, MenuItem, IconButton } from "@mui/material";
import {
  getItemFromSessionStorage,
  saveItemToSessionStorage,
  postMessageToSuperScreen,
} from "../../utils/utility";
import {
  VARIANT_LOCALSTORAGE_KEYS,
  SOCIAL_DEFAULT_SETTING_OBJECT_POINTERS,
} from "../../utils/defaultSettings";

import "./SocialOutput.css";
import GridLayout from "./gridLayout/GridLayout";
const SocialOutput = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rootKey, setRootKey] = useState(0);
  const [savedSettings, setSavedSettings] = useState(null);
  const [variantFromAPI, setVariantFromAPI] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const localStorageUniqueKey = "variant-selection";
  const { eventId } = useParams();
  const [variant, setVariant] = useState(
    getItemFromSessionStorage(localStorageUniqueKey) !== null
      ? Number(getItemFromSessionStorage(localStorageUniqueKey))
      : 1
  );

  const variantOptions = [
    { displayText: "Fixed Masonry", value: 1 },
    // { displayText: "Scrolling Masonry", value: 2 },
    { displayText: "Flipping Cards Grid", value: 5 },
    { displayText: "Stack of Quote Cards", value: 6 },
    { displayText: "Grid of Quote Cards", value: 7 },
    //,
    // { displayText: "Slide Show", value: 3 },
    // { displayText: "Carousel", value: 4 },
  ];

  useEffect(() => {
    postMessageToSuperScreen({ socailVariant: variant, _id: window.name });
  }, [variant]);

  const renderVariantSelector = (selectedVariantValue) => {
    return (
      <div className="variants-select-container ">
        <IconButton
          className="variant-icon"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size="large"
        >
          <Dashboard />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {variantOptions.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedVariantValue}
              onClick={() => {
                setVariant(option.value);
                saveItemToSessionStorage(localStorageUniqueKey, option.value);
                handleClose();
              }}
            >
              {option.displayText}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const reRender = () => {
    setRootKey((pre) => (pre === 0 ? 1 : 0));
  };

  const onSettingsChange = (screenSettings) => {
    postMessageToSuperScreen({ screenSettings, _id: window.name });
  };

  const getScreen = (_settings) => {
    const settings = variant === variantFromAPI ? _settings : null;
    switch (variant) {
      case 1: {
        return (
          <FixedMasonry
            eventId={eventId}
            reRender={reRender}
            onSettingsChange={onSettingsChange}
            savedSettings={settings}
          />
        );
      }
      // case 2: {
      //   return <SocialMaster eventId={eventId} reRender={reRender} />;
      // }
      case 3: {
        return (
          <SocialSlideshow
            eventId={eventId}
            reRender={reRender}
            onSettingsChange={onSettingsChange}
          />
        );
      }
      case 4: {
        return (
          <SocialCarousel
            eventId={eventId}
            reRender={reRender}
            onSettingsChange={onSettingsChange}
          />
        );
      }
      case 5: {
        return (
          <GridLayout
            eventId={eventId}
            reRender={reRender}
            onSettingsChange={onSettingsChange}
            savedSettings={settings}
          />
        );
      }
      case 6: {
        return (
          <SocialQuotes
            eventId={eventId}
            reRender={reRender}
            onSettingsChange={onSettingsChange}
            savedSettings={settings}
          />
        );
      }
      case 7: {
        return (
          <QuotesGridLayout
            eventId={eventId}
            reRender={reRender}
            onSettingsChange={onSettingsChange}
            savedSettings={settings}
          />
        );
      }
      default: {
        return (
          <FixedMasonry
            eventId={eventId}
            reRender={reRender}
            onSettingsChange={onSettingsChange}
            savedSettings={settings}
          />
        );
      }
    }
  };

  const listenMessage = (event) => {
    if (event.data) {
      if (
        event.data.socailVariant !== undefined &&
        event.data.socailVariant !== null
      ) {
        setVariant(Number(event.data.socailVariant));
        setVariantFromAPI(Number(event.data.socailVariant));
      }
      if (
        event.data.screenSettings !== undefined &&
        event.data.screenSettings !== null
      ) {
        setSavedSettings(event.data.screenSettings);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", listenMessage);
    for (let key in VARIANT_LOCALSTORAGE_KEYS) {
      const localStorageObj = getItemFromSessionStorage(
        VARIANT_LOCALSTORAGE_KEYS[key]
      );
      if (!localStorageObj) {
        saveItemToSessionStorage(
          VARIANT_LOCALSTORAGE_KEYS[key],
          SOCIAL_DEFAULT_SETTING_OBJECT_POINTERS[VARIANT_LOCALSTORAGE_KEYS[key]]
        );
      }
    }
    return () => {
      window.removeEventListener("message", listenMessage);
    };
  }, []);
  return (
    <div>
      {renderVariantSelector(variant)}
      <div key={`${rootKey}-${variant}`}>{getScreen(savedSettings)}</div>
    </div>
  );
};
export default SocialOutput;
