import axios from "axios";

/**
 * This function wraps the 'fetch' function provided in JS. This should be used for making requests
 * from all over the code base. this simplifies logging, and debugging. We can log all our requests in one place
 * this way.
 */
export const uploader = async ({
  url,
  method = "POST",
  headers = {},
  body = {},
  timeout = 0, // timeout is 0 by default, which means the request will be indefinite.
}) => {
  // console.log(`Uploading url: ${url}`);

  headers["Content-Type"] = "multipart/form-data";

  let formData = new FormData();
  Object.entries(body).map(([key, value]) => {
    // console.log(key + ": " + value);
    formData.append(key, value);
  });
  let requestDescription = {
    url,
    method: method,
    headers: headers,
    data: formData,
    transformRequest: (data, headers) => {
      // !!! override data to return formData
      // since axios converts that to string
      return formData;
    },
    onUploadProgress: (progressEvent) => {
      // use upload data, since it's an upload progress
      // iOS: {"isTrusted": false, "lengthComputable": true, "loaded": 123, "total": 98902}
      // console.log(progressEvent);
    },
  };

  try {
    let response = await axios({ ...requestDescription, timeout });
    return {
      statusCode: response.status,
      json: response.data,
    };
  } catch (err) {
    console.log(err);
    return {
      statusCode:
        err.response && err.response.status ? err.response.status : 404,
      json: err.response && err.response.data ? err.response.data : "",
    };
  }
};
