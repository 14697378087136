import React from "react";
import {
  Typography,
  IconButton,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "redux-zero/react";
import actions from "./store/actions";
import UvenuLogo from "./assets/uvenu_logo.png";
import "./Layout.css";
import { useLocation } from "react-router-dom";
import {
  CalendarToday,
  EditCalendar,
  ExitToApp,
  GridView,
  Home,
} from "@mui/icons-material";
import { CDN_URLS } from "./constants";
import { COLORS } from "./utils/colors";

const TopBar = ({ currentUser, doLogout, toggleSidebar }) => {
  const navigate = useNavigate();
  const handleClickLogout = () => {
    doLogout();
    navigate("/");
  };

  const handleRedirectEventList = () => {
    navigate("/events");
  };

  return (
    <div className="topBar">
      <Typography color={COLORS.textHeader} className="nameContainer">
        {currentUser ? currentUser.email : "Admin"}
      </Typography>
      <div className="buttonContainer">
        <IconButton
          size="medium"
          onClick={handleClickLogout}
          className="iconButton"
          sx={{
            color: COLORS.textHeader,
            backgroundColor: "#4CC9F026",
            "&:hover": { backgroundColor: COLORS.primary },
          }}
        >
          <ExitToApp />
        </IconButton>
        <IconButton
          size="medium"
          onClick={handleRedirectEventList}
          className="iconButton"
          sx={{
            color: COLORS.textHeader,
            backgroundColor: "#4CC9F026",
            "&:hover": { backgroundColor: COLORS.primary },
          }}
        >
          <Home />
        </IconButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const ConnectedTopBar = connect(mapStateToProps)(TopBar);

export { ConnectedTopBar };

export function TopLayout({ title, currentUser, doLogout }) {
  return (
    <Grid container className="topBar2">
      <img src={UvenuLogo} alt="logo" className="logo" />
    </Grid>
  );
}

export default connect(
  null,
  actions
)(function ({ title, children, currentUser, doLogout }) {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClickRedirect = (path) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className="layoutContainer"
      style={{ backgroundColor: COLORS.appBackground }}
    >
      {location.pathname === "/events" ||
      location.pathname === "/createNewEvent" ||
      location.pathname === "/editEvent" ? (
        <Drawer
          className="drawer"
          variant="persistent"
          anchor="left"
          open={isSidebarOpen}
          PaperProps={{
            sx: {
              width: 250,
              backgroundColor: COLORS.sideBarBackground,
            },
          }}
        >
          <div className="logoContainer">
            <img src={UvenuLogo} alt="logo" className="logo" />
          </div>
          <List className="drawerList">
            <ListItem
              sx={{
                color:
                  location.pathname === "/events"
                    ? COLORS.primary
                    : COLORS.textLabel,
                backgroundColor:
                  location.pathname === "/events"
                    ? COLORS.sideBarSelectedItemBackground
                    : "transparent",
                "&:hover": {
                  color: COLORS.textHeader,
                  backgroundColor: COLORS.primary,
                },
              }}
              onClick={() => handleClickRedirect("/events")}
            >
              <ListItemIcon>
                <GridView style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            {location.pathname === "/editEvent" && (
              <ListItem
                sx={{
                  color:
                    location.pathname === "/editEvent"
                      ? COLORS.primary
                      : COLORS.textLabel,
                  backgroundColor:
                    location.pathname === "/editEvent"
                      ? COLORS.sideBarSelectedItemBackground
                      : "transparent",
                  "&:hover": {
                    color: COLORS.textHeader,
                    backgroundColor: COLORS.primary,
                  },
                }}
              >
                <ListItemIcon>
                  <EditCalendar style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Edit Event" />
              </ListItem>
            )}
            <ListItem
              sx={{
                color:
                  location.pathname === "/createNewEvent"
                    ? COLORS.primary
                    : COLORS.textLabel,
                backgroundColor:
                  location.pathname === "/createNewEvent"
                    ? COLORS.sideBarSelectedItemBackground
                    : "transparent",
                "&:hover": {
                  color: COLORS.textHeader,
                  backgroundColor: COLORS.primary,
                },
              }}
              onClick={() => handleClickRedirect("/createNewEvent")}
            >
              <ListItemIcon>
                <CalendarToday style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Schedule Event" />
            </ListItem>
            {/* Add more sidebar items as needed */}
          </List>
        </Drawer>
      ) : (
        ""
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          maxHeight: "100vh",
        }}
      >
        <ConnectedTopBar
          currentUser={currentUser}
          title={title}
          doLogout={doLogout}
          toggleSidebar={toggleSidebar}
        />
        <div className="pageContent">{children}</div>
      </div>
    </div>
  );
});
