import React, { useState, useEffect, useRef } from "react";
import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {
  NavigateBefore,
  NavigateNext,
  Pause,
  PlayArrow,
  Settings,
} from "@mui/icons-material";
import "./SocialSlideshow.css";

import SocialOutputSettings from "../Settings";
import {
  DEFAULT_SETTINGS_SLIDE_SHOW,
  VARIANT_LOCALSTORAGE_KEYS,
} from "../../../utils/defaultSettings";
import { getItemFromSessionStorage } from "../../../utils/utility";

const Item = ({ data, className, cardDynamicStyles }) => {
  if (data.mimeType.includes("image") || data.mimeType.includes("gif")) {
    return (
      <div className={`card-slideshow ${className}`} style={cardDynamicStyles}>
        <img
          className="img-slideshow"
          src={data.mediaFileUrl}
          alt={data.mimeType}
        />
      </div>
    );
  } else if (data.mimeType.includes("video")) {
    console.log("video", data);
    return (
      <div className={`card-slideshow ${className}`} style={cardDynamicStyles}>
        <video
          className="video-slideshow"
          autoPlay
          playsInline
          loop
          controls={false}
          muted
          style={cardDynamicStyles}
          src={data.mediaFileUrl}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = ""; // remove broken video link to avoid repeated errors
            console.error("Video load failed:", data.mediaFileUrl);
          }}
          disableRemotePlayback={true}
        >
          Sorry, the video could not be loaded.
        </video>
      </div>
    );
  }
  return null;
};

const getRandomTransition = () => {
  const transitions = ["fade", "slide", "zoom", "rotate", "flip", "slideRight"];
  return transitions[Math.floor(Math.random() * transitions.length)];
};

const MAX_ITEMS = 100;
const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.slideShow;

const SocialSlideshow = (props) => {
  const [items, setItems] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [settings, setSettings] = useState(
    getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
      ? getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
      : DEFAULT_SETTINGS_SLIDE_SHOW
  );

  const eventId = window.location.hash.replace("#", "") || props.eventId;
  const perPage = 20;

  const [isPaused, setIsPaused] = useState(false); // New state to track if paused
  const [transitionClass, setTransitionClass] = useState(getRandomTransition());

  const isEndRef = useRef(isEnd);

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  const handleNext = () => {
    setCurrentItemIndex((prevIndex) => {
      if (prevIndex + 1 >= items.length) {
        return 0;
      }
      return prevIndex + 1;
    });
  };

  const handlePrevious = () => {
    setCurrentItemIndex((prevIndex) => {
      if (prevIndex - 1 < 0) {
        return items.length - 1;
      }
      return prevIndex - 1;
    });
  };

  const handlePause = () => {
    setIsPaused(true);
  };

  const handlePlay = () => {
    setIsPaused(false);
  };

  useEffect(() => {
    isEndRef.current = isEnd;
  }, [isEnd]);

  async function loadSocialData(page) {
    console.log("loadSocialData", page, "|| ", isLoading);
    // if (isLoading) return;
    setIsLoading(true);

    console.log("loadSocialData", eventId, "|| ", page, "|| ", perPage);

    const response = await uvenuFetcher({
      url: URLS.GET_SOCIAL_SCROLL(eventId, page, perPage),
      method: "GET",
    });

    const newMediaFiles = response.json.mediaFiles;
    const uniqueMediaFiles = newMediaFiles.filter(
      (file) => !items.some((item) => item._id === file._id)
    );

    let combinedItems = [...items, ...uniqueMediaFiles];
    let adjustment = 0;

    if (combinedItems.length > MAX_ITEMS) {
      adjustment = combinedItems.length - MAX_ITEMS;
      combinedItems = combinedItems.slice(adjustment);
    }

    setItems(combinedItems);

    setCurrentItemIndex((prevIndex) => {
      const newIndex = prevIndex - adjustment;
      if (newIndex < 0) {
        return 0;
      }
      return newIndex;
    });

    // setItems((prevItems) => [...prevItems, ...uniqueMediaFiles]);

    console.log("uniqueMediaFiles", response.json.mediaFiles.length);

    if (response.json.mediaFiles.length < perPage) {
      setIsEnd(true);
    } else {
      setIsEnd(false);
      setCurrentItemIndex((prevIndex) => prevIndex + 1); // Move to next item
    }

    setIsLoading(false);
  }

  useEffect(() => {
    loadSocialData(pageNo);
  }, [pageNo]);

  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        console.log(currentItemIndex, "currentItemIndex");
        console.log(items.length, "items.length");
        if (currentItemIndex + 2 >= items.length) {
          if (isEndRef.current) {
            setCurrentItemIndex(0); // Reset to the first item if we've reached the end
          } else {
            console.log("next page");
            if (!isEndRef.current) setPageNo((prevPageNo) => prevPageNo + 1); // Fetch next page
            // Don't update currentItemIndex here; let the items update useEffect handle it
          }
        } else {
          setCurrentItemIndex((prevIndex) => prevIndex + 1); // Move to next item
          if (settings.randomTransitions) {
            setTransitionClass(getRandomTransition());
          } else {
            setTransitionClass(settings.selectedTransition);
          }
        }
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    items,
    isPaused,
    currentItemIndex,
    settings.randomTransitions,
    settings.selectedTransition,
  ]);

  useEffect(() => {
    setIsLoading(false);
    let checkInterval;
    const checkLastPage = async () => {
      loadSocialData(pageNo);
    };
    console.log("CHECKER!!!!", isEnd);
    if (isEnd) {
      console.log("inside checker");
      // Check the last page every 10 seconds
      checkInterval = setInterval(checkLastPage, 10000);
    }

    return () => {
      clearInterval(checkInterval);
    };
  }, [isEnd]);

  const dynamicStyles = {
    backgroundColor: settings.backgroundColor,
    backgroundImage: settings.backgroundImage
      ? `url(${settings.backgroundImage})`
      : "none",
  };
  const cardDynamicStyles = {
    border: `${settings.borderWidth}px solid ${settings.borderColor}`,
    borderRadius: `${settings.roundedCorners ? "10px" : "0px"}`,
  };

  return (
    <div
      className="slideshow-container slideShowSocialMediaPortal"
      style={dynamicStyles}
    >
      <IconButton
        className="slideShowSettingsIcon"
        onClick={() => setDialogOpen(true)}
        size="large"
      >
        <Settings />
      </IconButton>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent className="slideShowDialogContent">
          <SocialOutputSettings
            onChange={handleSettingsChange}
            localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
            defaultSettings={DEFAULT_SETTINGS_SLIDE_SHOW}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      {items.length > 0 && (
        <Item
          key={currentItemIndex}
          className={transitionClass}
          data={items[currentItemIndex]}
          cardDynamicStyles={cardDynamicStyles}
        />
      )}
      <div className="slideshow-controls">
        {" "}
        <IconButton onClick={handlePrevious} size="large">
          <NavigateBefore />
        </IconButton>
        {isPaused ? (
          <IconButton onClick={handlePlay} size="large">
            <PlayArrow />
          </IconButton>
        ) : (
          <IconButton onClick={handlePause} size="large">
            <Pause />
          </IconButton>
        )}
        <IconButton onClick={handleNext} size="large">
          <NavigateNext />
        </IconButton>
      </div>
    </div>
  );
};

export default SocialSlideshow;
