import React from "react";
import { Dialog, DialogContent, DialogContentText, Paper } from "@mui/material";
import "./styles.css";
import { COLORS } from "../../utils/colors";

export default (function ({
  previewTitle = "AD PREVIEW",
  previewOpen,
  setPreviewOpen,
  adImageDataUri,
  adMimeType,
}) {
  return (
    <Dialog
      open={previewOpen}
      onClose={() => setPreviewOpen(false)}
      PaperProps={{
        className: "adBoxDialogPaper",
        style: { backgroundColor: COLORS.eventDetailBackground },
      }}
    >
      <DialogContentText
        className="adBoxDialogHeader"
        style={{ color: COLORS.textHeader }}
      >
        <h3 className="adBoxDialogHeader">{previewTitle}</h3>
        <h3 className="adBoxDialogClose" onClick={() => setPreviewOpen(false)}>
          X
        </h3>
      </DialogContentText>
      <DialogContent>
        <div className="adBoxMediaPreview">
          {adMimeType.includes("video") ? (
            <video
              className="adBoxVideoPreviewContainer"
              src={adImageDataUri || "#"}
              autoPlay
              playsInline
              loop
              controls
              disableRemotePlayback={true}
              // muted
            />
          ) : (
            <img
              alt="media"
              className="adBoxVideoPreviewContainer"
              src={adImageDataUri || "#"}
              style={{
                objectFit: "contain",
              }}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
});
