import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import "./quotes.css";
import { useContext, useEffect, useRef, useState } from "react";
import { MAX_QUOTE_LENGTH } from "../../../constants";
import { Button } from "@mui/material";
import generateUserid from "../../../utils/generateUserid";
import { postQuote } from "../../Games/networkCalls/networkCalls";
import { AppContext } from "../../../contexts/App.Context";
import Loader from "../../../components/Loader/Loader";
const QuotesSend = ({ userId, userName, eventData }) => {
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );
  const appContext = useContext(AppContext);
  const countRef = useRef(null);
  const textareaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [coordinates, setCoordinates] = useState([0, 0]);
  let userID = useRef(userId);
  useEffect(() => {
    countRef.current.innerText = 0;
    if (userID.current === "" || userID.current === null) {
      const databaseUniqueUserID = localStorage.getItem("databaseUniqueUserID");
      if (databaseUniqueUserID) {
        userID.current = databaseUniqueUserID;
      } else {
        userID.current = generateUserid();
      }
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinates([position.coords.longitude, position.coords.latitude]);
      },
      (error) => {
        setCoordinates([0, 0]);
      }
    );
  }, []);
  const handleMessageChange = (e) => {
    const count = e.target.value.length;
    countRef.current.innerText = count;
    if (count >= MAX_QUOTE_LENGTH) {
      e.target.value = e.target.value.substring(0, MAX_QUOTE_LENGTH);
      countRef.current.innerText = MAX_QUOTE_LENGTH;
      e.preventDefault();
    }
  };

  const handleSubmit = () => {
    const message = textareaRef.current?.value || "";
    if (message.trim() === "") {
      alert("Please enter a message.");
      textareaRef.current.blur();
      return;
    }
    sendQuote(message);
  };

  async function sendQuote(message) {
    setIsLoading(true);
    let userLocation = { type: "Point", coordinates: coordinates };
    const payload = {
      eventId: eventData._id,
      userId: userName,
      quote: message,
      userLocation: userLocation,
    };
    const uploadResponse = await postQuote(payload);
    setIsLoading(false);
    if (uploadResponse.quote) {
      textareaRef.current.value = "";
      countRef.current.innerText = 0;
      textareaRef.current.focus();
      appContext.triggerToast(true, {
        type: "success",
        message: "Message Submitted Successfully.",
      });
    }
  }
  return (
    <>
      {isLoading ? <div className="loadingSpinnerContainer"><Loader /></div> : null}
      <div className="sendQuoteScreen">
        <div className="quoteHeader">{userName}</div>
        <Textarea
          ref={textareaRef}
          autoFocus={true}
          className="quoteInput"
          aria-label="Quote input"
          minRows={7}
          maxRows={7}
          placeholder="Write your message here."
          defaultValue={""}
          onChange={handleMessageChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
        />
        <div className="quoteLength">
          Message Length: <span ref={countRef}>0</span> / {MAX_QUOTE_LENGTH}
        </div>

        <div className="actionButtons">
          <Button
            variant="outlined"
            onClick={() => {
              textareaRef.current.value = "";
              countRef.current.innerText = 0;
              textareaRef.current.focus();
            }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            sx={{ color: "white", marginLeft: "10px" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};
export default QuotesSend;
