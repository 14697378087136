import React from "react";
import "./Loader.css";
import { CDN_URLS } from "../../constants";

// Component Definition
const Loader = () => {
  return (
    <div className="loader">
      {/* <div className="spinner"></div> */}
      <img
        src={CDN_URLS.UVENU_LOADING}
        alt="Uvenu_Loading"
        style={{ width: "100px", height: "100px", objectFit: "contain" }}
      />
    </div>
  );
};

export default Loader;
