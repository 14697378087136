import { useState, forwardRef } from "react";
import Loader from "../../../components/Loader/Loader";
import { Favorite } from "@mui/icons-material";
const MediaCard = forwardRef(
  ({ data, onLoad, enableBlur = false, settings }, ref) => {
    const { showLikes, showName, fontSize, font, fontColor } = settings;
    const [isLoading, setIsLoading] = useState(false);

    const blurBg = {
      objectFit: "fill",
      filter: "blur(10px)",
      width: "100%",
      height: "100%",
      position: "absolute",
      border: "4px solid white",

      inset: 0,
      zIndex: -1,
      overflow: "hidden",
    };
    const mediaStyle = {
      zIndex: 0,
      position: "relative",
    };

    const recordPlayId = (id) => {
      if (window.playedIds) {
        window.playedIds.indexOf(id) === -1 && window.playedIds.push(id);
      } else {
        window["playedIds"] = [id];
      }
    };
    return (
      <>
        {isLoading && (
          <div className="loader-overlay">
            <Loader />
          </div>
        )}
        {data?.mimeType.includes("video") ? (
          <>
            <video
              className="content-video uv-main-media-tag"
              style={{ ...mediaStyle }}
              autoPlay
              playsInline
              loop
              controls={false}
              muted
              src={data?.mediaFileUrl}
              disableRemotePlayback={true}
              onPlay={() => {
                setIsLoading(false);
                onLoad();
                recordPlayId(data._id);
              }}
              ref={ref}
            />

            {enableBlur ? (
              <video
                className="content-video"
                disableRemotePlayback={true}
                src={data?.mediaFileUrl}
                playsInline=""
                loop=""
                style={blurBg}
              ></video>
            ) : null}
          </>
        ) : data?.mimeType.includes("placeholder") ? (
          <div className="mediacard-placeholder skeleton"></div>
        ) : (
          <>
            <img
              ref={ref}
              src={data?.mediaFileUrl}
              alt="Social media"
              style={{ ...mediaStyle }}
              className="uv-main-media-tag"
              onLoad={() => {
                setIsLoading(false);
                onLoad();
                recordPlayId(data._id);
              }}
            />
            {enableBlur ? (
              <img
                className="content-image"
                src={data?.mediaFileUrl}
                alt="Social media"
                border="10"
                style={blurBg}
              />
            ) : null}
          </>
        )}
        {(showLikes && data.likeCount) || (showName && data.userId) ? (
          <div
            className="media-card-name-likes"
            style={{ fontFamily: font, color: fontColor }}
          >
            {showName ? (
              <div
                style={{
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  flex: 3,
                  fontSize: `${fontSize ? Number(fontSize) / 10 : 1}vw`,
                }}
              >
                <div className="truncate">{data.userId}</div>
              </div>
            ) : null}
            {showLikes && data.likeCount > 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flex: 1,
                  fontSize: `${fontSize ? Number(fontSize) / 10 : 1}vw`,
                }}
              >
                <Favorite sx={{ color: "rgb(255 0 0 / 65%)" }} />
                <div style={{ marginLeft: "4px" }}>{data.likeCount}</div>
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
);

export default MediaCard;
