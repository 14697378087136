import { liveUsersGlobalState } from './liveUsersGlobalState';

export function startDrawingOnCanvas(videoElement, canvasElement) {
  function draw(v, c, w, h) {
    if (v.paused || v.ended) return false;
    drawCover(c, v, w, h);
  }

  function drawCover(ctx, vid, w, h) {
    // Store variables
    let cw = ctx.canvas.clientWidth;
    let ch = ctx.canvas.clientHeight;
    let vw = vid.videoWidth;
    let vh = vid.videoHeight;

    // Prevent black flash
    if (vid.currentTime === 0) return false;

    let r = 0;
    ctx.canvas.width = ctx.canvas.clientWidth;
    ctx.canvas.height = ctx.canvas.clientHeight;
    let rw = cw + 2 * r;
    let rh = (rw * vh) / vw;
    // Cover
    ctx.drawImage(vid, 0, 0, vw, vh, -r, (ch - rh) / 2, rw, rh);
    // Sretch
    ctx.drawImage(vid, 0, 0, cw, ch);
  }

  let cw = canvasElement.clientWidth;
  let ch = canvasElement.clientHeight;

  if (liveUsersGlobalState.blurringInterval) {
    clearInterval(liveUsersGlobalState.blurringInterval);
  }

  liveUsersGlobalState.blurringInterval = setInterval(() => {
    if (videoElement.paused) {
      return;
    }
    window.requestAnimationFrame(() => {
      const context = canvasElement.getContext("2d");
      draw(videoElement, context, cw, ch);
    });
  }, 1000 / 24);
}
