import React, { useEffect } from "react";
import { connect } from "redux-zero/react";
import "./App.css";
import "./globalCssFile.css";
import Login from "./screens/Login";
import CreateNewEvent from "./screens/Events/createNewEvent";
import EventList from "./screens/Events/EventsList";
import EventDetails from "./screens/EventDetails";
import GameScreen from "./screens/Games/GameScreen";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EditEvent from "./screens/Events/EditEvent";
import JoinEventLive from "./screens/JoinEventLive";
import SocialMaster from "./screens/Social/SocialMaster";
import SuperScreen from "./screens/SuperScreen";
import SocialCarousel from "./screens/Social/socialCarousel/SocialCarousel";
import SocialSlideshow from "./screens/Social/socialSlide/SocialSlideshow";
import SocialOutput from "./screens/Social/SocialOutput";
import LightShowMobileWeb from "./screens/LightShow/LightShowMobileWeb";
import SocialDynamicMasonry from "./screens/Social/socialDynamicMasonry/SocialDynamicMasonry";
import FixedMasonry from "./screens/Social/fixedMasonry/FixedMasonry";
import EventScreen_Old from "./screens/Output/EventScreen_Old";
import { updateSignallingConfigs } from "./utils/signallingConfig";
import { AppContextProvider } from "./contexts/App.Context";

function App(props) {
  const triggerUpdateSignalligConfigs = async () => {
    await updateSignallingConfigs(props);
  };
  const initializeOpenBrowserTabCounter = () => {
    // define increment counter part
    const key = `tabsOpen-${window.location}`;
    const tabsOpen = localStorage.getItem(key);
    if (tabsOpen == null) {
      localStorage.setItem(key, 1);
    } else {
      localStorage.setItem(key, parseInt(tabsOpen) + parseInt(1));
    }

    // define decrement counter part
    window.addEventListener("beforeunload", function (e) {
      const newTabCount = localStorage.getItem(key);
      if (newTabCount !== null) {
        localStorage.setItem(key, newTabCount - 1);
      }
    });
  };
  useEffect(() => {
    triggerUpdateSignalligConfigs();
    //initializeOpenBrowserTabCounter();
  }, []);
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/events" element={<EventList />} />
          <Route path="/createNewEvent" element={<CreateNewEvent />} />
          <Route path="/editEvent" element={<EditEvent />} />
          <Route path="/eventDetails" element={<EventDetails />} />
          <Route path="/eventScreen" element={<EventScreen_Old />} />
          <Route path="/joinEventLive" element={<JoinEventLive />} />
          <Route path="/gameScreen" element={<GameScreen />} />
          <Route path="/social-output/:eventId" element={<SocialOutput />} />
          <Route path="/Social" element={<SocialMaster />} />
          <Route path="/SuperScreen" element={<SuperScreen />} />
          <Route path="/SocialCarousel" element={<SocialCarousel />} />
          <Route path="/SocialSlideshow" element={<SocialSlideshow />} />
          <Route
            path="/SocialDynamicMasonry"
            element={<SocialDynamicMasonry />}
          />
          <Route path="/SocialFixedMasonry" element={<FixedMasonry />} />
          <Route path="/JoinLightShow" element={<LightShowMobileWeb />} />
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default connect((state) => ({ serverConfig: state.serverConfig }))(App);
