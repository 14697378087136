import CustomLayout from "../CustomLayout/CustomLayout";

import { Box } from "@mui/material";

import { forwardRef } from "react";
import { defaultUvGridLayouts } from "../../utils/defaultSettings";

const UvGrid = forwardRef((props, ref) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomLayout
        initialLayouts={props.savedLayouts || defaultUvGridLayouts}
        localStorageKey="uVenu-custom-layout"
        ref={ref}
        gameId={props.gameId}
        onLayoutChange={props.onLayoutChange}
        isEditEnabled={props.isEditEnabled}
      >
        {props.children}
      </CustomLayout>
    </Box>
  );
});

UvGrid.displayName = "UvGrid";

export default UvGrid;
