import { connectToMainScreenOnChannel } from './mainScreenConnection';

/* actions.js */
const actions = (store) => ({
  //  currentUser
  setCurrentUser: (state, currentUser) => {
    // if (state.rememberMe) {
    localStorage.setItem("currentUser", JSON.stringify(currentUser));
    // }
    return { ...state, currentUser };
  },
  setRememberMe: (state, rememberMe) => {
    localStorage.setItem("rememberMe", rememberMe);
    return {
      ...state,
      rememberMe,
    };
  },
  doLogout: (state) => {
    localStorage.setItem("currentUser", "");
    return {
      ...state,
      currentUser: null,
    };
  },
  setAllGameData: (state, gameData) => {
    return {
      ...state,
      initialState: {
        ...state.initialState,
        gameData: gameData,
      },
    };
  },
  setEventId: (state, eventId) => {
    return {
      ...state,
      initialState: {
        ...state.initialState,
        event_id: eventId,
      },
    };
  },
  setLoggedInUser: (state, userObj) => {
    return {
      ...state,
      initialState: {
        ...state.initialState,
        loggedInUserObj: userObj,
      },
    };
  },
  setUserCounts: (state, countObj) => {
    return {
      ...state,
      initialState: {
        ...state.initialState,
        userCounts: countObj,
      },
    };
  },
  addConnectionToActiveConnections: (state, userId, pc) => {
    const newActiveConnections = {
      ...state.initialState.activeConnections,
      [userId]: pc
    };
    return {
      ...state,
      initialState: {
        ...state.initialState,
        activeConnections: newActiveConnections
      }
    }
  },
  removeConnectionFromActiveConnections: (state, userId) => {
    const newActiveConnections = {...state.initialState.activeConnections};
    delete newActiveConnections[userId];
    return {
      ...state,
      initialState: {
        ...state.initialState,
        activeConnections: newActiveConnections
      }
    }
  },
  setCurrentlySelectedUserId: (state, userId) => {
    return {
      ...state,
      initialState: {
        ...state.initialState,
        currentlySelectedUserId: userId
      }
    }
  },
  connectToMainScreen: (state, channel) => {
    console.log(`connectToMainScreen called with ${channel}`);
    if(channel === 'none') {
      if(state.initialState.mainScreenPC) {
        state.initialState.mainScreenPC.disconnect && state.initialState.mainScreenPC.disconnect();
        state.initialState.mainScreenPC = undefined;
      }
      return {
        ...state,
        initialState: {
          ...state.initialState,
          mainScreenPCChannel: 'none',
          mainScreenPC: undefined,
        }
      };
    }

    const peer = connectToMainScreenOnChannel(channel, store, state.initialState.mainScreenPC);
    return {
      ...state,
      initialState: {
        ...state.initialState,
        mainScreenPCChannel: channel,
        mainScreenPC: peer
      }
    };
  },
  setCurrentlyStreamingUser: (state, userId) => {
    const previouslyStreaming = state.initialState.currentlyStreamingUser;
    // no change if the already streaming user is the
    // one that has been requested to stream.
    if(previouslyStreaming.userId === userId) {
      return state;
    }

    const previousPcExists = previouslyStreaming.userId && previouslyStreaming.pc;
    if(previousPcExists) {
      // need to tell the old user they aren't live anymore.
      previouslyStreaming.pc.send({ state: "not-live" });
      const activeConnections = state.initialState.activeConnections;
      const userIsNotBeingShownOnAdminPortal = activeConnections[previouslyStreaming.userId] !== previouslyStreaming.pc;
      // if the previously streaming user is not being shown on the admin portal grid view.
      // disconnect its PC.
      if(userIsNotBeingShownOnAdminPortal) {
        previouslyStreaming.pc.disconnect();
      }
    }

    const stopStreamingButtonWasPressed = userId == null;
    if(stopStreamingButtonWasPressed) {
      // previouslyStreaming.pc && previouslyStreaming.pc.send({ state: "not-live" });
      return {
        ...state,
        initialState: {
          ...state.initialState,
          currentlyStreamingUser: {
            userId: "",
            pc: null
          }
        }
      }
    }

    const pc = state.initialState.activeConnections[userId];
    pc.send({ state: "live" });
    return {
      ...state,
      initialState: {
        ...state.initialState,
        currentlyStreamingUser: {
          userId,
          pc
        }
      }
    }
  }
});

export default actions;
