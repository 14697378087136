import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import "./styles.css";
import { COLORS } from "../../../utils/colors";

export default function CustomModal(props) {
  const { open, event, handleContinue, children } = props;

  return (
    <Dialog
      maxWidth={"lg"}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ backgroundColor: "black" }}
    >
      <Paper style={{ backgroundColor: COLORS.textHeader }}>
        <DialogTitle
          id="alert-dialog-title"
          className="gupDialogTitle"
          style={{ color: COLORS.textHeader }}
        >
          {event ? (
            <div className="gupEventInfo">
              <img
                className="gupThumbnailStyle"
                alt={"event"}
                src={event.eventImageUrl}
              />
              <Typography
                className="gupEventName"
                style={{
                  color:
                    event && event.brandingBackgroundColor
                      ? event.brandingBackgroundColor
                      : COLORS.primary,
                }}
              >
                {event.name}
              </Typography>
            </div>
          ) : (
            ""
          )}
        </DialogTitle>
        <DialogContent style={{ padding: 10 }}>{children}</DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={handleContinue}
            className="gupContinueButton"
            style={{
              color: COLORS.textHeader,
              backgroundColor:
                event && event.brandingBackgroundColor
                  ? event.brandingBackgroundColor
                  : COLORS.primary,
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
}
