import { useEffect, useState, useRef } from "react";
import { URLS } from "../../constants";
import Loader from "../Social/Loader_social";

const PreviewMonitor = ({ selectedGameData, eventId }) => {
  const { gameId } = selectedGameData;
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef(null);
  const overlayRef = useRef(null);
  const wrapperRef = useRef(null);

  const getUrl = () => {
    return URLS.GAME_SCREEN_LINK(eventId, gameId);
  };
  const getZoomOutFactor = () => {
    if (!wrapperRef.current) {
      return 1;
    }
    const width = wrapperRef.current.getBoundingClientRect().width;
    const height = wrapperRef.current.getBoundingClientRect().height;
    return Math.max(width / 1920, height / 1080);
  };
  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.onload = () => {
        setIsLoading(false);
      };
    }
  }, []);
  return (
    <>
      <div ref={overlayRef} className="monitorOverlay">
        {isLoading && <Loader />}
      </div>
      <div
        className="iframeWrapper"
        style={{ zoom: getZoomOutFactor() }}
        ref={wrapperRef}
      >
        <iframe
          ref={iframeRef}
          title="Selected game output preview"
          src={getUrl()}
        ></iframe>
      </div>
    </>
  );
};
export default PreviewMonitor;
