import React, { useEffect, useState, useRef } from "react";

import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "./fixedMasonry/FixedMasonry.css";
import { Settings } from "@mui/icons-material";
import SocialOutputSettings from "./Settings";
import {
  DEFAULT_SETTINGS_SCROLLING_MASONRY,
  VARIANT_LOCALSTORAGE_KEYS,
} from "../../utils/defaultSettings";
import { getItemFromSessionStorage } from "../../utils/utility";
import { URLS } from "../../constants";

import "./SocialMaster.css";

import Slide from "./Slide";
import useResize from "../../components/Hooks/useResize";

const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.scrollingMasonry;

const SocialMaster = (props) => {
  const [settings, setSettings] = useState(
    getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
      ? getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
      : DEFAULT_SETTINGS_SCROLLING_MASONRY
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [slides, setSlides] = useState([]);
  const containerRef = useRef(null);
  const containerSize = useResize(containerRef);
  const [winSize, setWinSize] = useState({
    h: window.innerHeight,
    w: window.innerWidth,
  });

  const [initialData, setInitialData] = useState([]);

  const eventId = window.location.hash.replace("#", "") || props.eventId;

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  const renderSettings = () => {
    return (
      <>
        <IconButton
          className="settings-icon"
          onClick={() => setDialogOpen(true)}
          size="large"
        >
          <Settings />
        </IconButton>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Settings</DialogTitle>
          <DialogContent className="dialog-content">
            <SocialOutputSettings
              onChange={handleSettingsChange}
              localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
              defaultSettings={DEFAULT_SETTINGS_SCROLLING_MASONRY}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const getRootContainerStyles = () => {
    return {
      backgroundColor: settings.backgroundColor,
      backgroundImage: settings.backgroundImage
        ? `url(${settings.backgroundImage})`
        : "none",
    };
  };

  let totalPages = useRef(1).current;
  let currentPage = useRef(0).current;
  const perPage = settings.numberOfColumns * 3;
  const fetchContent = () => {
    return fetch(
      URLS.GET_SOCIAL_SCROLL(
        eventId,
        currentPage,
        perPage,
        undefined,
        undefined,
        "desc"
      ),
      {
        method: "GET",
      }
    )
      .then((rawResponse) => {
        return rawResponse.json();
      })
      .then((response) => {
        const mediaFiles = response?.mediaFiles || [];
        if (mediaFiles && mediaFiles.length > 0) {
          totalPages = Math.ceil(response.totalCount / perPage);
          return mediaFiles;
        } else {
          return [];
        }
      });
  };
  const SLIDES = [
    { page: 0, bg: "green" },
    { page: totalPages > 1 ? 1 : 0, bg: "yellow" },
    { page: 0, bg: "green" },
  ];

  const checkAndSetScrolling = (containerHeight, windowHeight) => {
    if (containerHeight > windowHeight) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };
  useEffect(() => {
    if (containerSize && winSize) {
      checkAndSetScrolling(containerSize.height, winSize.h);
    }
  }, [containerSize, winSize]);

  const handleWindowResize = () => {
    setWinSize({ h: window.innerHeight, w: window.innerWidth });
  };

  const getAndSetInitialData = () => {
    currentPage = 0;
    fetchContent();
  };

  const onIsScrollChange = (scroll) => {
    if (scroll) {
      setSlides([...SLIDES]);
    } else {
      setSlides([{ page: 0, bg: "green" }]);
    }
  };

  useEffect(() => {
    onIsScrollChange(isScroll);
  }, [isScroll]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    getAndSetInitialData();
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const getRootContainerClasses = () => {
    return isScroll
      ? `root-container-scrolling ${settings.scrollSpeed}-${settings.numberOfColumns}`
      : "root-container-scrolling";
  };

  const onReset = () => {
    setSlides([{ page: 0, bg: "green" }]);
  };

  return (
    <div
      className={getRootContainerClasses()}
      style={{ ...getRootContainerStyles() }}
      ref={containerRef}
    >
      <>
        {renderSettings()}

        {slides.map((_, i) => (
          <Slide
            key={i + _.bg}
            pageNo={_.page}
            eventId={eventId}
            perPage={settings.numberOfColumns * 3}
            bg={_.bg}
            isLast={i === slides.length - 1}
            totalSlides={slides.length}
            isScroll={isScroll}
            resetCallback={onReset}
            {...settings}
          />
        ))}
      </>
    </div>
  );
};

export default SocialMaster;
