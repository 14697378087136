import "../styles.css";
import { IconButton, Box, BottomNavigation } from "@mui/material";

import gameIcon from "../../../assets/games.png";
import pollIcon from "../../../assets/polls.png";
import socialIcon from "../../../assets/social.png";
import send_icon from "../../../assets/send_camera.png";
import record_icon from "../../../assets/live.png";
import light_show from "../../../assets/light_show.png";
import { TABS } from "../constants";
import { COLORS } from "../../../utils/colors";

export function showBottomTabStatus(title, pageParams) {
  const initialPage = pageParams.page;
  const loadWithOptions = pageParams.loadWithOptions;
  if (
    initialPage === null ||
    initialPage === "" ||
    title.includes(initialPage)
  ) {
    if (loadWithOptions === "true") {
      if (
        initialPage === null ||
        initialPage === "" ||
        initialPage === "live" ||
        title.includes(TABS.SOCIAL)
      ) {
        if (title === "games") {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

const getTabSelectionStatus = (pageParams, currentTabStatus) => {
  if (
    (pageParams.page === null ||
      pageParams.page === "" ||
      pageParams.page === TABS.SOCIAL) &&
    currentTabStatus
  ) {
    return "#3498db";
  }

  return "white";
};

const getTitleStatus = (pageParams, currentTabStatus) => {
  if (
    pageParams.page === null ||
    pageParams.page === "" ||
    pageParams.page === TABS.SOCIAL
  ) {
    if (!currentTabStatus) {
      return false;
    }
    return true;
  }

  return false;
};

function BottomTabButton({
  text,
  value,
  iconSrc,
  iconAlt,
  currentTab,
  setCurrentTab,
  eventData,
  pageParams,
}) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <IconButton
        value={value}
        onClick={() => {
          if (eventData === null) {
            alert(
              "To participate in the event experience, you must be at the event location."
            );
            return;
          }
          setCurrentTab(value);
        }}
        style={{
          marginTop: getTitleStatus(pageParams, currentTab === value) ? -10 : 0,
        }}
        size="large"
      >
        <img
          src={iconSrc}
          alt={iconAlt}
          style={{
            width: 35,
            height: 35,
            objectFit: "contain",
            borderRadius: 5,
          }}
        />
      </IconButton>
      {/* {getTitleStatus(pageParams, currentTab === value) && (
        <Typography
          style={{
            color: getTabSelectionStatus(pageParams, currentTab === value),
            fontSize: 11,
            marginTop: -10,
          }}
        >
          {text}
        </Typography>
      )} */}
    </Box>
  );
}

export default function BottomTabs({
  currentTab,
  pageParams,
  setCurrentTab,
  eventData,
}) {
  return (
    <BottomNavigation
      value={currentTab}
      onChange={(_, newValue) => {
        setCurrentTab(newValue);
      }}
      className="bottomNavigation"
      id="mobile-web-app-footer"
      style={{
        backgroundColor:
          eventData && eventData.brandingBackgroundColor
            ? eventData.brandingBackgroundColor
            : COLORS.appBackground,
      }}
    >
      {showBottomTabStatus(TABS.GAMES, pageParams) && (
        <BottomTabButton
          text={eventData && eventData.isPoll ? "Polls" : "Games"}
          value={TABS.GAMES}
          iconSrc={eventData && eventData.isPoll ? pollIcon : gameIcon}
          iconAlt={eventData && eventData.isPoll ? "polls" : "games"}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          eventData={eventData}
          pageParams={pageParams}
        />
      )}

      {showBottomTabStatus(TABS.LIVE, pageParams) && (
        <BottomTabButton
          text="Live"
          value={TABS.LIVE}
          iconSrc={record_icon}
          iconAlt="home"
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          eventData={eventData}
          pageParams={pageParams}
        />
      )}

      {showBottomTabStatus(TABS.SOCIAL_FEED, pageParams) && (
        <BottomTabButton
          text="Social"
          value={TABS.SOCIAL_FEED}
          iconSrc={socialIcon}
          iconAlt={TABS.SOCIAL_FEED}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          eventData={eventData}
          pageParams={pageParams}
        />
      )}

      {showBottomTabStatus(TABS.SOCIAL_SEND, pageParams) && (
        <BottomTabButton
          text="Send"
          value={TABS.SOCIAL_SEND}
          iconSrc={send_icon}
          iconAlt={TABS.SOCIAL_SEND}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          eventData={eventData}
          pageParams={pageParams}
        />
      )}

      {showBottomTabStatus(TABS.LIGHT_SHOW, pageParams) && (
        <BottomTabButton
          text="Light Show"
          value={TABS.LIGHT_SHOW}
          iconSrc={light_show}
          iconAlt={TABS.LIGHT_SHOW}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          eventData={eventData}
          pageParams={pageParams}
        />
      )}
    </BottomNavigation>
  );
}
