import React, { useEffect, useState, useReducer } from "react";
import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { MuiColorInput } from "mui-color-input";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { Settings, Wallpaper } from "@mui/icons-material";

const initialState = {
  flipped: false,
  backContent: null,
};

function tileReducer(state, action) {
  switch (action.type) {
    case "SET_BACK_CONTENT":
      return { ...state, backContent: action.content };
    case "FLIP":
      return { ...state, flipped: !state.flipped };
    default:
      throw new Error();
  }
}

const MasonryGrid = ({ children, columns }) => {
  return (
    <div
      style={{
        columnCount: columns,
        columnGap: "10px",
        height: "130vh",
        overflow: "hidden",
        columnFill: "balance",
      }}
    >
      {children}
    </div>
  );
};

const AdTile = () => {
  return (
    <div
      style={
        {
          /* styles for your ad tile */
        }
      }
    >
      Example Advert
    </div>
  );
};

const Tile = ({ data, onFlip, flipCounter, tileNumber }) => {
  const [state, dispatch] = useReducer(tileReducer, initialState);

  useEffect(() => {
    data.displayCount += 1;
  }, []);

  useEffect(() => {
    if (flipCounter) {
      // console.log(`Flipping tile number: ${tileNumber}`);
      const nextItem = onFlip();

      dispatch({ type: "SET_BACK_CONTENT", content: nextItem });

      requestAnimationFrame(() => {
        dispatch({ type: "FLIP" });
      });
    } else {
      dispatch({ type: "FLIP" });
    }
  }, [flipCounter]);

  const renderContent = (itemData) => {
    if (
      itemData?.mimeType.includes("image") ||
      itemData?.mimeType.includes("gif")
    ) {
      return (
        <img
          style={{ width: "100%", objectFit: "cover" }}
          src={itemData.mediaFileUrl}
          alt={itemData.mimeType}
        />
      );
    } else if (itemData?.mimeType.includes("video")) {
      return (
        <video
          style={{ width: "100%", objectFit: "cover" }}
          autoPlay
          playsInline
          loop
          controls={false}
          muted
          src={itemData.mediaFileUrl}
          disableRemotePlayback={true}
        />
      );
    }
    return null;
  };

  return (
    <div
      style={{
        width: "100%",
        marginBottom: "10px",
        transformStyle: "preserve-3d",
        perspective: "1000px",
        transform: state.flipped ? "rotateY(180deg)" : "rotateY(0deg)",
        transition: "all 2s 0.5s",
      }}
    >
      <div
        style={{
          backfaceVisibility: "hidden",
          width: "100%",
          height: "100%",
          position: state.flipped ? "absolute" : "relative",
        }}
      >
        {state.flipped ? renderContent(state.backContent) : renderContent(data)}
      </div>
      <div
        style={{
          transform: "rotateY(180deg)",
          backfaceVisibility: "hidden",
          width: "100%",
          height: "100%",
          position: !state.flipped ? "absolute" : "relative",
        }}
      >
        {state.flipped ? renderContent(data) : renderContent(state.backContent)}
      </div>
    </div>
  );
};

const SocialDynamicMasonry = (props) => {
  const [items, setItems] = useState([]); // All fetched items
  const [displayItems, setDisplayItems] = useState([]); // 20 items with front and back properties
  const [tileToFlip, setTileToFlip] = useState(null);
  const eventId = window.location.hash.replace("#", "") || props.eventId;
  const [page, setPage] = useState(0);

  const [backgroundColor, setBackgroundColor] = useState("black");
  const [columns, setColumns] = useState(
    parseInt(localStorage.getItem("columns") || "5")
  );
  const [backgroundImage, setBackgroundImage] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [flipCounter, setFlipCounter] = useState(0);
  const perPage = 20;

  useEffect(() => {
    // Hide scrollbar for Chrome, Safari and Opera
    document.body.style.overflow = "hidden";
    document.body.style["&::-webkit-scrollbar"] = "display: none";

    // Hide scrollbar for IE, Edge and Firefox
    document.body.style["-ms-overflow-style"] = "none"; // IE and Edge
    document.body.style["scrollbar-width"] = "none"; // Firefox

    return () => {
      // Undo the above modifications to the body style
      document.body.style.overflow = null;
      document.body.style["&::-webkit-scrollbar"] = null;
      document.body.style["-ms-overflow-style"] = null;
      document.body.style["scrollbar-width"] = null;
    };
  }, []);

  const fetchContent = async (pageNumber) => {
    const response = await uvenuFetcher({
      url: URLS.GET_SOCIAL_SCROLL(eventId, pageNumber, perPage),
      method: "GET",
    });

    if (response.json.mediaFiles.length > 0) {
      const newItems = response.json.mediaFiles.map((item) => ({
        ...item,
        displayCount: 0,
      }));

      // setItems((prevItems) => [...prevItems, ...newItems]);
      appendUniqueItems(newItems);

      // Initialize displayItems if it's empty
      if (displayItems.length === 0) {
        const initialDisplayItems = newItems.slice(0, 20).map((item) => ({
          front: item,
          back: null,
        }));
        setDisplayItems(initialDisplayItems);
      }

      setPage(pageNumber + 1); // Increment the page number after successful fetch
    }
  };

  const appendUniqueItems = (newItems) => {
    const currentIds = new Set(items.map((item) => item._id));
    const uniqueItems = newItems
      .filter((item) => !currentIds.has(item._id))
      .map((item) => ({
        ...item,
        displayCount: 0,
      }));

    // 1. Sort the existing items by displayCount in descending order
    const sortedItems = [...items].sort(
      (a, b) => b?.displayCount - a?.displayCount
    );

    // 2. Append new unique items
    const combinedItems = [...sortedItems, ...uniqueItems];

    // 3. Trim the array to a maximum of 100 items
    const trimmedItems = combinedItems.slice(0, 100);

    setItems(trimmedItems);
  };

  useEffect(() => {
    fetchContent(page);
    const interval = setInterval(() => {
      fetchContent(page); // Use the page state directly here
    }, 10000);

    return () => clearInterval(interval);
  }, [eventId, page]);

  useEffect(() => {
    const flipInterval = setInterval(() => {
      if (displayItems.length > 0) {
        const randomTileIndex = Math.floor(Math.random() * (columns * 2));
        setTileToFlip(randomTileIndex);
        setFlipCounter((prev) => prev + 1); // Increment the flip counter
      }
    }, Math.random() * 2000 + 3000);
    return () => clearInterval(flipInterval);
  }, [displayItems]);

  const handleFlip = (index) => {
    const currentItem = displayItems[index].front;

    // Get a list of all _ids that are either in the front or back of the displayItems
    const usedIds = new Set(
      displayItems.flatMap((item) => [item.front._id, item.back?._id])
    );

    // Filter out items that are already being displayed
    const availableItems = items.filter((item) => !usedIds.has(item._id));

    // Sort the available items based on displayCount
    const sortedItems = availableItems.sort(
      (a, b) => a?.displayCount - b?.displayCount
    );

    const nextItem = {
      ...sortedItems[0],
      displayCount: sortedItems[0]?.displayCount + 1,
    };

    // Update both front and back in one go
    setDisplayItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {
        front: nextItem,
        back: currentItem,
      };
      return updatedItems;
    });

    setItems((prevItems) => {
      return prevItems.map((item) =>
        item._id === nextItem._id
          ? { ...item, displayCount: item?.displayCount + 1 }
          : item
      );
    });

    setTileToFlip(null);
  };

  useEffect(() => {
    localStorage.setItem("columns", columns.toString());
  }, [columns]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setBackgroundImage(null);
  };

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <IconButton
        className="sdmSettingsIcon"
        onClick={() => setDialogOpen(true)}
        size="large"
      >
        <Settings />
      </IconButton>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent className="sdmDialogContent">
          <FormControl variant="standard">
            <InputLabel>Select Background color</InputLabel>
            <MuiColorInput
              value={backgroundColor}
              onChange={(newColor) => setBackgroundColor(newColor)}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel>Select number of columns</InputLabel>
            <Select
              variant="standard"
              label="Scroll Speed"
              value={columns}
              onChange={(event) => setColumns(event.target.value)}
            >
              {/* Adjust these values as needed */}
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={1}>1</MenuItem>
            </Select>
          </FormControl>
          <div>
            <label htmlFor="background-image">background image:</label>
            <input
              type="file"
              id="background-image"
              onChange={handleImageChange}
              accept="image/*"
              style={{ display: "none" }}
            />
            <label htmlFor="background-image">
              <IconButton
                component="span"
                color="primary"
                aria-label="upload"
                size="large"
              >
                <Wallpaper />
              </IconButton>
              Upload BG Image
            </label>
          </div>
          {backgroundImage && (
            <>
              <img
                src={backgroundImage}
                alt="Background Preview"
                style={{
                  width: "100%",
                  maxHeight: "150px",
                  margin: "10px 0",
                  objectFit: "cover",
                }}
              />
              <Button variant="outlined" onClick={handleRemoveImage}>
                Remove Background Image
              </Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <MasonryGrid columns={columns}>
        {displayItems.map((displayItem, index) => (
          <Tile
            key={displayItem.front._id}
            data={displayItem.front}
            onFlip={() => handleFlip(index)}
            flipCounter={tileToFlip === index ? flipCounter : 0} // Pass the flipCounter prop
            tileNumber={index + 1}
          />
        ))}
        {/* <AdTile /> */}
      </MasonryGrid>
    </div>
  );
};

export default SocialDynamicMasonry;
