import UvDatePickerHoc from "../HOCs/UvDatePickerHoc";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { InputAdornment } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
const UvMobileDatePicker = (props) => {
  return (
    <UvDatePickerHoc>
      <MobileDateTimePicker
        sx={{ backgroundColor: "#404B5D", borderRadius: 1 }}
        {...props}
        slotProps={{
          textField: {
            style: { color: "white", width: "100%" },
            InputProps: {
              style: { color: "#FFFFFF", fontSize: 14, cursor: "pointer" },
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonth sx={{ color: "white" }} />
                </InputAdornment>
              ),
            },
          },
        }}
      />
    </UvDatePickerHoc>
  );
};
export default UvMobileDatePicker;
