import React, { useEffect, useState } from "react";
import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import "./SocialCarousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import { Settings } from "@mui/icons-material";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SocialOutputSettings from "../Settings";
import {
  DEFAULT_SETTINGS_SLIDE_SHOW,
  VARIANT_LOCALSTORAGE_KEYS,
} from "../../../utils/defaultSettings";
import { getItemFromSessionStorage } from "../../../utils/utility";
const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.carousel;

const Item = ({ data, cardDynamicStyles }) => {
  if (data.mimeType.includes("image") || data.mimeType.includes("gif")) {
    return (
      <div className="card-carousel">
        <img
          className="img-carousel"
          src={data.mediaFileUrl}
          alt={data.mimeType}
          style={cardDynamicStyles}
        />
      </div>
    );
  } else if (data.mimeType.includes("video")) {
    return (
      <div className="card-carousel">
        <video
          className="video-carousel"
          autoPlay
          playsInline
          loop
          controls={false}
          muted
          src={data.mediaFileUrl}
          style={cardDynamicStyles}
          disableRemotePlayback={true}
        />
      </div>
    );
  }
  return null;
};

const maxItems = 100;

const SocialCarousel = (props) => {
  const [items, setItems] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const eventId = window.location.hash.replace("#", "") || props.eventId;
  const perPage = 20;

  useEffect(() => {
    // Hide scrollbar for Chrome, Safari and Opera
    document.body.style.overflow = "hidden";
    document.body.style["&::-webkit-scrollbar"] = "display: none";

    // Hide scrollbar for IE, Edge and Firefox
    document.body.style["-ms-overflow-style"] = "none"; // IE and Edge
    document.body.style["scrollbar-width"] = "none"; // Firefox

    return () => {
      // Undo the above modifications to the body style
      document.body.style.overflow = null;
      document.body.style["&::-webkit-scrollbar"] = null;
      document.body.style["-ms-overflow-style"] = null;
      document.body.style["scrollbar-width"] = null;
    };
  }, []);

  useEffect(() => {
    loadSocialData(pageNo);
  }, [pageNo]);

  const [settings, setSettings] = useState(
    getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
      ? getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
      : DEFAULT_SETTINGS_SLIDE_SHOW
  );

  const loadSocialData = async (page) => {
    const gamesResponse = await uvenuFetcher({
      url: URLS.GET_SOCIAL_SCROLL(eventId, page, perPage),
      method: "GET",
    });

    const newMediaFiles = gamesResponse?.json?.mediaFiles;

    // Filter out duplicates
    const uniqueMediaFiles = newMediaFiles?.filter(
      (file) => !items.some((item) => item._id === file._id)
    );

    setItems((prevItems) => {
      const combinedItems = [...prevItems, ...uniqueMediaFiles];
      if (combinedItems.length > maxItems) {
        return combinedItems.slice(combinedItems.length - maxItems);
      }
      return combinedItems;
    });

    if (newMediaFiles.length < perPage && !isEnd) {
      setIsEnd(true);
      // Start polling the same page after a delay to check for new items
      setTimeout(() => loadSocialData(page), 10000); // Poll every 10 seconds
    } else if (newMediaFiles.length === perPage) {
      setIsEnd(false);
    }
  };

  const handleSlideChange = (index, item) => {
    if (index + 3 >= items.length && !isEnd) {
      setPageNo(pageNo + 1);
    }
  };
  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };
  const dynamicStyles = {
    backgroundColor: settings.backgroundColor,
    backgroundImage: settings.backgroundImage
      ? `url(${settings.backgroundImage})`
      : "none",
  };
  const cardDynamicStyles = {
    border: `${settings.borderWidth}px solid ${settings.borderColor}`,
    borderRadius: `${settings.roundedCorners ? "10px" : "0px"}`,
  };
  return (
    <div
      className="container socialCarouselSocialMediaPortal"
      style={dynamicStyles}
    >
      <IconButton
        className="socialCarouselSettingsIcon"
        onClick={() => setDialogOpen(true)}
        size="large"
      >
        <Settings />
      </IconButton>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent className="socialCarouselDialogContent">
          <SocialOutputSettings
            onChange={handleSettingsChange}
            localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
            defaultSettings={DEFAULT_SETTINGS_SLIDE_SHOW}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Carousel
        showArrows={settings.showArrows}
        autoPlay={settings.autoPlay}
        infiniteLoop
        interval={settings.carouselSpeed}
        showStatus={false}
        showThumbs={false}
        stopOnHover
        swipeable
        // dynamicHeight
        emulateTouch
        useKeyboardArrows
        centerMode
        centerSlidePercentage={50}
        showIndicators={false}
        onChange={handleSlideChange}
      >
        {items.map((item, idx) => (
          <div key={idx} className="carousel-slide">
            <Item data={item} cardDynamicStyles={cardDynamicStyles} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SocialCarousel;
