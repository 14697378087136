import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";

export function checkMediaOrientation(
  mediaUrl,
  mimeType,
  callback,
  sharedMediaId
) {
  // Check if the URL is an image
  const imagePattern = /\.(jpeg|jpg|gif|png|svg)$/i;
  if (
    imagePattern.test(mediaUrl) ||
    mimeType.includes("image") ||
    mimeType.includes("gif")
  ) {
    const img = new Image();
    img.onload = function () {
      const orientation = getOrientation(img.width, img.height);
      callback(null, orientation);
      const loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"));
      if (
        loggedInUserObj &&
        sharedMediaId &&
        orientation &&
        orientation.width &&
        orientation.height
      ) {
        // Update db
        saveMetaData(loggedInUserObj, sharedMediaId, orientation);
      }
    };
    img.onerror = function (errorEvent) {
      callback("Error: Unable to load image", null, errorEvent);
    };
    img.src = mediaUrl;
  }
  // Check if the URL is a video
  else if (mimeType.includes("video")) {
    const video = document.createElement("video");
    let isMetadataLoaded = false;

    video.preload = "metadata";
    video.addEventListener("loadedmetadata", function () {
      const orientation = getOrientation(video.videoWidth, video.videoHeight);
      callback(null, orientation);
      const loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"));
      if (
        loggedInUserObj &&
        sharedMediaId &&
        orientation &&
        orientation.width &&
        orientation.height
      ) {
        // Update db
        saveMetaData(loggedInUserObj, sharedMediaId, orientation);
      }
    });
    video.onerror = function (errorEvent) {
      if (!isMetadataLoaded) {
        callback("Error: Unable to load video", mediaUrl, errorEvent);
      }
    };
    video.src = mediaUrl;

    setTimeout(() => {
      if (!isMetadataLoaded) {
        callback("Error: Video metadata loading timed out", mediaUrl);
      }
    }, 10000); // 10 seconds timeout
  } else {
    callback(
      `Error: Encountered Unsupported mimeType ${mimeType} for media URL ${mediaUrl}`
    );
  }

}

export const getOrientation = (width, height) => {
  if (width > height) {
    return { orientation: "landscape", width, height };
  } else if (height > width) {
    return { orientation: "portrait", width, height };
  } else {
    return { orientation: "square", width, height };
  }
};

const saveMetaData = (loggedInUserObj, sharedMediaId, metadata) => {
  try {
    uvenuFetcher({
      method: "PATCH",
      url: URLS.REVIEW_MEDIA,
      headers: {
        token: loggedInUserObj.token,
      },
      body: {
        sharedMediaId,
        metadata,
      },
    }).then((res) => {});
  } catch (error) {
    console.log(error);
  }
};
