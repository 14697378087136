export const COLORS = {
  primary: "#24B8E7",
  secondary: "#FFFFFF00",
  appBackground: "#0D0E13",
  sideBarBackground: "#14181F",
  sideBarSelectedItemBackground: "#4CC9F026",
  topBarBackground: "rgba(34, 41, 52, 0.6)",
  eventDetailBackground: "#3E3E3E",
  popupBackground: "#222934CC",
  textLabel: "#ACB4B8",
  textHeader: "#FFFFFF",
  inputBackground: "#404B5D",
  success: "#BFDB2B",
  error: "#E20049",
  completedColor: "rgba(11, 188, 61, 1)",
  completedBackground: "rgba(25, 177, 68, 0.25)",
  inProgressColor: "rgba(244, 205, 70, 1)",
  inProgressBackground: "rgba(254, 251, 184, 0.25)",
  upcomingColor: "rgba(49, 156, 255, 1)",
  upcomingBackground: "rgba(49, 156, 255, 0.25)",
  thumbGreenColor: "#0BBC3D",
  gray: "#808080",
  contactAvatar: "#CFCFCF",
  previewViewBackground: "#606060",
  liveUsersBackground: "#443b36",
  activeGameIndicator: "#3bff3b",
  gamesQuestionGray: "#515151",
  socialFeedCardBackground: "rgb(25, 25, 25)",
  lightShowBaseBG: "#000000",

  // V2 old colors
  appBlueColor: "#0D1051",
};
