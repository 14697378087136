import { useState, useEffect } from "react";
import { getRandomColor } from "../../utils/utility";
const RandomColorStrobe = ({ color, animationDuration = 1 }) => {
  const [currentColor, setCurrentColor] = useState(color);
  useEffect(() => {
    setCurrentColor(color);
  }, [color]);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor(getRandomColor());
    }, animationDuration * 1000);
    return () => clearInterval(interval);
  }, [animationDuration]);

  return (
    <div
      style={{
        backgroundColor: currentColor,
        display: "flex",
        position: "absolute",
        inset: 0,
      }}
    ></div>
  );
};
export default RandomColorStrobe;
