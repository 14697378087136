import { useEffect, useRef, useState } from "react";
import { CDN_URLS } from "../../constants";
const TorchShow = ({ animationDuration = 1 }) => {
  const timeoutIdFOrOn = useRef(null);
  const timeoutIdFOrOff = useRef(null);
  const [cameraStream, setCameraStream] = useState(null);
  //const [message, setMessage] = useState("");
  const [isOn, setIsOn] = useState(false);
  useEffect(() => {
    const init = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
        });
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();

        if (capabilities.torch) {
          await track.applyConstraints({
            advanced: [{ torch: true }],
          });
          setIsOn(true);
          setCameraStream(stream);
        } else {
          console.log("Torch not available");
        }
      } catch (err) {
        console.error("Error accessing torch:", err);
        //setMessage("Error accessing torch");
      }
    };
    init();
    return () => {
      if (cameraStream) {
        cameraStream.getVideoTracks().forEach((track) => {
          track
            .applyConstraints({
              advanced: [{ torch: false }],
            })
            .then(() => {
              track.stop();
            });
        });
      }
    };
  }, []);

  const switchOff = () => {
    if (cameraStream) {
      const track = cameraStream.getVideoTracks()[0];
      track.applyConstraints({
        advanced: [{ torch: false }],
      });
      setIsOn(false);
    }
  };
  const switchOn = () => {
    if (cameraStream) {
      const track = cameraStream.getVideoTracks()[0];
      track.applyConstraints({
        advanced: [{ torch: true }],
      });
      setIsOn(true);
    }
  };
  useEffect(() => {
    if (isOn) {
      clearTimeout(timeoutIdFOrOn.current);
      timeoutIdFOrOff.current = setTimeout(switchOff, animationDuration * 1000);
    } else {
      clearTimeout(timeoutIdFOrOff.current);
      timeoutIdFOrOn.current = setTimeout(switchOn, animationDuration * 1000);
    }
    return () => {
      clearTimeout(timeoutIdFOrOff.current);
      clearTimeout(timeoutIdFOrOn.current);
    };
  }, [isOn, animationDuration]);

  return (
    <div
      style={{
        backgroundColor: "#000",
        display: "flex",
        position: "absolute",
        inset: 0,
        justifyContent: "center",
        color: "#fff",
      }}
    >
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3>Torch Show</h3>
        {isOn ? (
          <img
            className="torchImage"
            src={CDN_URLS.FLASH_ON}
            alt="flash light on"
          />
        ) : (
          <img
            className="torchImage"
            src={CDN_URLS.FLASH_OFF}
            alt="flash light off"
          />
        )}
      </div>
    </div>
  );
};
export default TorchShow;
