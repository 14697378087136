import React, { useState } from "react";
import "./styles.css";
import { Button, Box } from "@mui/material";
import UserEmailsDashboard from "./UserEmailsDashboard";
import { CheckCircle } from "@mui/icons-material";
import { COLORS } from "../../utils/colors";

export default function ({ event }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="metricsContainer">
      <div
        className="buttonsContainer"
        style={{ backgroundColor: "#222934" }}
      >
        <Box>
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 0 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => handleTabChange(0)}
            variant="contained"
            startIcon={activeTab === 0 ? <CheckCircle /> : null}
          >
            User Emails
          </Button>
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              borderColor: COLORS.primary,
              backgroundColor:
                activeTab === 1 ? COLORS.sideBarBackground : COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => handleTabChange(1)}
            variant="contained"
            startIcon={activeTab === 1 ? <CheckCircle /> : null}
          >
            Event Metrics
          </Button>
        </Box>
      </div>
      <div className="metricsContentContainer">
        {activeTab === 0 && <UserEmailsDashboard event={event} />}
        {activeTab === 1 && (
          <div>
            <h2 style={{ color: "white", textAlign: "center" }}>
              Coming soon...
            </h2>
          </div>
        )}
      </div>
    </div>
  );
}
