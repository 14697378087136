import React from "react";
import { Card } from "@mui/material";
import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import "../styles.css";

import actions from "../../../store/actions";
import { connect } from "redux-zero/react";
import { RenderOnlineUsers } from "./RenderOnlineUsers";
import { liveUsersGlobalState } from "./liveUsersGlobalState";
import LoadPreviewForSelectedUser from "./LoadPreviewForSelectedUser";
import { useBeforeunload } from "react-beforeunload";

export async function sendCommandToMainScreen(eventId, channel) {
  await uvenuFetcher({
    url: URLS.EVENT_SCREEN_COMMAND_PUBLISH_LINK,
    method: "POST",
    body: {
      eventId,
      data: {
        type: "command",
        channel,
      },
    },
  });
}

function LiveUsers({
  event,
  usersType,
  loggedInUser,
  setCurrentlySelectedUserId,
}) {
  liveUsersGlobalState.loggedInUserObj = loggedInUser;
  const MESSAGE =
    "Live output will get disconnected. Are you sure you want to move away?";
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    return MESSAGE;
  };
  useBeforeunload(handleBeforeUnload);
  return (
    <Card className="mainCard">
      <RenderOnlineUsers
        event={event}
        usersType={usersType}
        onUserSelected={(u) => {
          setCurrentlySelectedUserId(u.eventUserId);
        }}
        onUserBlocked={async (userId) => {
          // If the user that was blocked is the currentPreviewUserId.
          if (liveUsersGlobalState.currentPreviewUserId === userId) {
            liveUsersGlobalState.currentPreviewUserId = "";
            liveUsersGlobalState.reloadPreview({
              noUserSelection: true,
              loadingStatus: false,
            });
          }
          if (liveUsersGlobalState.currentOutputUserId === userId) {
            liveUsersGlobalState.currentOutputUserId = "";
            await sendCommandToMainScreen(event._id, `none`);
            liveUsersGlobalState.onCurrentStreamChanged("");
          }
        }}
      />
      <LoadPreviewForSelectedUser event={event} />
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.currentUser,
  };
};
export default connect(mapStateToProps, actions)(LiveUsers);
