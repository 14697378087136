import {
  ENABLE_WEBRTC_DEBUG_LOGGING,
  ICE_SERVERS,
  SIGNALING_SERVER_URL,
} from "../constants";
import { getVideoAudioTracks, getVideoTrackFromPeerConnection } from '../utils/getVideoTrackFromPeerConnection';

let videoTrackWatcherInterval = null;

function disconnectOldPeerConnection(oldPeerConnection) {
  if (oldPeerConnection) {
    // call disconnect on the old peer connection object after 5 seconds.
    // why not disconnect right away?
    // this function is called after we add a stream to the connection with
    // the main screen. This stream can take some time to be visible on that side
    // the main screen switches the display when a stream is received. If we disconnect
    // before the stream is received on the main screen the current stream will go black
    // which is not ideal, so we call disconnect with a delay of 5 seconds.
    //
    // the main responsibility of disconnecting peer connections lies with the main screen
    // it calls disconnect on the old connection as soon as it receives a new stream. But incase
    // that doesn't happen, we have this as a safeguard to make sure we're not leaking peer connection
    // objects in memory.
    setTimeout(() => {
      oldPeerConnection.disconnect && oldPeerConnection.disconnect();
    }, 5000);
  }
}

export function connectToMainScreenOnChannel(channel, store, oldPeerConnection) {
  try {
    const pc = new window.SimplePeerController({
      roomId: channel,
      baseUrl: SIGNALING_SERVER_URL,
      EventSource: EventSource,
      iceServers: ICE_SERVERS,
      noConfirmation: true,
      enableDebugLogging: ENABLE_WEBRTC_DEBUG_LOGGING,
      tryInitiator: true
    });
    pc.on("connecting", () => {
      console.log("connecting to main...");
    });

    pc.on("reconnecting", () => {
      console.log("reconnecting to main...");
    });

    pc.on("connected", () => {
      console.log("connected to main...");
      // Everytime we connect to the main screen.
      // we must query the store for the currently streaming user
      // and send their media stream out.
      const state = store.getState();
      const userPeerConnection = state.initialState.currentlyStreamingUser ? state.initialState.currentlyStreamingUser.pc : undefined;
      if(userPeerConnection) {
        const videoTrack = getVideoAudioTracks(userPeerConnection);
        if(videoTrack) {
          pc.addStream(new MediaStream(videoTrack));
          disconnectOldPeerConnection(oldPeerConnection);
        } else {
          // clear if there's an older watcher present.
          if(videoTrackWatcherInterval) {
            clearInterval(videoTrackWatcherInterval);
          }
          // A retry mechanism to keep watching when the video track
          // until it becomes available and then do `addStream` to pc.
          videoTrackWatcherInterval = setInterval(() => {
            const videoTrack = getVideoAudioTracks(userPeerConnection);
            if(videoTrack) {
              pc.addStream(new MediaStream(videoTrack));
              disconnectOldPeerConnection(oldPeerConnection);
              clearInterval(videoTrackWatcherInterval);
            }
          }, 500);
        }
      }
    });

    return pc;
  } catch (error) {
    console.log("Error setting up peer controller:", error);
    return undefined;
  }
}