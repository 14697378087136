import { useState, createContext } from "react";
export const Ctx = createContext();

export const SocialQuotesContextProvider = ({ children }) => {
  const [firstCardData, setFirstCardData] = useState(null);
  const setFirstCardValue = (data) => {
    setFirstCardData(data);
  };

  return (
    <Ctx.Provider value={{ firstCardData, setFirstCardValue }}>
      {children}
    </Ctx.Provider>
  );
};
