import "./FlashCover.css";
const FlashCover = () => {
  return (
    <div className="flashCover">
      <div className="white-div">
        <div className="hole"></div>
      </div>
    </div>
  );
};
export default FlashCover;
