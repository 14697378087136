import React, { useEffect, useState } from "react";
import { connect } from "redux-zero/react";
import CustomModal from "../BrandingOverlayManager/Modal";
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  Divider,
  Tooltip,
  Switch,
  Typography,
} from "@mui/material";
import {
  AddAPhotoOutlined as AddAPhotoOutlinedIcon,
  Close,
} from "@mui/icons-material";
import { MuiColorInput } from "mui-color-input";
import AdPreviewBox from "../../screens/AdsSetupManager/AdPreviewBox";
import { COLORS } from "../../utils/colors";
import "../BrandingOverlayManager/styles.css";
import { getMediaMimeType } from "../../utils/mediaFunctions";

function getBlurEnabled(data) {
  return data && data.brandingBackgroundBlurEnabled
    ? data.brandingBackgroundBlurEnabled
    : false;
}

function getSelectedColor(data) {
  return data && data.brandingBackgroundColor
    ? data.brandingBackgroundColor
    : COLORS.appBackground;
}

function getBrandingColrEnabled(data) {
  return data && data.brandingBackgroundColorEnabled
    ? data.brandingBackgroundColorEnabled
    : false;
}

function getImageUri(data) {
  return data && data.brandingBackgroundImageURI
    ? data.brandingBackgroundImageURI
    : data.brandingBackgroundImage;
}

function getBrandingImageEnabled(data) {
  return data && data.brandingBackgroundImageEnabled
    ? data.brandingBackgroundImageEnabled
    : false;
}

export default connect((state) => {
  return {
    currentUser: state.currentUser,
  };
}, null)(function ({
  modalVisible,
  handleCloseModal,
  brandingModal,
  setBrandingModal,
  currentUser,
}) {
  const [brandingModalObj, setBrandingModalObj] = useState(null);

  const [blurCheckedStatus, setBlurCheckedStatus] = useState(false);

  const [colorCheckedStatus, setColorCheckedStatus] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");

  const [imageCheckedStatus, setImageCheckedStatus] = useState(false);
  const [imageDataUri, setImageDataUri] = useState();
  const [mimeType, setMimeType] = useState("image");
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setBrandingModalObj(brandingModal);

    setBlurCheckedStatus(getBlurEnabled(brandingModal));

    setSelectedColor(getSelectedColor(brandingModal));
    setColorCheckedStatus(getBrandingColrEnabled(brandingModal));

    setImageDataUri(getImageUri(brandingModal));
    setImageCheckedStatus(getBrandingImageEnabled(brandingModal));

    getMediaMimeType(getImageUri(brandingModal)).then((type) => {
      setMimeType(type);
    });
  }, [brandingModal]);

  const delegateClickToFileElement = () =>
    document.getElementById(`selectBrandingBackgroundImage`).click();

  const handleImageUpload = async (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      setMimeType(file.type);
      onChangeValues(reader.result, imageCheckedStatus);
    };
    reader.readAsDataURL(file);
  };

  const onChangeValues = (uri, status) => {
    let liveAdObj = brandingModalObj;
    if (uri && uri.split(":")[0] === "data") {
      setImageDataUri(uri);
      liveAdObj.brandingBackgroundImage = uri;
      liveAdObj.brandingBackgroundImageURI = uri;
      liveAdObj._id = null;
    }
    liveAdObj.brandingBackgroundImageEnabled = status;
    setBrandingModalObj(liveAdObj);
  };

  const handleRemove = () => {
    setImageDataUri(null);
    let modalObj = brandingModalObj;
    modalObj.brandingBackgroundImage = null;
    modalObj.brandingBackgroundImageURI = null;
    modalObj._id = null;
    setBrandingModalObj(modalObj);
  };

  return (
    <CustomModal
      open={modalVisible}
      handleClose={() => {
        handleCloseModal();
      }}
      handleSubmit={async () => {
        if (imageCheckedStatus && imageDataUri == null) {
          alert("background image is required when enabled");
          return;
        }
        const brandingModalObjCopy = brandingModalObj;
        setBrandingModal(brandingModalObjCopy);
        handleCloseModal();
      }}
      title={"Output background settings"}
    >
      <div className="advertParentDiv">
        <Card
          className="backgroundRoot"
          style={{ backgroundColor: COLORS.popupBackground }}
        >
          <CardContent>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                Enable background blur
              </Typography>
              <Switch
                style={{ alignSelf: "flex-end" }}
                defaultChecked={blurCheckedStatus}
                checked={blurCheckedStatus}
                onChange={(e) => {
                  setImageCheckedStatus(false);
                  setColorCheckedStatus(false);
                  setBlurCheckedStatus(e.target.checked);

                  let modalObj = brandingModalObj;
                  modalObj.brandingBackgroundColorEnabled = false;
                  modalObj.brandingBackgroundImageEnabled = false;
                  modalObj.brandingBackgroundBlurEnabled = e.target.checked;
                  setBrandingModalObj(modalObj);
                }}
              />
            </div>
            <Divider
              sx={{
                width: "100%",
                marginTop: 1,
                marginBottom: 1,
                backgroundColor: COLORS.textLabel,
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "6px 0px",
                marginBottom: 10,
              }}
            >
              <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                Webconnect theme color:
              </Typography>
              <MuiColorInput
                sx={{
                  backgroundColor: COLORS.inputBackground,
                  borderRadius: 2,
                }}
                inputProps={{
                  style: { color: COLORS.textHeader },
                }}
                fullWidth
                value={selectedColor}
                onChange={(newColor) => {
                  setSelectedColor(newColor);
                  let modalObj = brandingModalObj;
                  modalObj.brandingBackgroundColor = newColor;
                  setBrandingModalObj(modalObj);
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                Enable as background color
              </Typography>
              <Switch
                style={{ alignSelf: "flex-end" }}
                defaultChecked={colorCheckedStatus}
                checked={colorCheckedStatus}
                onChange={(e) => {
                  setBlurCheckedStatus(false);
                  setImageCheckedStatus(false);
                  setColorCheckedStatus(e.target.checked);

                  let modalObj = brandingModalObj;
                  modalObj.brandingBackgroundBlurEnabled = false;
                  modalObj.brandingBackgroundImageEnabled = false;
                  modalObj.brandingBackgroundColorEnabled = e.target.checked;
                  setBrandingModalObj(modalObj);
                }}
              />
            </div>
            <Divider
              sx={{
                width: "100%",
                marginTop: 1,
                marginBottom: 1,
                backgroundColor: COLORS.textLabel,
              }}
            />
            <Badge
              overlap="rectangular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              badgeContent={
                <div
                  className="badgeDiv"
                  style={{
                    display: imageDataUri ? null : "none",
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  onClick={handleRemove}
                >
                  <Tooltip title={"Remove"}>
                    <Avatar className="closeAvatar">
                      <Close />
                    </Avatar>
                  </Tooltip>
                </div>
              }
            >
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <div
                    className="badgeDiv"
                    style={{ marginRight: -70, marginBottom: -40 }}
                    onClick={delegateClickToFileElement}
                    onChange={(e) => handleImageUpload(e)}
                  >
                    <input
                      id={`selectBrandingBackgroundImage`}
                      hidden
                      type="file"
                      accept="image/*, video/*"
                    />
                    <Tooltip
                      title={"Select branding background image water mark"}
                    >
                      <Avatar
                        style={{ border: `1px solid ${COLORS.textHeader}` }}
                      >
                        <AddAPhotoOutlinedIcon />
                      </Avatar>
                    </Tooltip>
                  </div>
                }
              >
                <div onClick={() => imageDataUri && setShowPreview(true)}>
                  {mimeType.includes("video") ? (
                    <video
                      autoPlay
                      playsInline
                      loop
                      controls={false}
                      muted
                      src={imageDataUri || "#"}
                      disableRemotePlayback={true}
                      className="bgMedia"
                      style={{
                        borderColor: COLORS.contactAvatar,
                      }}
                    />
                  ) : (
                    <Avatar
                      src={imageDataUri || "#"}
                      variant={"square"}
                      className="bgMedia"
                      style={{
                        borderColor: COLORS.contactAvatar,
                      }}
                      imgProps={{
                        style: {
                          objectFit: "contain",
                          borderColor: COLORS.contactAvatar,
                        },
                      }}
                    />
                  )}
                </div>
              </Badge>
            </Badge>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                Enable background image
              </Typography>
              <Switch
                style={{ alignSelf: "flex-end" }}
                defaultChecked={imageCheckedStatus}
                checked={imageCheckedStatus}
                onChange={(e) => {
                  setBlurCheckedStatus(false);
                  setImageCheckedStatus(e.target.checked);
                  setColorCheckedStatus(false);

                  let modalObj = brandingModalObj;
                  modalObj.brandingBackgroundBlurEnabled = false;
                  modalObj.brandingBackgroundColorEnabled = false;
                  modalObj.brandingBackgroundImageEnabled = e.target.checked;
                  setBrandingModalObj(modalObj);
                }}
              />
            </div>
          </CardContent>
        </Card>
        <AdPreviewBox
          previewTitle={"BACKGROUND PREVIEW"}
          previewOpen={showPreview}
          setPreviewOpen={(status) => {
            setShowPreview(status);
          }}
          adImageDataUri={imageDataUri}
          adMimeType={mimeType}
        />
      </div>
    </CustomModal>
  );
});
