import { DeviceUUID } from "device-uuid";
import { v4 as uuid } from "uuid";

export default (random = false) => {
  const platform = window.navigator.platform.toLowerCase();
  let os = "";
  if (platform.startsWith("ip")) {
    os = "ios";
  } else if (platform.startsWith("li")) {
    os = "android";
  } else if (platform.startsWith("ma")) {
    os = "mac";
  } else if (platform.startsWith("wi")) {
    os = "windows";
  } else {
    os = "other";
  }
  const deviceUUID = new DeviceUUID().get();
  if (random) return "guest_web_" + os + "_" + uuid();
  else return "guest_web_" + os + "_" + deviceUUID;
};
