import { Cancel, CheckCircle } from "@mui/icons-material";
import { Alert, Snackbar } from "@mui/material";
import { useState, createContext } from "react";
import { COLORS } from "../utils/colors";
export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const config = { type: "success", message: "" };
  const [toastConfig, setToastConfig] = useState(config);

  const triggerToast = (value, config) => {
    setToastConfig(config);
    setShowToast(value);
  };

  const resetToast = () => {
    setShowToast(false);
    setTimeout(() => {
      setToastConfig(config);
    }, 1000);
  };

  const renderSnackBar = () => (
    <Snackbar
      open={showToast}
      autoHideDuration={1000}
      onClose={resetToast}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        severity={toastConfig.type}
        variant="outlined"
        sx={{
          color: COLORS.textHeader,
          borderColor:
            toastConfig.type === "success" ? COLORS.success : COLORS.error,
        }}
        icon={
          toastConfig.type === "success" ? (
            <CheckCircle sx={{ color: COLORS.success }} />
          ) : (
            <Cancel sx={{ color: COLORS.error }} />
          )
        }
      >
        {toastConfig.message}
      </Alert>
    </Snackbar>
  );

  return (
    <AppContext.Provider
      value={{ showToast, toastConfig, triggerToast, resetToast }}
    >
      {children}
      {renderSnackBar()}
    </AppContext.Provider>
  );
};
