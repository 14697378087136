import { useState, forwardRef, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
const MediaCardScrolling = forwardRef(
  ({ data, onLoad, enableBlur = false, roundedCorners, orientation }, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    const [dynamicStyle, setDynamicStyle] = useState({
      borderRadius: roundedCorners ? "10px" : "0px",
    });

    useEffect(() => {
      setDynamicStyle({
        borderRadius: roundedCorners ? "8px" : "0px",
      });
    }, [roundedCorners]);

    const blurBg = {
      objectFit: "fill",
      filter: "blur(10px)",
      width: "100%",
      height: "100%",
      position: "absolute",
      border: "4px solid white",
      borderRadius: "10px",
      inset: 0,
      zIndex: -1,
      overflow: "hidden",
    };
    const mediaStyle = {
      zIndex: 0,
      position: "relative",
    };

    const recordPlayId = (id) => {
      if (window.playedIds) {
        window.playedIds.indexOf(id) === -1 && window.playedIds.push(id);
      } else {
        window["playedIds"] = [id];
      }
    };
    return (
      <div
        className={
          data.mimeType.includes("video")
            ? "video-media-card"
            : "img-media-card"
        }
        style={{ ...dynamicStyle, overflow: "hidden" }}
      >
        <video
          className="content-video uv-main-media-tag"
          style={{ ...mediaStyle, ...dynamicStyle }}
          autoPlay
          playsInline
          loop
          controls={false}
          muted
          disableRemotePlayback={true}
          src={data?.mediaFileUrl}
          onPlay={() => {
            setIsLoading(false);
            onLoad();
            recordPlayId(data._id);
          }}
          ref={ref}
        />

        <img
          ref={ref}
          src={data?.mediaFileUrl}
          alt="Social media"
          style={{ ...mediaStyle, ...dynamicStyle }}
          className="uv-main-media-tag"
          onLoad={() => {
            setIsLoading(false);
            onLoad();
            recordPlayId(data._id);
          }}
        />
      </div>
    );
  }
);
export default MediaCardScrolling;
