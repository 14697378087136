const SkeletonCard = () => {
  return (
    <div className="skeletonCard">
      <div className="mediaItemContainer">
        <div className="mediaItem skeleton"></div>
        <div className="textContent">
          <div className="skeleton"></div>
          <div className="skeleton" style={{ width: "26%" }}></div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="skeleton"></div>
            <div className="skeleton"></div>
          </div>
        </div>
      </div>
      <div className="mediaItemContainer">
        <div className="mediaItem skeleton"></div>
        <div className="textContent">
          <div className="skeleton"></div>
          <div className="skeleton" style={{ width: "26%" }}></div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="skeleton"></div>
            <div className="skeleton"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SkeletonCard;
