import React from "react";
import { CDN_URLS } from "../../constants";

// Component Definition
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <div className="spinner"></div> */}
      <img
        src={CDN_URLS.UVENU_LOADING}
        alt="Uvenu_Loading"
        style={{ width: "100px", height: "100px", objectFit: "contain" }}
      />
    </div>
  );
};

export default Loader;
