import { motion } from "framer-motion";
import { memo } from "react";
const TwoColorOpacity = memo(
  ({ color, color2, color1HoldTime, color2HoldTime, animationDuration }) => {
    return (
      <motion.div
        style={{
          backgroundColor: color,
          display: "flex",
          position: "absolute",
          inset: 0,
        }}
        animate={{ backgroundColor: [color, "rgba(0,0,0,0)", color2] }}
        transition={{
          duration: animationDuration,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      ></motion.div>
    );
  }
);
export default TwoColorOpacity;
