import MediaCardScrolling from "./MediaCardScrolling";

import { useState, useEffect, forwardRef } from "react";
import { InView } from "react-intersection-observer";

const ScrollingMasonryItem = forwardRef(
  (
    {
      data,
      requestNewItemCallBack,
      roundedCorners,
      isLastSlide = false,
      borderWidth,
      borderColor,
    },
    ref
  ) => {
    const [item, setItem] = useState(data);
    const recordPlayId = (id) => {
      if (window.playedIds1) {
        window.playedIds1.indexOf(id) === -1 && window.playedIds1.push(id);
      } else {
        window["playedIds1"] = [id];
      }
    };

    return (
      <InView
        as="div"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          zIndex: 0,
          overflow: "hidden",
          border: `${borderWidth}px solid ${borderColor}`,
          borderRadius: `${roundedCorners ? "10px" : "0px"}`,
          boxSizing: "border-box",
        }}
        threshold={0.15}
        className="scrolling-card"
        onChange={(inView, entry) => {
          const video = entry.target.querySelectorAll("video");
          const videoElements = Array.from(video);
          let playPromise = null;
          if (inView) {
            try {
              if (data.mimeType.includes("video")) {
                videoElements.forEach((videoElement) => {
                  if (videoElement.paused) {
                    videoElement.muted = true;
                    playPromise = videoElement.play();
                  }
                });
              }
            } catch (e) {
              console.log(e);
            }
          } else {
            try {
              if (data.mimeType.includes("video")) {
                videoElements.forEach((videoElement) => {
                  if (playPromise !== undefined && playPromise !== null) {
                    playPromise
                      .then((_) => {
                        videoElement.pause();
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                });
              }

              const img = entry.target.querySelectorAll("img");

              const imageElements = Array.from(img);

              if (entry && entry.target && entry.target.parentNode) {
                if (entry.boundingClientRect.top < 0) {
                  requestNewItemCallBack(
                    { imageElements, videoElements },
                    entry.target.firstChild
                  );
                } else if (isLastSlide) {
                  requestNewItemCallBack(
                    { imageElements, videoElements },
                    entry.target.firstChild
                  );
                }
              }
            } catch (e) {
              console.log(e);
            }
          }
        }}
      >
        <MediaCardScrolling
          data={item}
          onLoad={() => {
            recordPlayId(item._id);
          }}
          roundedCorners={roundedCorners}
          enableBlur={true}
          ref={ref}
        />
      </InView>
    );
  }
);
export default ScrollingMasonryItem;
