import { useContext, useEffect, useRef, useState } from "react";
import "../styles.css";
import { IconButton, Typography, Select, MenuItem } from "@mui/material";
import camera_switch from "../../../../src/assets/camera_switch.png";
import { insights } from "../../../ApplicationInsightsService";
import { selfRetryingPeerControllerFactory } from "./peer-controller-factory";
import { AD_TYPES } from "../../AdsSetupManager/functions";
import { AppContext } from "../../../contexts/App.Context";
import { COLORS } from "../../../utils/colors";

async function attachLocalStreamToVideoElement(localStream) {
  const video = document.getElementById("streamview");
  video.setAttribute("autoplay", "");
  video.setAttribute("muted", "");
  video.setAttribute("playsinline", "");
  video.srcObject = localStream;
}

let currentImageIndex = 0;
let currentTick = 0;
let latestAdverts = [];

export function LiveTab({
  pageParams,
  userName,
  eventData,
  userIdFromDB,
  showSignalling,
  isPortrait,
  restartSession,
  cameraNotLoaded,
  advertsData,
  stopCameraFeed,
}) {
  const { loadWithOptions } = pageParams;
  const appContext = useContext(AppContext);

  const [localStream, setLocalStream] = useState(null);
  const [previewStreamActive, setPreviewStreamActive] = useState(false);
  const [outputStreamActive, setOutputStreamActive] = useState(false);

  const [showAdImage, setShowAdImage] = useState(false);
  const [currentAdImageUri, setCurrentAdImageUri] = useState("");
  const [currentAdImageType, setCurrentAdImageType] = useState("");
  let adUpdateInterval = useRef();
  let imageSwitcherTimeout = useRef();

  let permissionsInterval = useRef(null);
  const [camerasList, setCamerasList] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const selectedCameraDeviceId = useRef(null);
  const selectedCameraMode = useRef("user");

  const releaseCallback = {
    release: () => {},
  };
  // capturing media stream
  useEffect(() => {
    loadCameraSetup();
    loadCamerasList();

    const handleOnline = () => {};
    const handleOffline = () => {
      setOutputStreamActive(false);
      setPreviewStreamActive(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      releaseCallback.release();
    };
  }, []);

  useEffect(() => {
    if (stopCameraFeed) {
      localStream.getTracks().forEach(function (track) {
        // console.log("stopping camera feed", track);
        track.stop();
      });
    }
  }, [stopCameraFeed, localStream]);

  const loadCameraSetup = () => {
    let videoConstraints = {};
    if (loadWithOptions === "true") {
      videoConstraints = {
        deviceId: selectedCameraDeviceId.current,
      };
    } else {
      videoConstraints = {
        facingMode: selectedCameraMode.current,
      };
    }

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: videoConstraints,
      })
      .then((localStream) => {
        cameraNotLoaded(false);

        setLocalStream(localStream);
        attachLocalStreamToVideoElement(localStream);

        releaseCallback.release = () => {
          localStream.getTracks().forEach(function (track) {
            track.stop();
          });
        };
      })
      .catch((error) => {
        cameraNotLoaded(true);
        alert("Camera is required to join, please restart the session.");
        insights.trackUvenuException(error, {
          status: "error",
          info: "JoinEventLive: exception while loading cameras due to permissions",
          error: error,
        });
      });
  };

  const loadCamerasList = () => {
    permissionsInterval.current = setInterval(() => {
      try {
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            const videoCameras = devices.filter(
              (d) => d.kind === "videoinput" && d.deviceId.length > 0
            );
            if (videoCameras.length > 0) {
              setCamerasList(videoCameras);
              setSelectedCamera(videoCameras[0].deviceId);
              selectedCameraDeviceId.current = videoCameras[0].deviceId;
              selectedCameraMode.current = "user";
              clearInterval(permissionsInterval.current);
            }
          })
          .catch((error) => {
            insights.trackUvenuException(error, {
              status: "error",
              info: "JoinEventLive: error while loading cameras due to permissions",
              error: error,
            });
          });
      } catch (error) {
        insights.trackUvenuException(error, {
          status: "error",
          info: "JoinEventLive: exception while loading cameras due to permissions",
          error: error,
        });
      }
    }, 1000);
  };

  const onCameraChange = async (event) => {
    if (outputStreamActive || previewStreamActive) {
      appContext.triggerToast(true, {
        type: "error",
        message: "Not allowed now",
      });
      return;
    }
    if (loadWithOptions === "true") {
      setSelectedCamera(event.target.value);
      selectedCameraDeviceId.current = event.target.value;
    } else {
      selectedCameraMode.current =
        selectedCameraMode.current === "environment" ? "user" : "environment";
    }

    await disconnectRooms();
    restartSession();
    loadCameraSetup();
  };

  // useEffect for webrtc connections

  useEffect(() => {
    const triggerConnect = async () => {
      if (!localStream || !userIdFromDB || eventData === null) return;
      connectRooms();
    };
    triggerConnect();
    return () => {
      disconnectRooms();
    };
  }, [localStream, userIdFromDB]);

  const connections = [];
  const connectRooms = () => {
    const roomsToJoin = [
      // only connect to the admin portal.
      `${eventData._id}_${userIdFromDB}_admin`,
    ];

    for (const room of roomsToJoin) {
      const stream = isAudioEnabled
        ? localStream
        : new MediaStream(localStream.getVideoTracks());
      connections.push(
        selfRetryingPeerControllerFactory({
          roomId: room,
          stream: stream,
          onConnecting: () => {
            setPreviewStreamActive(false);
            setOutputStreamActive(false);
          },
          onConnected: () => {
            setPreviewStreamActive(true);
            setOutputStreamActive(false);
          },
          onReconnecting: () => {
            setPreviewStreamActive(false);
            setOutputStreamActive(false);
          },
          onLiveStateChaned: (state) => {
            setPreviewStreamActive(true);
            setOutputStreamActive(state === "live");
          },
          onMessage: (data, pc) => {
            if (data && data.state) {
              switch (data.state) {
                case "audio":
                  handleAudioEnabled(pc);
                  break;
                case "no-audio":
                  handleAudioDisabled(pc);
                  break;
                default:
                  break;
              }
            }
          },
        })
      );
    }
  };

  const handleAudioEnabled = (reference) => {
    const pc = reference._peer.getRTCPeerConnection();
    const audioSender = pc
      .getSenders()
      .find((s) => s.track && s.track.kind === "audio");
    const silentSender = pc.getSenders().find((s) => !s.track);
    if (audioSender) {
      audioSender.replaceTrack(localStream.getAudioTracks()[0]);
    } else if (silentSender) {
      silentSender.replaceTrack(localStream.getAudioTracks()[0]);
    } else {
      localStream.getAudioTracks().forEach((track) => {
        reference._peer.addTrack(track, localStream);
      });
    }
    setIsAudioEnabled(true);
  };
  const handleAudioDisabled = (reference) => {
    const pc = reference._peer.getRTCPeerConnection();
    const sender = pc
      .getSenders()
      .find((s) => s.track && s.track.kind === "audio");
    if (sender) {
      sender.replaceTrack(null);
    }
    setIsAudioEnabled(false);
  };

  const disconnectRooms = async () => {
    for (const conn of connections) {
      await conn.pc.disconnect();
      conn.pc = null;
    }
  };

  useEffect(() => {
    if (eventData) setupAdviews();
  }, [eventData, advertsData]);

  const setupAdviews = () => {
    stopAds();
    // console.log("advertsData", advertsData);
    if (advertsData && advertsData.length > 0) {
      let newAdverts = advertsData.filter(
        (a) => a.adType === AD_TYPES.IN_EVENT
      );
      latestAdverts = newAdverts.map((ad) => ({
        uri: ad.adMediaUrl,
        timeout: ad.interval,
        mimeType: ad.mimeType,
      }));
      if (loadWithOptions !== "true") initAdSwitcher();
    }
  };

  const stopAds = () => {
    clearInterval(adUpdateInterval.current);
    clearTimeout(imageSwitcherTimeout.current);
  };

  const initAdSwitcher = () => {
    setShowAdImage(false);
    imageSwitcherTimeout.current = setTimeout(() => {
      changePicture();
    }, 3000);
  };

  function changePicture() {
    // On every odd iteration we are going to switch
    // to an advert when the advert loads its going to keep
    // itself visible for as long as the interval specifies, and then
    // its going to call changePicture again. On every even iteration we are
    // going to show the message and we will switch to a picture after 3 seconds.
    if (currentTick % 2 !== 0 && latestAdverts.length > 0) {
      const currentAdvert = latestAdverts[currentImageIndex];
      setCurrentAdImageUri(currentAdvert.uri);
      setCurrentAdImageType(currentAdvert.mimeType);
      setShowAdImage(true);
    } else {
      setCurrentAdImageUri("");
      setCurrentAdImageType("");
      setShowAdImage(false);
      imageSwitcherTimeout.current = setTimeout(() => {
        changePicture();
      }, 3000);
    }
    currentTick += 1;
  }

  return (
    <div className="jelMainContainer">
      <video
        id={"streamview"}
        className="jelStreamContainer"
        muted
        hidden={eventData === null}
        disableRemotePlayback={true}
      />
      {camerasList.length > 1 &&
        (loadWithOptions === "true" ? (
          <Select
            variant="standard"
            className="cameraButton"
            style={{ position: "absolute", backgroundColor: "white" }}
            sx={{ color: "#0D1051" }}
            value={selectedCamera}
            id="effect-select"
            onChange={onCameraChange}
          >
            {camerasList.map((camera) => (
              <MenuItem key={camera.deviceId} value={camera.deviceId}>
                {camera.label}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <IconButton
            className="cameraButton"
            value="camera_switch"
            onClick={() => onCameraChange()}
            size="large"
          >
            <img
              src={camera_switch}
              alt="camera_switch"
              style={{ width: 40, height: 40, objectFit: "contain" }}
            />
          </IconButton>
        ))}
      {!stopCameraFeed && showSignalling && (
        <div
          className={`signallingView ${
            isPortrait ? "signallingViewPortrait" : "signallingViewLandScape"
          }`}
          style={{
            backgroundColor: outputStreamActive
              ? "rgba(183, 13, 13, 0.6)"
              : "rgba(72, 81, 100, 0.7)",
          }}
        >
          <Typography
            className="statusTextStyle"
            style={{ color: COLORS.textHeader }}
          >
            {outputStreamActive
              ? "You're live!"
              : previewStreamActive
              ? "You're up next!"
              : showAdImage
              ? ""
              : "Please standby..."}
          </Typography>

          {!outputStreamActive &&
            !previewStreamActive &&
            latestAdverts.length > 0 && (
              <div className="adsContainer">
                {showAdImage &&
                  (currentAdImageType.includes("video") ? (
                    <video
                      style={{
                        height: "100px",
                        width: "250px",
                        alignSelf: "center",
                        borderRadius: 10,
                        objectFit: "contain",
                      }}
                      src={currentAdImageUri}
                      autoPlay
                      playsInline
                      muted
                      loop
                      disableRemotePlayback={true}
                      onLoadedData={() => {
                        imageSwitcherTimeout.current = setTimeout(() => {
                          changePicture();
                        }, latestAdverts[currentImageIndex].timeout * 1000);
                        currentImageIndex =
                          (currentImageIndex + 1) % latestAdverts.length;
                      }}
                    />
                  ) : (
                    <img
                      alt="_"
                      style={{
                        height: "100px",
                        width: "250px",
                        alignSelf: "center",
                        borderRadius: 10,
                        objectFit: "contain",
                      }}
                      onLoad={() => {
                        imageSwitcherTimeout.current = setTimeout(() => {
                          changePicture();
                        }, latestAdverts[currentImageIndex].timeout * 1000);
                        currentImageIndex =
                          (currentImageIndex + 1) % latestAdverts.length;
                      }}
                      src={currentAdImageUri}
                    />
                  ))}
              </div>
            )}
        </div>
      )}
    </div>
  );
}
