import React, { useEffect, useState } from "react";
import { CardActionArea, IconButton, Typography } from "@mui/material";
import {
  ENABLE_WEBRTC_DEBUG_LOGGING,
  ICE_SERVERS,
  SIGNALING_SERVER_URL,
} from "../../../constants";
import { connect } from "redux-zero/react";
import actions from "../../../store/actions";
import store from "../../../store";
import { getVideoAudioTracks } from "../../../utils/getVideoTrackFromPeerConnection";
import Loader from "../../Social/Loader_social";
import "../styles.css";
import { COLORS } from "../../../utils/colors";
import { VolumeOff, VolumeUp } from "@mui/icons-material";

function attachStreamToVideoElement(userId, stream) {
  const video = document.getElementById(userId);
  video.srcObject = stream;
}

function attachVideoTrackFromPCToVideoElement(currentlyStreaming) {
  const pc = currentlyStreaming.pc;
  const videoAudioTracks = getVideoAudioTracks(pc);
  attachStreamToVideoElement(
    currentlyStreaming.userId,
    new MediaStream(videoAudioTracks)
  );
}
function setupNewPeerConnectionForUser(
  eventId,
  userId,
  onLoading,
  setDisconnectedStatus,
  setUserDisconnected,
  user,
  updateStream,
  onComplete
) {
  const pc = new window.SimplePeerController({
    roomId: `${eventId}_${userId}_admin`,
    baseUrl: SIGNALING_SERVER_URL,
    EventSource: EventSource,
    iceServers: ICE_SERVERS,
    noConfirmation: true,
    enableDebugLogging: ENABLE_WEBRTC_DEBUG_LOGGING,
  });

  pc.on("connecting", () => {
    onLoading();
    console.log("connecting...");
  });

  pc.on("reconnecting", () => {
    setDisconnectedStatus(true);
    console.log("reconnecting...");
  });

  pc.on("connected", () => {
    setDisconnectedStatus(false);
    console.log("connected...");
  });

  pc.on("stream", (stream) => {
    const [videoTrack] = stream.getVideoTracks();
    const oldVideoTrack = user?.stream?.getVideoTracks();
    if (!videoTrack && oldVideoTrack.length) {
      stream.addTrack(oldVideoTrack[0]);
    }
    setUserDisconnected(false);
    console.log("received a stream...");
    attachStreamToVideoElement(userId, stream);
    user.stream = stream;
    if (user.isPreviewedUser) {
      user.isPreviewedUser = null;
      console.log("update stream");
      updateStream();
    }
    onComplete();
  });
  return pc;
}

function UserPreviewView({
  event,
  user,
  handleClicked,
  updateStream,
  addConnectionToActiveConnections,
  removeConnectionFromActiveConnections,
  currentlyStreamingUser,
}) {
  const [loading, setLoading] = useState(true);
  const [currentSpeed, setCurrentSpeed] = useState(0);
  const [userDisconnected, setUserDisconnected] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const isLiveUser =
    currentlyStreamingUser &&
    currentlyStreamingUser.userId === user.eventUserId;

  var bitrateSamples = {};

  const cleanupPeerControllerForUser = (user) => {
    removeConnectionFromActiveConnections(user.eventUserId);
    const currentState = store.getState();
    if (
      user.peerController &&
      ![null, user.eventUserId].includes(
        currentState.initialState.currentlyStreamingUser.userId
      )
    ) {
      user.peerController.disconnect();
      user.peerController = null;
    }
  };

  const initializePeerControllerForUser = (
    user,
    eventId,
    onLoading,
    onComplete
  ) => {
    const userId = user.eventUserId;

    try {
      const state = store.getState();
      const currentlyStreaming = state.initialState.currentlyStreamingUser;
      const pc = (() => {
        if (currentlyStreaming.userId === userId && currentlyStreaming.pc) {
          attachVideoTrackFromPCToVideoElement(currentlyStreaming);
          onComplete();
          return currentlyStreaming.pc;
        }
        return setupNewPeerConnectionForUser(
          eventId,
          userId,
          onLoading,
          setDisconnectedStatus,
          setUserDisconnected,
          user,
          updateStream,
          onComplete
        );
      })();

      user.peerController = pc;
      addConnectionToActiveConnections(user.eventUserId, pc);

      // Display statistics
      clearRefresh();
      bitrateSamples[userId] = {
        refreshIntervalId: setInterval(() => {
          //TODO: clearInterval after 30 seconds, keeping average bitrate
          try {
            if (user.peerController && user.peerController._peer) {
              user.peerController._peer.getStats(showRemoteStats);
            } else {
              // console.log("Not connected yet");
            }
          } catch (error) {
            // console.log("release timer");
            clearRefresh();
          }
        }, 1000),
        bytesPrev: null,
        timestampPrev: null,
        bitrate: null,
      };
    } catch (error) {
      console.log("Error setting up peer controller:", error);
      onComplete(); // Call onComplete to stop loading state even if there is an error
    }
  };

  const clearRefresh = () => {
    let refreshIntervalId =
      bitrateSamples[user.eventUserId] &&
      bitrateSamples[user.eventUserId]["refreshIntervalId"];
    if (refreshIntervalId) {
      clearInterval(refreshIntervalId);
      bitrateSamples[user.eventUserId] = {
        refreshIntervalId: null,
        bytesPrev: null,
        timestampPrev: null,
        bitrate: null,
      };
    }
  };

  function showRemoteStats(err, results) {
    if (err) {
      console.log(`ERROR: ${err}`);
      let refreshIntervalId =
        bitrateSamples[user.eventUserId]["refreshIntervalId"];
      if (refreshIntervalId) {
        clearInterval(refreshIntervalId);
        bitrateSamples[user.eventUserId]["refreshIntervalId"] = null;
      }
      return;
    }
    let timestampPrev = bitrateSamples[user.eventUserId]["timestampPrev"];
    let bytesPrev = bitrateSamples[user.eventUserId]["bytesPrev"];
    // calculate video bitrate
    results.forEach((report) => {
      if (report.type === "inbound-rtp" && report.mediaType === "video") {
        const now = report.timestamp;
        const bytes = report.bytesReceived;
        let bitrate;
        if (timestampPrev) {
          bitrate = (8 * (bytes - bytesPrev)) / (now - timestampPrev);
          bitrate = Math.floor(bitrate);
        }

        bitrateSamples[user.eventUserId]["bytesPrev"] = bytes;
        bitrateSamples[user.eventUserId]["timestampPrev"] = now;
        bitrateSamples[user.eventUserId]["bitrate"] = bitrate;
        if (bitrate) {
          setCurrentSpeed(bitrate);
        }
      }
    });
  }

  const setDisconnectedStatus = (disconnected) => {
    setLoading(!disconnected);
    setUserDisconnected(disconnected);
  };

  useEffect(() => {
    initializePeerControllerForUser(
      user,
      event._id,
      () => {
        setLoading(true);
      },
      () => {
        setLoading(false);
      }
    );

    return () => {
      cleanupPeerControllerForUser(user);
      if (bitrateSamples[user.id]) {
        clearRefresh();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, event]);

  useEffect(() => {
    if (user.alreadyPreviewed && user.alreadyPreviewed === true) {
      if (user.peerController) {
        // disconnectCurrentPeerController();
        // console.log("pc disconnected");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.alreadyPreviewed]);

  useEffect(() => {
    if (isAudioEnabled) {
      user?.peerController?._peer?.send({ state: "audio" });
    } else {
      user?.peerController?._peer?.send({ state: "no-audio" });
    }
  }, [isAudioEnabled, user.peerController]);

  const getConnectionSpeedStatusColor = () => {
    let color = "";
    if (currentSpeed > 1500) {
      color = "green";
    } else if (currentSpeed > 750) {
      color = "orange";
    } else if (currentSpeed > 0) {
      color = "red";
    } else {
      color = "transparent";
    }
    return color;
  };

  return (
    <div className="mainImgDiv" style={{ position: "relative" }}>
      {isAudioEnabled ? (
        <IconButton
          className="liveUserVolumeToggle"
          onClick={() => {
            setIsAudioEnabled((pre) => !pre);
          }}
        >
          <VolumeUp />
        </IconButton>
      ) : (
        <IconButton
          className="liveUserVolumeToggle"
          onClick={() => {
            setIsAudioEnabled((pre) => !pre);
          }}
        >
          <VolumeOff />
        </IconButton>
      )}

      <div
        onClick={() => {
          user.isPreviewedUser = user.eventUserId;
          handleClicked();
        }}
        className="userPreviewHolderDiv"
      >
        {loading ? (
          <div className="loaderStyle">
            <Loader />
          </div>
        ) : userDisconnected ? (
          <div className="disconnectionHolder">
            <Typography
              className="disconnectionStyle"
              style={{ color: COLORS.error }}
            >
              {/* {"User Disconnected"} */}
            </Typography>
          </div>
        ) : (
          <span />
        )}
        <video
          id={user.eventUserId}
          className="userPreviewVideo"
          autoPlay
          playsInline
          style={{ display: loading ? "none" : undefined }}
          disableRemotePlayback={true}
        />
        <div className={"infoActionsView"} style={{ height: "100%" }}>
          {isLiveUser ? (
            <Typography
              className={"liveUserText"}
              id={"preview_live_" + user.eventUserId}
              style={{
                backgroundColor: COLORS.textHeader,
                color: COLORS.error,
              }}
            >
              LIVE
            </Typography>
          ) : (
            <span />
          )}
          <div
            className={"nameHolder"}
            style={{ backgroundColor: COLORS.gray }}
          >
            <Typography
              className={"nameStyle"}
              style={{ color: COLORS.textHeader }}
            >
              {" "}
              {user.userName}
            </Typography>
            {user.alreadyPreviewed && user.alreadyPreviewed === true ? (
              <span />
            ) : (
              <div
                className={"speedStatus"}
                style={{ backgroundColor: getConnectionSpeedStatusColor() }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  ({ initialState: { currentlyStreamingUser } }) => ({
    currentlyStreamingUser,
  }),
  actions
)(UserPreviewView);
