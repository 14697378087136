import React, { useEffect, useState } from "react";
import "./styles.css";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";

import {
  Grid,
  Button,
  Tooltip,
  Select,
  MenuItem,
  Card,
  Typography,
} from "@mui/material";
import { Download, Refresh } from "@mui/icons-material";
import Loader from "../Social/Loader_social";
import { format } from "date-fns";
import { AD_TYPES } from "../AdsSetupManager/functions";

import * as XLSX from "xlsx";
import { uvenuFetcher } from "../../utils/uvenu-fetcher";
import { URLS } from "../../constants";
import { COLORS } from "../../utils/colors";

function UserEmailsDashboard(props) {
  const loggedInUserObj = props.initialState.loggedInUserObj;
  const [loading, setLoading] = useState(false);
  const [userEmailsData, setUserEmailsData] = useState([]);

  const options = [
    { value: 0, name: "All", type: "all" },
    { value: 1, name: "Post Event", type: AD_TYPES.POST_EVENT },
    { value: 2, name: "Post Game", type: AD_TYPES.POST_GAME },
  ];
  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = async (option = selectedOption) => {
    setLoading(true);
    try {
      const queryParams =
        option !== 0 ? "captureSource=" + options[option].type : "";
      const url = URLS.SUBMIT_USERS_EMAILS(props.event._id) + "?" + queryParams;
      const response = await uvenuFetcher({
        method: "GET",
        url,
        headers: {
          token: `${loggedInUserObj.token}`,
        },
      });
      if (response.statusCode === 200) {
        setLoading(false);
        setUserEmailsData(response.json.contacts);
      } else {
        setLoading(false);
        alert(response.json.message);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onOptionChange = (option) => {
    setUserEmailsData([]);
    setSelectedOption(option.target.value);
    refreshData(option.target.value);
  };

  const exportData = () => {
    let exportData = [];
    userEmailsData.map((item) => {
      const source = options.filter(
        (option) => option.type === item.captureSource
      )[0].name;
      const saveDate = format(
        new Date(item.saveDate * 1000),
        "dd MMM yyyy hh:mm a"
      );
      let user = {
        "Email Address": item.emailAddress,
        "Capture Source": source,
        "Submitted On": saveDate,
      };
      exportData.push(user);
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const filename = props.event.name.replace(" ", "") + "-user_emails.xlsx";
    XLSX.writeFile(workbook, filename);
  };

  return (
    <div className="userEmailsRoot">
      {loading && (
        <div className="userEmailsOverlay">
          <Loader />
        </div>
      )}
      <div className="userEmailsHeader">
        <Select
          className="userEmailsOptionsDropdown"
          style={{ position: "absolute" }}
          sx={{ color: "#0D1051" }}
          value={selectedOption}
          id="effect-select"
          onChange={onOptionChange}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Grid container className="userEmailsContainer" spacing={1}>
        {userEmailsData.map((item, index) => (
          <Grid item xs={4} key={index}>
            <Card className="userEmailsCard">
              <Typography className="userEmailsEmailAddress">
                Email: {item.emailAddress}
              </Typography>
              {selectedOption === 0 && (
                <Typography className="userEmailsCaptureSource">
                  Source: {item.captureSource}
                </Typography>
              )}
              <Typography className="userEmailsCaptureSource">
                Submitted on:{" "}
                {format(new Date(item.saveDate * 1000), "dd MMM yyyy hh:mm a")}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>

      <hr style={{ width: "100%" }} />
      <div className="userEmailsBottomButtons">
        <Tooltip title="Refresh data">
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              backgroundColor: COLORS.primary,
              borderColor: COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => refreshData()}
            startIcon={<Refresh />}
          >
            Refresh
          </Button>
        </Tooltip>
        <Tooltip title="Export data">
          <Button
            className="tabButton"
            sx={{
              color: COLORS.textHeader,
              backgroundColor: COLORS.primary,
              borderColor: COLORS.primary,
              "&:hover": {
                backgroundColor: COLORS.sideBarBackground,
              },
            }}
            onClick={() => exportData()}
            endIcon={<Download />}
          >
            Export
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({ initialState: state.initialState }),
  actions
)(UserEmailsDashboard);
