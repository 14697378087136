import { useLocation } from "react-router-dom";

/**
 *
 * @returns {{ eventId: string; page: string; loadWithOptions: string username: string }}
 */
export function useParamsFromUrlFragment() {
  const location = useLocation();
  const hashParams = new URLSearchParams(location.hash.substring(1));
  const eventId = hashParams.get("eventId");
  const page = hashParams.get("page");
  const loadWithOptions = hashParams.get("loadwithoptions");
  const username = hashParams.get("username");
  return { eventId, page, loadWithOptions, username };
}
