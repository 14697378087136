import { VARIANT_LOCALSTORAGE_KEYS } from "../../utils/defaultSettings";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { useEffect, useState } from "react";
import {
  getItemFromSessionStorage,
  saveItemToSessionStorage,
} from "../../utils/utility";
const ApplySettingComponent = ({
  defaultSelection,
  onChange,
  localStorageKey,
  resetWithChangeIn,
}) => {
  const applyToOptions = [
    {
      displayText: "Fixed Masonry",
      value: VARIANT_LOCALSTORAGE_KEYS.fixedMasonry,
    },
    // {
    //   displayText: "Scrolling Masonry",
    //   value: VARIANT_LOCALSTORAGE_KEYS.scrollingMasonry,
    // },
    {
      displayText: "Flipping Cards Grid",
      value: VARIANT_LOCALSTORAGE_KEYS.gridLayout,
    },
    // { displayText: "Slide Show", value: VARIANT_LOCALSTORAGE_KEYS.slideShow },
    // { displayText: "Carousel", value: VARIANT_LOCALSTORAGE_KEYS.carousel },
    {
      displayText: "Stack of Quote Cards",
      value: VARIANT_LOCALSTORAGE_KEYS.socialQuotes,
    },
    {
      displayText: "Grid of Quote Cards",
      value: VARIANT_LOCALSTORAGE_KEYS.quotesGridLayout,
    },
  ];
  const [selectedOptions, setSelectedOptions] = useState(
    getItemFromSessionStorage(localStorageKey) || [defaultSelection]
  );

  useEffect(() => {
    setSelectedOptions([defaultSelection]);
    saveItemToSessionStorage(localStorageKey, [defaultSelection]);
  }, [resetWithChangeIn]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const newValue = typeof value === "string" ? value.split(",") : value;
    setSelectedOptions(newValue);
    saveItemToSessionStorage(localStorageKey, newValue);
    onChange(newValue);
  };

  const getTitle = (selected) => {
    return selected
      .map((value) => {
        const obj = applyToOptions.find((op) => op.value === value);
        return obj?.displayText || "";
      })
      .join(", ");
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="uv-chip-label">Apply To</InputLabel>
      <Select
        input={<OutlinedInput id="select-multiple-chip" label="Apply To" />}
        labelId="uv-chip-label"
        id="uv-chip"
        title={getTitle(selectedOptions)}
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={getTitle}
      >
        {applyToOptions.map(({ displayText, value }) => (
          <MenuItem key={value} value={value}>
            <Checkbox
              checked={selectedOptions.indexOf(value) !== -1}
              disabled={value === defaultSelection}
            />
            <ListItemText primary={displayText} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default ApplySettingComponent;
