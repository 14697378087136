import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { URLS } from "../../../constants";
import { getOrientation } from "../fixedMasonry/CheckOrientation";

async function reviewMedia(
  loggedInUserObj,
  mediaId,
  review,
  username,
  description,
  showOnQuotesChecked = false,
  metadata = undefined,
  isStarred = false
) {
  try {
    if(metadata && metadata.width && metadata.height){
      metadata = {
        orientation: getOrientation(metadata.width, metadata.height).orientation,
        width: metadata.width,
        height: metadata.height
      }
    }
    const userId = username !== undefined && username !== null ? username : "";
    
    const response = await uvenuFetcher({
      method: "PATCH",
      url: URLS.REVIEW_MEDIA,
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      body: {
        sharedMediaId: mediaId,
        isApproved: review,
        userId,
        comment: description,
        showOnQuotesFeed: showOnQuotesChecked,
        metadata,
        isStarred
      },
    });
    if (response.statusCode === 204) {
      return response;
    } else {
      return "error: " + response.json.message;
    }
  } catch (e) {
    console.log(e);
  }
}

async function likeMedia(mediaId, userId) {
  // console.log("likeMedia called", mediaId, userId);
  try {
    const response = await uvenuFetcher({
      method: "POST",
      url: URLS.SOCIAL_MEDIA_LIKE,

      body: {
        sharedMediaId: mediaId,
        userId: userId,
      },
    });
    if (response.statusCode === 200 || response.statusCode === 201) {
      return response.json;
    } else {
      console.log(response.json.message);
      return "error: " + response.json.message;
    }
  } catch {}
}

export { reviewMedia, likeMedia };
