import { Autocomplete } from "@react-google-maps/api";
import React, { useState } from "react";

export default function ({ style, onPlacesChanged }) {
  const [autoComplete, setAutoComplete] = useState(null);
  return (
    <div style={style}>
      <Autocomplete
        onLoad={(autoComplete) => {
          setAutoComplete(autoComplete);
        }}
        onPlaceChanged={() => onPlacesChanged(autoComplete.getPlace())}
      >
        <input
          type="text"
          placeholder="Search places"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            height: `40px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </Autocomplete>
    </div>
  );
}
