import { useState, useEffect } from "react";
import UvDialog from "../../components/UI/UvDialog";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { createLightShow } from "../Games/networkCalls/networkCalls";
import { useLocation } from "react-router-dom";
import { targetFrequencies, effects } from "./Common";
const AddLightShow = ({ show, closeDialog }) => {
  const [open, setOpen] = useState(show);
  const [title, setTitle] = useState("");
  const [targetFrequency, setTargetFrequency] = useState(0);
  const [effect, setEffect] = useState(1);
  const [color, setColor] = useState("#ffffff");
  const [color2, setColor2] = useState("#000");
  const [color1HoldTime, setColor1HoldTime] = useState(1);
  const [color2HoldTime, setColor2HoldTime] = useState(1);
  const [animationDuration, setAnimationDuration] = useState(1);
  const location = useLocation();

  const reset = () => {
    setTitle("");
    setTargetFrequency(0);
    setEffect(1);
    setColor("#ffffff");
    setColor2("#000");
    setColor1HoldTime(1);
    setColor2HoldTime(1);
    setAnimationDuration(1);
  };
  useEffect(() => {
    reset();
    setOpen(show);
  }, [show]);

  const handleSubmit = async () => {
    await createLightShow({
      eventId: location.state.event.event._id,
      title,
      targetFrequency,
      color,
      color2,
      effect,
      color1HoldTime,
      color2HoldTime,
      animationDuration,
    });
    closeDialog();
  };
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  return (
    <UvDialog
      show={open}
      onClose={closeDialog}
      onSubmit={handleSubmit}
      title="Add Light Show"
      submitButtonLable="Add"
    >
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        value={title}
        onChange={onTitleChange}
        margin="normal"
      />
      <FormControl fullWidth sx={{ marginTop: "16px" }}>
        <InputLabel>Target Frequency</InputLabel>
        <Select
          variant="outlined"
          value={targetFrequency}
          onChange={(e) => {
            setTargetFrequency(e.target.value);
          }}
          label="Target Frequency"
        >
          {targetFrequencies.map(({ name, value }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: "16px" }}>
        <InputLabel>Select Effect</InputLabel>
        <Select
          variant="outlined"
          value={effect}
          onChange={(e) => {
            setEffect(e.target.value);
          }}
          label="Select Effect"
        >
          {effects.map(({ name, value }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid
        container
        columns={2}
        columnGap={1}
        justifyContent={"space-between"}
      >
        <Grid item fullWidth>
          <FormControl
            variant="standard"
            sx={{ width: "100%", marginTop: "16px" }}
          >
            <MuiColorInput
              format="hex"
              value={color}
              label="Select color 1"
              onChange={(newColor) => {
                setColor(newColor);
              }}
            />
          </FormControl>
        </Grid>
        {effect !== 5 && (
          <Grid item fullWidth>
            <FormControl sx={{ width: "100%", marginTop: "16px" }}>
              <InputLabel htmlFor="hold1">
                Set Hold Seconds for Color 1
              </InputLabel>
              <OutlinedInput
                id="hold1"
                type="number"
                inputProps={{ min: 1 }}
                value={color1HoldTime}
                label="Set Hold Seconds for Color 1"
                onChange={(e) => {
                  const newValue = e.target.value;
                  setColor1HoldTime(newValue);
                }}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
      {effect !== 5 && (
        <Grid
          container
          columns={2}
          columnGap={1}
          justifyContent={"space-between"}
        >
          <Grid item fullWidth>
            <FormControl
              variant="outlined"
              sx={{ width: "100%", marginTop: "16px" }}
            >
              <MuiColorInput
                format="hex"
                value={color2}
                label="Select color 2"
                onChange={(newColor) => {
                  setColor2(newColor);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item fullWidth>
            <FormControl
              variant="outlined"
              sx={{ width: "100%", marginTop: "16px" }}
            >
              <InputLabel htmlFor="hold2">
                Set Hold Seconds for Color 2
              </InputLabel>
              <OutlinedInput
                label="Set Hold Seconds for Color 2"
                id="hold2"
                type="number"
                inputProps={{ min: 1 }}
                value={color2HoldTime}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setColor2HoldTime(newValue);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {effect !== 5 && (
        <FormControl
          sx={{ width: "100%", marginTop: "16px" }}
          title="Higher the value slower the animation."
        >
          <InputLabel htmlFor="duration">
            Set Animation Duration (Seconds)
          </InputLabel>
          <OutlinedInput
            label="Set Animation Duration Seconds"
            id="duration"
            type="number"
            inputProps={{ min: 1 }}
            value={animationDuration}
            onChange={(e) => {
              const newValue = e.target.value;
              setAnimationDuration(newValue);
            }}
          />
        </FormControl>
      )}
    </UvDialog>
  );
};
export default AddLightShow;
