import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputLabel,
} from "@mui/material";
import { URLS } from "../../../src/constants";
import { uvenuFetcher } from "../../utils/uvenu-fetcher";
import { useLocation } from "react-router-dom";
import {
  getGameStats,
  getGameResultsScreenSettings,
  updateGame,
} from "./networkCalls/networkCalls";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";
import "./GameScreen.css";
import Loader from "../../components/Loader/Loader";
import UvGrid from "../../components/CustomLayout/UvGrid";
import { Lock, LockOpen, Settings } from "@mui/icons-material";
import SocialOutputSettings from "../Social/Settings";
import {
  DEFAULT_SETTINGS_GAMES_OUTPUT,
  VARIANT_LOCALSTORAGE_KEYS,
} from "../../utils/defaultSettings";
import PercentageBar from "./PercentageBar";

const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.gamesOutput;

function GameScreen(props) {
  const [settings, setSettings] = useState(DEFAULT_SETTINGS_GAMES_OUTPUT);
  const [backgroundImageEnabled, setBackgroundImageEnabled] = useState(false);
  const [backgroundImageUri, setBackgroundImageUri] = useState(null);
  const [useGameBackground, setUseGameBackground] = useState(true);
  const [gameStats, setGameStats] = useState(null);
  const [savedLayouts, setSavedLayouts] = useState(null);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rulerGapSize, setRulerGapSize] = useState(20);
  const location = useLocation();

  const hashParams = new URLSearchParams(location.hash.substring(1));

  // Access individual query parameters
  const game = hashParams.get("game");
  const event = hashParams.get("event");

  useEffect(() => {
    // Remove scroll from body
    document.body.style.overflow = "hidden";

    return () => {
      // Restore scroll to body when the component is unmounted
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (
      dialogOpen === false &&
      JSON.stringify(settings) !== JSON.stringify(DEFAULT_SETTINGS_GAMES_OUTPUT)
    ) {
      saveLayoutToDB();
    }
  }, [dialogOpen]);
  useEffect(() => {
    if (savedLayouts) {
      saveLayoutToDB();
    }
  }, [savedLayouts]);

  const fetchSavedLayouts = async (gameId) => {
    setIsLoading(true);
    const response = await getGameResultsScreenSettings(gameId);
    if (response?.layouts) {
      setSavedLayouts((previousValue) => {
        if (
          JSON.stringify(previousValue) !== JSON.stringify(response.layouts)
        ) {
          return response.layouts;
        } else {
          return previousValue;
        }
      });
    }
    if (response?.settings) {
      setSettings(response.settings);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    async function triggerFetch() {
      await fetchSavedLayouts(game);
    }
    triggerFetch();
  }, [event]);

  useEffect(() => {
    let intervalId;
    async function getEventInfo() {
      try {
        const response = await uvenuFetcher({
          method: "GET",
          url: URLS.EVENT_INFO(event),
        });
        if (response.statusCode === 200) {
          const event = response.json.data;
          setBackgroundImageEnabled(event.brandingBackgroundImageEnabled);
          setBackgroundImageUri(event.brandingBackgroundImage);
        } else {
          console.log(response.json.message);
        }
        setIsLoading(false);
      } catch (error) {
        console.log("Error fetching event info:", error);
      }
    }

    async function fetchGameStats() {
      const response = await getGameStats(game);
      setGameStats(response);
      setIsLoading(false);
    }

    fetchGameStats();
    intervalId = setInterval(() => {
      fetchGameStats();
    }, 2500);

    getEventInfo();

    return () => {
      clearInterval(intervalId);
    };
  }, [event]);

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };
  const renderSettings = () => {
    return (
      <>
        <IconButton
          className="settings-icon"
          onClick={() => setDialogOpen(true)}
          size="large"
        >
          <Settings />
        </IconButton>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Settings</DialogTitle>
          <DialogContent style={{ minWidth: 400 }}>
            <SocialOutputSettings
              onChange={handleSettingsChange}
              localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
              defaultSettings={settings || DEFAULT_SETTINGS_GAMES_OUTPUT}
              useLocalStorage={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const renderOptions = (_settings) => {
    if (!gameStats) return null;

    const {
      correctAnswerText,
      highlightBarColor,
      optionTextColor,
      questionTextColor,
      gameType,
      isActive,
      questionText,
      isShowTotalVotes,
    } = gameStats.game;
    return isShowTotalVotes && gameStats
      ? [
          <div
            key="questionText"
            className="gameScreenQuestionText"
            style={{
              color: questionTextColor,
              fontSize: `${_settings.headingFontSize || 24}px`,
            }}
          >
            {questionText}
          </div>,
          <h2
            key="votes"
            className="gameScreenQuestionText"
            style={{
              color: optionTextColor,
              background: "rgba(0,0,0,0.6)",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            {gameStats.totalRespondents} Votes
          </h2>,
          ...gameStats.answerStats.map((option, index) => {
            return (
              <div
                className="gameScreenOptionContainer"
                key={option.answer._id}
                style={{ fontSize: `${_settings.optionFontSize || 18}px` }}
              >
                <PercentageBar
                  percentage={option.percentage}
                  correctAnswer={option.answer.optionText === correctAnswerText}
                  correctAnswerValue={correctAnswerText}
                  option={option.answer}
                  optionTextColor={optionTextColor}
                  highlightBarColor={highlightBarColor}
                  gameType={gameType}
                  isActive={isActive}
                  index={index}
                  settings={settings}
                />
              </div>
            );
          }),
        ]
      : [
          <div
            className="gameScreenQuestionText"
            key="question"
            style={{
              fontSize: `${_settings.headingFontSize || 24}px`,
              color: questionTextColor,
            }}
          >
            {questionText}
          </div>,
          ...gameStats.answerStats.map((option, index) => {
            return (
              <div
                className="gameScreenOptionContainer"
                key={option.answer._id}
                style={{ fontSize: `${_settings.optionFontSize || 18}px` }}
              >
                <PercentageBar
                  percentage={option.percentage}
                  correctAnswer={option.answer.optionText === correctAnswerText}
                  correctAnswerValue={correctAnswerText}
                  option={option.answer}
                  optionTextColor={optionTextColor}
                  highlightBarColor={highlightBarColor}
                  gameType={gameType}
                  isActive={isActive}
                  index={index}
                  settings={settings}
                />
              </div>
            );
          }),
        ];
  };

  const saveLayoutToDB = async () => {
    const loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"));
    const payload = {
      gameId: game,
      layouts: savedLayouts,
      settings,
    };
    await updateGame(payload, loggedInUserObj);
  };
  return (
    <div
      id={`gameInfoView`}
      className="gameScreenVideoViewStyle"
      style={{
        fontFamily:
          settings.font && settings.font.toLowerCase() === "default"
            ? "inherit"
            : settings.font,
      }}
    >
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {useGameBackground ? (
              <img
                src={gameStats && gameStats.game.gameResultsBackgroundImageUrl}
                alt="game background"
                className="gameScreenBackgroundImage"
              />
            ) : (
              backgroundImageEnabled && (
                <img
                  src={backgroundImageUri}
                  alt="event background"
                  className="gameScreenBackgroundImage"
                />
              )
            )}
            {renderSettings()}
            {/* Lock Icon */}
            {isEditEnabled ? (
              <div>
                <FormControl
                  variant="standard"
                  sx={{
                    position: "fixed",
                    top: "8px",
                    right: "112px",
                    zIndex: 100,
                    color: "white",
                  }}
                >
                  <InputLabel htmlFor="ruler-size" sx={{ color: "white" }}>
                    Ruler Gap Size
                  </InputLabel>
                  <Input
                    id="ruler-size"
                    type="number"
                    inputProps={{ min: 10, style: { borderColor: "white" } }}
                    value={rulerGapSize}
                    sx={{ color: "white" }}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRulerGapSize(newValue);
                    }}
                    disableUnderline
                  />
                </FormControl>
                <IconButton
                  className="lock-icon"
                  onClick={() => setIsEditEnabled((pre) => !pre)}
                  size="large"
                  title="Disable Drag and Resize"
                >
                  <Lock />
                </IconButton>
              </div>
            ) : (
              <IconButton
                className="lock-icon"
                onClick={() => setIsEditEnabled((pre) => !pre)}
                size="large"
                title="Enable Drag and Resize"
              >
                <LockOpen />
              </IconButton>
            )}

            {/* End: Lock icon */}
            <div
              style={{ height: "auto", position: "relative" }}
              key="uv-grid-container"
            >
              <div
                style={{
                  position: "relative",
                  width: "100vw",
                  height: "100vh",
                  overflow: "auto",
                  backgroundSize: `${rulerGapSize}px ${rulerGapSize}px`,
                }}
                className={isEditEnabled ? "ruler" : ""}
              >
                {renderOptions(settings) && (
                  <UvGrid
                    gameId={game}
                    savedLayouts={savedLayouts || null}
                    isEditEnabled={isEditEnabled}
                    onLayoutChange={(layouts) => {
                      if (layouts) {
                        setSavedLayouts(layouts);
                      }
                    }}
                  >
                    {renderOptions(settings)}
                  </UvGrid>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default connect(
  (state) => ({ initialState: state.initialState }),
  actions
)(GameScreen);
