import React, { useEffect, useContext, useRef, useState } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  Checkbox,
  IconButton,
  TextField,
} from "@mui/material";

import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { connect } from "redux-zero/react";
import actions from "../../../store/actions";
import { reviewMedia } from "./networkCallsSocial";
import Loader from "../Loader_social";
import BulkUploadModal from "./BulkMediaUploadModal";
import LaunchIcon from "@mui/icons-material/Launch";
import InfiniteLoaderGrid from "./InfininteLoaderGrid/InfiniteLoaderGrid";
import {
  ArrowDownward,
  ArrowUpward,
  CheckCircle,
  Delete,
  Refresh,
  Search,
  Upload,
} from "@mui/icons-material";
import { COLORS } from "../../../utils/colors";
import "./SocialOperator.css";
import ConfirmationDialog from "../../../components/UI/ConfirmationDialog";
import { AppContext } from "../../../contexts/App.Context";
import { reviewQuote } from "../../Games/networkCalls/networkCalls";

const perPage = 9; // Set this to your desired number of items per page
function SocialOperator(props) {
  const loggedInUserObj = props.initialState.loggedInUserObj;
  const [mediaFilesUnReviewed, setMediaFilesUnReviewed] = useState([]); //
  const [mediaFilesReviewed, setMediaFilesReviewed] = useState([]);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [pageNo, setPageNo] = useState(0);
  const [hasNoMore, setHasNoMore] = useState(false);
  const [samePageTrigger, setSamePageTrigger] = useState(false);

  const reviewedItem = useRef(""); // to store last reviewd item
  useEffect(() => {
    setLoading(true);
    refreshData();
  }, []);

  const [newestFirst, setNewestFirst] = useState(true);
  const [onlyImages, setOnlyImages] = useState(false);
  const [onlyVideos, setOnlyVideos] = useState(false);
  const [onlyApprovedQuotes, setOnlyApprovedQuotes] = useState(false);
  const [hasOnlyQuote, setHasOnlyQuote] = useState(false);
  const [purgeConfirmation, setPurgeConfirmation] = useState(false);
  const [searchText, setSearchText] = useState("");

  const appContext = useContext(AppContext);

  useEffect(() => {
    setMediaFilesReviewed([]);
    setMediaFilesUnReviewed([]);
  }, [
    activeTab,
    newestFirst,
    onlyImages,
    onlyVideos,
    onlyApprovedQuotes,
    hasOnlyQuote,
  ]);

  useEffect(() => {
    if (pageNo !== 0) {
      refreshData();
    }
    if (samePageTrigger) {
      refreshData();
    }
  }, [pageNo, samePageTrigger]);

  const [displayedMediaFiles, setDisplayedMediaFiles] = useState([]);

  useEffect(() => {
    setDisplayedMediaFiles(
      activeTab === 0 ? mediaFilesUnReviewed : mediaFilesReviewed
    );
    if (mediaFilesReviewed.length === 0 && mediaFilesUnReviewed.length === 0) {
      refreshData();
    }
  }, [mediaFilesUnReviewed, mediaFilesReviewed, activeTab]);

  const refreshData = () => {
    if (activeTab === 1) {
      loadReviewedMediaData();
    } else if (activeTab === 0) {
      loadUnreviewedMediaData();
    }
  };

  async function loadReviewedMediaData() {
    setLoading(true);
    let mimeType = null;
    if (onlyImages) mimeType = "image";
    if (onlyVideos) mimeType = "video";
    const reviewedResponse = await uvenuFetcher({
      url: URLS.GET_SOCIAL_SCROLL(
        props.initialState.event_id,
        pageNo,
        perPage,
        mimeType,
        onlyApprovedQuotes ? true : null,
        newestFirst ? "desc" : "asc",
        searchText.length > 0 ? searchText : null
      ),
      method: "GET",
    });
    if (
      reviewedResponse.json &&
      reviewedResponse.json.mediaFiles.length === 0 &&
      pageNo === 0
    ) {
      setHasNoMore(true);
      setLoading(false);
      return;
    }
    if (
      reviewedResponse.json &&
      reviewedResponse.json.mediaFiles.length < perPage
    ) {
      setHasNoMore(true);
    } else {
      setHasNoMore(false);
    }
    setMediaFilesReviewed(
      mergeUnique(mediaFilesReviewed, reviewedResponse.json.mediaFiles)
    );
    setLoading(false);
  }

  async function loadUnreviewedMediaData() {
    setLoading(true);
    let mimeType = null;
    if (onlyImages) mimeType = "image";
    if (onlyVideos) mimeType = "video";
    const unreviewedResponse = await uvenuFetcher({
      url: URLS.GET_UNREVIEWED_MEDIA(
        props.initialState.event_id,
        pageNo,
        perPage,
        mimeType,
        newestFirst ? "desc" : "asc",
        searchText.length > 0 ? searchText : null,
        undefined,
        hasOnlyQuote
      ),
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      method: "GET",
    });

    if (
      unreviewedResponse.json &&
      unreviewedResponse.json.mediaFiles.length === 0 &&
      pageNo === 0
    ) {
      setHasNoMore(true);
      setLoading(false);
      return;
    }
    const uniqueFiles = mergeUnique(
      mediaFilesUnReviewed,
      unreviewedResponse.json.mediaFiles
    );
    if (
      unreviewedResponse.json &&
      unreviewedResponse.json.mediaFiles.length < perPage
    ) {
      setHasNoMore(true);
    } else {
      setHasNoMore(false);
    }
    setMediaFilesUnReviewed(uniqueFiles);
    setLoading(false);
  }

  const review_Media = async (
    loggedInUserObj,
    mediaId,
    review,
    username,
    description,
    showOnQuotesChecked,
    metadata,
    isStarred
  ) => {
    reviewedItem.current = { mediaId, isStarred: isStarred };
    try {
      await reviewMedia(
        loggedInUserObj,
        mediaId,
        review,
        username,
        description,
        showOnQuotesChecked,
        review && metadata?.width && metadata?.height ? metadata : undefined,
        isStarred ?? false
      );
      if (pageNo < 0) setPageNo(0);
      refreshData();
    } catch (e) {
      console.log(e);
    }
  };
  const handleReiewQuote = async (media) => {
    reviewedItem.current = { mediaId: media._id, isStarred: media.isStarred };
    await reviewQuote(media, loggedInUserObj);
    if (pageNo < 0) setPageNo(0);
    refreshData();
  };

  function mergeUnique(oldMediaFiles, newMediaFiles) {
    const uniqueNewMediaFiles = newMediaFiles.filter(
      (newMedia) =>
        !oldMediaFiles.some((oldMedia) => oldMedia._id === newMedia._id)
    );

    let finalArray = [...oldMediaFiles, ...uniqueNewMediaFiles];
    if (
      reviewedItem?.current?.isStarred === undefined ||
      reviewedItem?.current?.isStarred === null ||
      reviewedItem?.current?.isStarred === false
    ) {
      finalArray = finalArray.filter((media) => {
        return media._id !== reviewedItem?.current.mediaId;
      });
      reviewedItem.current = "";
    } else {
      finalArray = finalArray.map((media) => {
        if (media._id === reviewedItem?.current.mediaId) {
          media.isStarred = reviewedItem?.current.isStarred;
        }
        return media;
      });
    }

    finalArray = finalArray.sort(function (a, b) {
      if (newestFirst) return new Date(b.createdAt) - new Date(a.createdAt);
      else return new Date(a.createdAt) - new Date(b.createdAt);
    });

    return finalArray;
  }

  const loadMoreItems = (startIndex, stopIndex) => {
    if (hasNoMore) {
      setSamePageTrigger(true);
      return;
    }
    let newPageNo = Math.floor(startIndex / perPage);
    setPageNo(newPageNo);
  };

  const handleTabChange = (newValue) => {
    setPageNo(0);
    setActiveTab(newValue);
  };

  const searchClicked = () => {
    setPageNo(0);
    setMediaFilesReviewed([]);
    setMediaFilesUnReviewed([]);
  };

  const purgeAllMediaFiles = async () => {
    const response = await uvenuFetcher({
      method: "PATCH",
      url: URLS.PURGE_ALL_MEDIA,
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      body: {
        eventId: props?.initialState?.event_id,
      },
    });
    setPurgeConfirmation(false);
    if (response.statusCode === 204) {
      handleTabChange(!activeTab);
      appContext.triggerToast(true, {
        type: "success",
        message: "All media purged successfully.",
      });
    } else {
      console.log(response);
    }
  };

  return (
    <div className="soRoot">
      <BulkUploadModal
        initialState={props.initialState}
        open={openUploadModal}
        onClose={() => {
          setOpenUploadModal(false);
        }}
        uploadAfterMath={() => {
          if (newestFirst === true) {
            props.forceRerender();
          } else {
            if (pageNo < 0) setPageNo(0);
            refreshData();
          }
        }}
      />
      <div className="soHeaderSocial">
        <Button
          className="tabButton"
          sx={{
            color: COLORS.textHeader,
            borderColor: COLORS.primary,
            backgroundColor:
              activeTab === 0 ? COLORS.sideBarBackground : COLORS.primary,
            "&:hover": {
              backgroundColor: COLORS.sideBarBackground,
            },
          }}
          onClick={() => {
            handleTabChange(0);
          }}
          variant="contained"
          startIcon={activeTab === 0 ? <CheckCircle /> : null}
        >
          Unapproved
        </Button>
        <Button
          className="tabButton"
          sx={{
            color: COLORS.textHeader,
            borderColor: COLORS.primary,
            backgroundColor:
              activeTab === 1 ? COLORS.sideBarBackground : COLORS.primary,
            "&:hover": {
              backgroundColor: COLORS.sideBarBackground,
            },
          }}
          onClick={() => {
            handleTabChange(1);
          }}
          variant="contained"
          startIcon={activeTab === 1 ? <CheckCircle /> : null}
        >
          Approved
        </Button>
        <IconButton
          style={{ color: "white" }}
          onClick={() => {
            if (newestFirst === true) {
              props.forceRerender();
            } else {
              setPageNo(0);
              refreshData();
            }
          }}
        >
          <Refresh />
        </IconButton>

        <Button
          className="tabButton"
          sx={{
            color: COLORS.textHeader,
            borderColor: COLORS.primary,
            backgroundColor: COLORS.primary,
            "&:hover": {
              backgroundColor: COLORS.sideBarBackground,
            },
          }}
          onClick={() => {
            setPurgeConfirmation(true);
          }}
          startIcon={<Delete />}
        >
          Purge All Media
        </Button>
        <Button
          className="tabButton"
          sx={{
            color: COLORS.textHeader,
            borderColor: COLORS.primary,
            backgroundColor: COLORS.primary,
            "&:hover": {
              backgroundColor: COLORS.sideBarBackground,
            },
          }}
          onClick={() => setOpenUploadModal(true)}
          startIcon={<Upload />}
        >
          Upload Bulk
        </Button>
      </div>
      {loading && (
        <div className="soOverlay">
          <Loader />
        </div>
      )}
      <Grid container className="soContainer" spacing={1}>
        <InfiniteLoaderGrid
          loadMoreItems={loadMoreItems}
          items={displayedMediaFiles}
          hasNextPage={!hasNoMore}
          activeTab={activeTab}
          handleReviewMedia={review_Media}
          handleReiewQuote={handleReiewQuote}
          pageSize={perPage}
          approved={activeTab === 1}
          columnCount={5}
        />
      </Grid>

      <div className="soBottomButtons" style={{ color: COLORS.textHeader }}>
        <Button
          className="tabButton"
          sx={{
            color: COLORS.textHeader,
            borderColor: COLORS.primary,
            backgroundColor: COLORS.primary,
            "&:hover": {
              backgroundColor: COLORS.sideBarBackground,
            },
          }}
          onClick={() => {
            setPageNo(0);
            setNewestFirst(!newestFirst);
          }}
        >
          {newestFirst ? <ArrowUpward /> : <ArrowDownward />}
          {newestFirst ? "Newest First" : "Oldest First"}
        </Button>
        <div
          className="soCheckboxContainer"
          style={{
            backgroundColor: "transparent",
            border: "1px solid white",
            borderRadius: 5,
            alignSelf: "center",
            margin: "8px",
            maxHeight: "40px",
          }}
        >
          <TextField
            className="soSearchTextField"
            variant="filled"
            placeholder="search..."
            hiddenLabel
            InputProps={{
              style: { color: "white" },
            }}
            fullWidth
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <IconButton style={{ color: "white" }} onClick={searchClicked}>
            <Search />
          </IconButton>
        </div>
        <div
          className="soCheckboxContainer"
          onClick={() => {
            setPageNo(0);
            setOnlyVideos(false);
            setOnlyImages(!onlyImages);
            setHasOnlyQuote(false);
          }}
        >
          <Typography>Images</Typography>
          <Checkbox
            checked={onlyImages}
            sx={{
              color: "white",
              "&.Mui-checked": {
                color: "white",
              },
            }}
          />
        </div>
        <div
          className="soCheckboxContainer"
          onClick={() => {
            setPageNo(0);
            setOnlyImages(false);
            setHasOnlyQuote(false);
            setOnlyVideos(!onlyVideos);
          }}
        >
          <Typography>Videos</Typography>
          <Checkbox
            checked={onlyVideos}
            sx={{
              color: "white",
              "&.Mui-checked": {
                color: "white",
              },
            }}
          />
        </div>
        {activeTab === 1 ? (
          <div
            className="soCheckboxContainer"
            onClick={() => {
              setPageNo(0);
              setOnlyApprovedQuotes(!onlyApprovedQuotes);
            }}
          >
            <Typography>Approved Quotes</Typography>
            <Checkbox
              checked={onlyApprovedQuotes}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
              }}
            />
          </div>
        ) : (
          <div
            className="soCheckboxContainer"
            onClick={() => {
              setPageNo(0);
              setHasOnlyQuote((pre) => !pre);
              setOnlyVideos(false);
              setOnlyImages(false);
            }}
          >
            <Typography>Only Quote</Typography>
            <Checkbox
              checked={hasOnlyQuote}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
              }}
            />
          </div>
        )}
      </div>
      <ConfirmationDialog
        title="Purge Media Confirmation"
        confirmButtonText="Yes"
        message={`All unstarred media will be purged. Are you sure you want to continue?`}
        show={purgeConfirmation}
        onConfirm={async () => {
          await purgeAllMediaFiles();
        }}
        onClose={() => {
          setPurgeConfirmation(false);
        }}
      />
    </div>
  );
}

export default connect(
  (state) => ({ initialState: state.initialState }),
  actions
)(SocialOperator);
