import { useEffect, useRef, useState } from "react";
import UvCardsParallax from "./UvCardsParallax";
import { useParams } from "react-router-dom";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { URLS } from "../../../constants";
import "./SocialQuotes.css";
import { SocialQuotesContextProvider } from "../../../contexts/SocialQuotes.Context";
import { Settings } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SocialOutputSettings from "../Settings";
import {
  checkMediaOrientation,
  getOrientation,
} from "../fixedMasonry/CheckOrientation";
import {
  VARIANT_LOCALSTORAGE_KEYS,
  DEFAULT_SETTINGS_SOCIAL_QUOTES,
  QUOTE_CARDS,
  getDefaultQuoteCardData,
} from "../../../utils/defaultSettings";
import {
  getItemFromSessionStorage,
  mergeAndDeduplicate,
} from "../../../utils/utility";
import Loader from "../../../components/Loader/Loader";
const SETTINGS_LOCALSTORAGE_KEY = VARIANT_LOCALSTORAGE_KEYS.socialQuotes;

const SocialQuotes = (props) => {
  const getInitialSettings = () => {
    if (props.savedSettings) {
      return props.savedSettings;
    } else {
      return getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        ? getItemFromSessionStorage(SETTINGS_LOCALSTORAGE_KEY)
        : DEFAULT_SETTINGS_SOCIAL_QUOTES;
    }
  };
  const [settings, setSettings] = useState(getInitialSettings);

  const [dialogOpen, setDialogOpen] = useState(null);
  const { eventId } = useParams();
  const [items, setItems] = useState([]);
  const [nextItems, setNextItems] = useState([]);
  const [fetchNextItems, setFetchNextItems] = useState(false);
  const pageNumber = useRef(0);
  const pageSize = QUOTE_CARDS.length;
  const totalPages = useRef(1);
  const [doRequestNew, setDoRequestNew] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const showQuoteOnlyLastValue = useRef(settings?.showQuoteOnly);
  useEffect(() => {
    if (settings.showQuoteOnly !== showQuoteOnlyLastValue.current) {
      props.reRender();
    }
  }, [settings.showQuoteOnly]);

  const checkImageOrientationAsync = (imageUrl, mimeType, sharedMediaId) => {
    return new Promise((resolve, reject) => {
      checkMediaOrientation(
        imageUrl,
        mimeType,
        (error, result, errorEvent) => {
          if (error) {
            console.error(error, errorEvent);
            reject(new Error(error));
          } else {
            resolve(result);
          }
        },
        sharedMediaId
      );
    });
  };

  const fetchContent = async () => {
    if (!items.length) {
      setLoading(true);
    }
    const rawResponse = await uvenuFetcher({
      url: URLS.GET_SOCIAL_SCROLL(
        eventId,
        pageNumber.current,
        pageSize,
        undefined,
        true,
        "desc",
        undefined,
        settings?.showQuoteOnly ? true : undefined
      ),
      method: "GET",
    });

    const data = await rawResponse.json;
    setLoading(false);
    for (const item of data.mediaFiles) {
      if (item?.isQuoteOnly) {
        continue;
      }
      try {
        if (item.metadata && item.metadata.width && item.metadata.height) {
          if (!item.orientation) {
            item.metadata["orientation"] = getOrientation(
              item.metadata.width,
              item.metadata.height
            ).orientation;
          }
        } else {
          const result = await checkImageOrientationAsync(
            item.mediaFileUrl,
            item.mimeType,
            item._id
          );
          item.metadata = result;
        }

        const { orientation, width, height } = item.metadata;
        item.orientation = orientation;
        item.width = width;
        item.height = height;
      } catch (error) {
        console.error("Error determining image orientation:", error.message);
      }
    }
    return data;
  };

  const getCardSettings = (index) => {
    const { setAlternateCard, quoteCards, useCommonCardStyle } = settings;
    if (setAlternateCard) {
      return index % 2 === 0 ? quoteCards[0] : quoteCards[1];
    } else if (useCommonCardStyle) {
      return quoteCards[0];
    } else {
      return quoteCards[index];
    }
  };

  const getInitialDataAndSetItems = () => {
    fetchContent().then(({ mediaFiles, totalCount }) => {
      totalPages.current = Math.ceil(totalCount / pageSize);
      if (!totalCount) {
        mediaFiles = Array.from({ length: pageSize }, () =>
          getDefaultQuoteCardData()
        );
      } else if (totalCount < pageSize) {
        const dummyItems = Array.from({ length: pageSize - totalCount }, () =>
          getDefaultQuoteCardData()
        );
        mediaFiles = [...dummyItems, ...mediaFiles];
      }
      if (mediaFiles.length) {
        for (let i = 0; i < mediaFiles.length; i++) {
          mediaFiles[i]["cardSettings"] = getCardSettings(i);
        }
      }
      setItems(mediaFiles);
    });
  };

  useEffect(() => {
    getInitialDataAndSetItems();
  }, []);
  useEffect(() => {
    if (props.savedSettings) {
      setSettings(props.savedSettings);
    }
  }, [props.savedSettings]);
  useEffect(() => {
    setItems((pre) => {
      if (pre?.length) {
        const itemsCopy = [...pre];
        for (let i = 0; i < itemsCopy.length; i++) {
          itemsCopy[i]["cardSettings"] = getCardSettings(i);
        }
        return itemsCopy;
      } else {
        return pre;
      }
    });
  }, [
    settings.setAlternateCard,
    settings.useCommonCardStyle,
    settings.quoteCards,
  ]);

  useEffect(() => {
    if (nextItems.length < pageSize && items.length) {
      if (pageNumber.current < totalPages.current - 1) {
        pageNumber.current = pageNumber.current + 1;
      } else {
        pageNumber.current = 0;
      }
      fetchContent().then(({ mediaFiles, totalCount }) => {
        totalPages.current = Math.ceil(totalCount / pageSize);
        if (!totalCount) {
          mediaFiles = Array.from({ length: pageSize }, () =>
            getDefaultQuoteCardData()
          );
        } else if (totalCount < pageSize) {
          const dummyItems = Array.from({ length: pageSize - totalCount }, () =>
            getDefaultQuoteCardData()
          );
          mediaFiles = [...dummyItems, ...mediaFiles];
        }

        setNextItems((pre) => mergeAndDeduplicate(pre, mediaFiles, "_id"));
      });
    }
  }, [nextItems?.length, items?.length, fetchNextItems]);

  useEffect(() => {
    props.onSettingsChange(settings);
  }, [settings]);

  const requestNewData = () => {
    let nextItem = null;
    if (nextItems.length) {
      const nextItemsCopy = [...nextItems];
      nextItem = nextItemsCopy.shift();
      setNextItems(nextItemsCopy);
    } else {
      setFetchNextItems((pre) => !pre);
    }
    return nextItem;
  };

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  useEffect(() => {
    if (doRequestNew) {
      setDoRequestNew(false);
      const newItem = requestNewData();
      if (newItem && !items.some((item) => item._id === newItem._id)) {
        newItem["cardSettings"] = items[0].cardSettings;
        items[0] = { ...newItem };
      }
    }
  }, [items, doRequestNew]);

  const shuffle = () => {
    setItems((pre) => {
      const shuffledItems = [...pre];
      const poppedItem = shuffledItems.pop();
      shuffledItems.unshift(poppedItem);
      setDoRequestNew(true);
      return shuffledItems;
    });
  };

  const renderSettings = () => {
    return (
      <>
        <IconButton
          className="settings-icon"
          onClick={() => setDialogOpen(true)}
          size="large"
        >
          <Settings />
        </IconButton>
        <Dialog
          open={dialogOpen || false}
          onClose={() => setDialogOpen(false)}
          fullWidth
        >
          <DialogTitle>Settings</DialogTitle>
          <DialogContent className="dialog-content">
            <SocialOutputSettings
              onChange={handleSettingsChange}
              localStorageUniqueKey={SETTINGS_LOCALSTORAGE_KEY}
              defaultSettings={settings}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  return (
    <>
      <div
        className="background"
        style={{
          background: settings.backgroundColor,
          backgroundImage: settings.backgroundImage
            ? `url(${settings.backgroundImage})`
            : "none",
        }}
      ></div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {renderSettings()}
          <SocialQuotesContextProvider>
            <UvCardsParallax
              items={items}
              shuffle={shuffle}
              requestNewData={requestNewData}
              {...settings}
            />
          </SocialQuotesContextProvider>
        </div>
      )}
    </>
  );
};
export default SocialQuotes;
