import {
  ICE_SERVERS,
  ENABLE_WEBRTC_DEBUG_LOGGING,
  SIGNALING_SERVER_URL,
} from "../../../constants";

const WRTC = {
  RTCPeerConnection,
  RTCIceCandidate,
  RTCSessionDescription,
  MediaStream,
  MediaStreamTrack,
};

export function selfRetryingPeerControllerFactory({
  roomId,
  stream,
  onConnecting,
  onConnected,
  onReconnecting,
  onLiveStateChaned,
  onMessage,
}) {
  const simplePeerControllerOptions = {
    baseUrl: SIGNALING_SERVER_URL,
    EventSource: EventSource,
    wrtc: WRTC,
    iceServers: ICE_SERVERS,
    noConfirmation: true,
    tryInitiator: true,
    enableDebugLogging: ENABLE_WEBRTC_DEBUG_LOGGING,
  };

  const reference = {
    pc: new window.SimplePeerController({
      roomId: roomId,
      ...simplePeerControllerOptions,
    }),
  };

  function initialize() {
    reference.pc.on("connecting", () => {
      onConnecting();
    });
    reference.pc.on("reconnecting", () => {
      onReconnecting(false);
    });

    reference.pc.on("connected", () => {
      onConnected(reference.pc);
      reference.pc.addStream(stream);
    });

    reference.pc.on("data", (data) => {
      if (data && data.state) {
        switch (data.state) {
          case "live":
            onLiveStateChaned("live");
            break;
          case "not-live":
            onLiveStateChaned("not-live");
            break;
          default:
            onMessage(data, reference.pc);
            break;
        }
      }
    });

    reference.pc.on("disconnected", () => {
      onReconnecting(true);
      setTimeout(() => {
        if (reference.pc === null) {
          return;
        }
        reference.pc = new window.SimplePeerController({
          roomId: roomId,
          ...simplePeerControllerOptions,
        });

        initialize();
      }, 2000);
    });

  }

  initialize();

  return reference;
}
