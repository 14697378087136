import { MuiColorInput } from "mui-color-input";
import { useEffect, useReducer, useState } from "react";
import { Button, FormControl, IconButton } from "@mui/material";
import {
  MAX_BACKGROUND_IMAGE_SIZE,
  WARNING_MESSAGE,
} from "../../utils/defaultSettings";
import { Wallpaper } from "@mui/icons-material";

function reducer(state, { type, value }) {
  switch (type) {
    case "change_background_color": {
      return { ...state, color: value };
    }
    case "change_background_image": {
      return { ...state, bgImage: value };
    }
    case "change_content_color": {
      return { ...state, contentColor: value };
    }
    default: {
      throw Error(`Unknown action ${type}`);
    }
  }
}

const QuoteCardSetting = ({ defaultSetting, index, onChange, isCommon }) => {
  const [state, dispatch] = useReducer(reducer, defaultSetting);
  const [isImageSizeLarge, setIsImageSizeLarge] = useState(false);
  const handleChange = (type, value) => {
    dispatch({ type, value });
  };

  useEffect(() => {
    onChange(state, index);
  }, [state]);

  const renderControls = () => {
    return (
      <>
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <MuiColorInput
            value={state.contentColor}
            label="Quote content color"
            onChange={(newColor) => {
              handleChange("change_content_color", newColor);
            }}
          />
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ width: "100%", margin: "20px 0px" }}
        >
          <MuiColorInput
            value={state.color}
            label={`Quote Background Color`}
            onChange={(newColor) => {
              handleChange("change_background_color", newColor);
            }}
          />
        </FormControl>
        <div>
          <label htmlFor={"card-background-image" + index}>
            Quote Background image:
          </label>
          <input
            type="file"
            id={"card-background-image" + index}
            onChange={(event) => {
              const file = event.target.files[0];
              if (file) {
                if (file.size > MAX_BACKGROUND_IMAGE_SIZE) {
                  setIsImageSizeLarge(true);
                } else {
                  setIsImageSizeLarge(false);
                }
                const reader = new FileReader();
                reader.onloadend = () => {
                  const newImage = reader.result;
                  handleChange("change_background_image", newImage);
                };
                reader.readAsDataURL(file);
              }
            }}
            accept="image/*"
            style={{ display: "none" }}
          />
          <label htmlFor={"card-background-image" + index}>
            <IconButton
              component="span"
              color="primary"
              aria-label="upload"
              size="large"
            >
              <Wallpaper />
            </IconButton>
            Upload Quote Background Image
          </label>
          {isImageSizeLarge && state.bgImage && (
            <p style={{ color: "#ed6c02" }}>{WARNING_MESSAGE}</p>
          )}
          {state.bgImage && (
            <>
              <img
                src={state.bgImage}
                alt="Background Preview"
                style={{
                  width: "100%",
                  maxHeight: "150px",
                  margin: "10px 0",
                  objectFit: "cover",
                }}
              />
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  handleChange("change_background_image", null);
                }}
              >
                Remove Quote Background Image
              </Button>
            </>
          )}
        </div>
      </>
    );
  };

  return isCommon ? (
    renderControls()
  ) : (
    <fieldset style={{ margin: "20px 0px" }}>
      <legend>Card {index + 1}</legend>
      {renderControls()}
    </fieldset>
  );
};
export default QuoteCardSetting;
