import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Paper,
} from "@mui/material";
import { COLORS } from "../../utils/colors";
const ConfirmationDialog = ({
  title,
  message,
  onConfirm,
  show,
  confirmButtonText,
  onClose
}) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  useEffect(() => {
    setShowConfirmationDialog(show);
  }, [show]);
  return (
    <Dialog
      open={showConfirmationDialog}
      onClose={() => {
        setShowConfirmationDialog(false);
        onClose();
      }}
    >
      <Paper style={{ backgroundColor: COLORS.popupBackground }}>
        <DialogTitle style={{ color: COLORS.textHeader }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: COLORS.textHeader }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirm}>{confirmButtonText}</Button>
          <Button
            onClick={() => {
              setShowConfirmationDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};
export default ConfirmationDialog;
