import createStore from "redux-zero";

let currentUserInLocalStorage = null;
let savedServerConfig = null;
try {
  console.log("creating store");
  if (localStorage.getItem("currentUser")) {
    currentUserInLocalStorage = JSON.parse(localStorage.getItem("currentUser"));
  }
  if (localStorage.getItem("serverConfig")) {
    savedServerConfig = JSON.parse(localStorage.getItem("serverConfig"));
  }
} catch {}

const initialState = {
  userCounts: {},
  gameData: [],
  active_game: "",
  currentGameData: {},
  event_id: "",
  loggedInUserObj: {},
  // { [userId]: peerConnection }
  activeConnections: {},
  currentlySelectedUserId: "",
  currentlyStreamingUser: {
    userId: "",
    pc: null
  },
  mainScreenPCChannel: "",
  mainScreenPC: undefined,
};

const store = createStore({
  initialState, // Spread the central initialState
  currentUser: currentUserInLocalStorage,
  rememberMe: localStorage.getItem("rememberMe") === "true",
  serverConfig: savedServerConfig,
});

export default store;
