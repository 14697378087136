import React, { useRef, useState } from "react";
import {
  Avatar,
  Card,
  Tooltip,
  CardContent,
  Badge,
  TextField,
  Divider,
  Typography,
} from "@mui/material";
import {
  AddAPhotoOutlined as AddAPhotoOutlinedIcon,
  Close,
} from "@mui/icons-material";
import {
  getAdDescription,
  getAdInterval,
  getAdTitle,
  getCollectUserInfoAt,
  getFileMimeType,
  getHyperlink,
  getImageUri,
  validateUrl,
} from "./functions";

import "./styles.css";
import AdPreviewBox from "./AdPreviewBox";
import { COLORS } from "../../utils/colors";

export default (function ({ onChangeAdvert, initialLightShowAd }) {
  let selectedFile = useRef(null);
  const imageDataUri = getImageUri(initialLightShowAd);
  const mimeType = getFileMimeType(initialLightShowAd);
  const adInterval = getAdInterval(initialLightShowAd);
  const adTitle = getAdTitle(initialLightShowAd);
  const adText = getAdDescription(initialLightShowAd);
  const adHyperlink = getHyperlink(initialLightShowAd);
  const checkedStatus = getCollectUserInfoAt(initialLightShowAd);

  const [showPreview, setShowPreview] = useState(false);

  const delegateClickToFileElement = () =>
    document.getElementById(`selectLightShowAdObjImage`).click();

  const handleRemove = () => {
    onChangeAdvert(
      null,
      null,
      adInterval,
      0,
      true,
      adTitle,
      adText,
      adHyperlink,
      "",
      false
    );
  };

  const handleImageUpload = async (e) => {
    selectedFile.current = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile.current);
    reader.onloadend = () => {
      onChangeAdvert(
        reader.result,
        selectedFile.current,
        adInterval,
        0,
        false,
        adTitle,
        adText,
        adHyperlink,
        selectedFile.current.type,
        checkedStatus
      );
    };
  };

  return (
    <Card
      className="adRoot"
      style={{
        overflow: "unset",
        backgroundColor: COLORS.popupBackground,
      }}
    >
      <CardContent className="adsCardContent">
        <Badge
          style={{ margin: 30, marginTop: 5 }}
          overlap="rectangular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={
            <div
              className="badgeDiv"
              style={{
                display: imageDataUri ? null : "none",
              }}
              onClick={handleRemove}
            >
              <Tooltip title={"Remove"}>
                <Avatar className="closeAvatar">
                  <Close />
                </Avatar>
              </Tooltip>
            </div>
          }
        >
          <Badge
            overlap="rectangular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <div
                className="badgeDiv"
                onClick={delegateClickToFileElement}
                onChange={(e) => handleImageUpload(e)}
              >
                <input
                  id={`selectLightShowAdObjImage`}
                  hidden
                  type="file"
                  accept="image/*, video/*"
                />
                <Tooltip title={"Select Light Show Ad Image"}>
                  <Avatar className="selectAvatar">
                    <AddAPhotoOutlinedIcon />
                  </Avatar>
                </Tooltip>
              </div>
            }
          >
            <div onClick={() => imageDataUri && setShowPreview(true)}>
              {mimeType.includes("video") ? (
                <video
                  className="adMedia"
                  style={{
                    borderColor: COLORS.contactAvatar,
                  }}
                  autoPlay
                  playsInline
                  loop
                  controls={false}
                  muted
                  src={imageDataUri || "#"}
                  disableRemotePlayback={true}
                />
              ) : (
                <Avatar
                  src={imageDataUri || "#"}
                  variant={"square"}
                  className="adMedia"
                  style={{
                    borderColor: COLORS.contactAvatar,
                  }}
                  imgProps={{ style: { objectFit: "contain" } }}
                />
              )}
            </div>
          </Badge>
        </Badge>
        <Divider
          sx={{
            width: "100%",
            marginTop: 1,
            marginBottom: 1,
            backgroundColor: COLORS.textLabel,
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Ad Title
          <span
            style={{
              color: COLORS.error,
            }}
          >
            {" *"}
          </span>
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          value={adTitle}
          onChange={(e) => {
            onChangeAdvert(
              imageDataUri,
              selectedFile.current,
              adInterval,
              0,
              false,
              e.target.value,
              adText,
              adHyperlink,
              selectedFile.current ? selectedFile.current.type : mimeType,
              checkedStatus
            );
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Ad Description
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          value={adText}
          onChange={(e) => {
            onChangeAdvert(
              imageDataUri,
              selectedFile.current,
              adInterval,
              0,
              false,
              adTitle,
              e.target.value,
              adHyperlink,
              selectedFile.current ? selectedFile.current.type : mimeType,
              checkedStatus
            );
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Hyperlink
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          value={adHyperlink}
          helperText={
            adHyperlink.length > 0 &&
            !validateUrl(adHyperlink) &&
            "Invalid hyperlink"
          }
          error={adHyperlink.length > 0 && !validateUrl(adHyperlink)}
          onChange={(e) => {
            onChangeAdvert(
              imageDataUri,
              selectedFile.current,
              adInterval,
              0,
              false,
              adTitle,
              adText,
              e.target.value,
              selectedFile.current ? selectedFile.current.type : mimeType,
              checkedStatus
            );
          }}
        />
        <AdPreviewBox
          previewOpen={showPreview}
          setPreviewOpen={(status) => {
            setShowPreview(status);
          }}
          adImageDataUri={imageDataUri}
          adMimeType={mimeType}
        />
      </CardContent>
    </Card>
  );
});
