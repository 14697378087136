import React, { useRef, useState } from "react";
import {
  Avatar,
  Card,
  Tooltip,
  CardContent,
  Badge,
  TextField,
  Divider,
  Typography,
  Checkbox,
} from "@mui/material";
import {
  AddAPhotoOutlined as AddAPhotoOutlinedIcon,
  Close,
} from "@mui/icons-material";
import {
  getAdDescription,
  getAdInterval,
  getFileMimeType,
  getHyperlink,
  getImageUri,
  getSocialMediaUploadAd,
  getSocialSharingOptionsEnabled,
  validateUrl,
} from "./functions";
import "./styles.css";
import AdPreviewBox from "./AdPreviewBox";
import { COLORS } from "../../utils/colors";

export default (function ({ advertData, index, onChangeAdvert }) {
  let selectedFile = useRef(null);
  const dataUri = getImageUri(advertData);
  const mimeType = getFileMimeType(advertData);
  const advertFrequency = getAdInterval(advertData);
  const advertHyperlink = getHyperlink(advertData);
  const adText = getAdDescription(advertData);
  const checkedStatus = getSocialMediaUploadAd(advertData);
  const [showPreview, setShowPreview] = useState(false);

  const handleRemove = () => {
    onChangeAdvert(
      null,
      null,
      advertFrequency,
      index,
      true,
      "",
      adText,
      advertHyperlink,
      "",
      checkedStatus
    );
  };

  const delegateClickToFileElement = () =>
    document.getElementById(`selectSocialAdvert${index}`).click();

  const handleImageUpload = async (e) => {
    selectedFile.current = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile.current);
    reader.onloadend = () => {
      onChangeAdvert(
        reader.result,
        selectedFile.current,
        advertFrequency,
        index,
        false,
        "",
        adText,
        advertHyperlink,
        selectedFile.current.type,
        checkedStatus
      );
    };
  };

  return (
    <Card
      className="adRoot"
      style={{
        overflow: "unset",
        backgroundColor: COLORS.popupBackground,
      }}
    >
      <CardContent className="adsCardContent">
        <Badge
          style={{ margin: 30, marginTop: 5 }}
          overlap="rectangular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={
            <div
              className="badgeDiv"
              style={{
                display: dataUri ? null : "none",
              }}
              onClick={handleRemove}
            >
              <Tooltip title={"Remove"}>
                <Avatar className="closeAvatar">
                  <Close />
                </Avatar>
              </Tooltip>
            </div>
          }
        >
          <Badge
            overlap="rectangular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <div
                className="badgeDiv"
                onClick={delegateClickToFileElement}
                onChange={(e) => handleImageUpload(e)}
              >
                <input
                  id={`selectSocialAdvert${index}`}
                  hidden
                  type="file"
                  accept="image/*, video/*"
                />
                <Tooltip title={"Select In Social Feed Ad"}>
                  <Avatar className="selectAvatar">
                    <AddAPhotoOutlinedIcon />
                  </Avatar>
                </Tooltip>
              </div>
            }
          >
            <div onClick={() => dataUri && setShowPreview(true)}>
              {mimeType.includes("video") ? (
                <video
                  className="adMedia"
                  style={{
                    borderColor: COLORS.contactAvatar,
                  }}
                  autoPlay
                  playsInline
                  loop
                  controls={false}
                  muted
                  src={dataUri || "#"}
                  disableRemotePlayback={true}
                />
              ) : (
                <Avatar
                  src={dataUri || "#"}
                  variant={"square"}
                  className="adMedia"
                  style={{
                    borderColor: COLORS.contactAvatar,
                  }}
                  imgProps={{ style: { objectFit: "contain" } }}
                />
              )}
            </div>
          </Badge>
        </Badge>
        <Divider
          sx={{
            width: "100%",
            marginTop: 1,
            marginBottom: 1,
            backgroundColor: COLORS.textLabel,
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Frequency
          <span
            style={{
              color: COLORS.error,
            }}
          >
            {" *"}
          </span>
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          type="number"
          value={advertFrequency}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 2);
          }}
          onChange={(e) => {
            onChangeAdvert(
              dataUri,
              selectedFile.current,
              parseInt(e.target.value),
              index,
              false,
              "",
              adText,
              advertHyperlink,
              selectedFile.current ? selectedFile.current.type : mimeType,
              checkedStatus
            );
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Hyperlink
          <span
            style={{
              color: COLORS.error,
            }}
          >
            {" *"}
          </span>
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          value={advertHyperlink}
          helperText={
            advertHyperlink.length > 0 &&
            !validateUrl(advertHyperlink) &&
            "Invalid hyperlink"
          }
          error={advertHyperlink.length > 0 && !validateUrl(advertHyperlink)}
          onChange={(e) => {
            onChangeAdvert(
              dataUri,
              selectedFile.current,
              advertFrequency,
              index,
              false,
              "",
              adText,
              e.target.value,
              selectedFile.current ? selectedFile.current.type : mimeType,
              checkedStatus
            );
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Ad Text
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          value={adText}
          onChange={(e) => {
            onChangeAdvert(
              dataUri,
              selectedFile.current,
              advertFrequency,
              index,
              false,
              "",
              e.target.value,
              advertHyperlink,
              selectedFile.current ? selectedFile.current.type : mimeType,
              checkedStatus
            );
          }}
        />
        {index > 0 && (
          <div
            className="emailCheckbox"
            onClick={() => {
              console.log("clicked", advertData.file);
              onChangeAdvert(
                dataUri,
                selectedFile.current,
                advertFrequency,
                index,
                false,
                "",
                adText,
                advertHyperlink,
                selectedFile.current ? selectedFile.current.type : mimeType,
                !checkedStatus
              );
            }}
          >
            <Typography
              className="textLabel"
              style={{ color: COLORS.textLabel }}
            >
              Use for Social submission?
            </Typography>
            <Checkbox
              checked={checkedStatus}
              sx={{
                color: COLORS.primary,
                "&.Mui-checked": {
                  color: COLORS.primary,
                },
              }}
            />
          </div>
        )}
        <AdPreviewBox
          previewOpen={showPreview}
          setPreviewOpen={(status) => {
            setShowPreview(status);
          }}
          adImageDataUri={dataUri}
          adMimeType={mimeType}
        />
      </CardContent>
    </Card>
  );
});
