import React, { forwardRef, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./styles.css";
import Panel from "./Panel";

import {
  RESPONSIVE_GRID_MULTIPLIER as A,
  defaultUvGridLayouts,
} from "../../utils/defaultSettings";

const ResponsiveGridLayout = WidthProvider(Responsive);
const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
const CustomLayout = forwardRef((props, ref) => {
  const { initialLayouts, children } = props;
  const latestLayouts = useRef(initialLayouts);
  const onLayoutChange = (_, allLayouts) => {
    for (let breakpoint in allLayouts) {
      const layout = allLayouts[breakpoint];
      allLayouts[breakpoint] = layout.map((ob) => {
        if (ob.w === 1 || ob.h === 1) {
          ob.w = initialLayouts[breakpoint][1].w;
          ob.h = initialLayouts[breakpoint][1].h;
        }
        return ob;
      });
    }
    if (
      JSON.stringify(latestLayouts.current) !== JSON.stringify(allLayouts) &&
      JSON.stringify(defaultUvGridLayouts) !== JSON.stringify(allLayouts)
    ) {
      latestLayouts.current = allLayouts;
    }
  };

  const onDragStop = (layout) => {
    const windowWidth = window.innerWidth;
    let currentBreakpoint = "";
    for (let breakpoint in breakpoints) {
      if (windowWidth <= breakpoints[breakpoint]) {
        currentBreakpoint = breakpoint;
      } else {
        currentBreakpoint = breakpoint;
        break;
      }
    }

    const copyOfLatestLayouts = { ...latestLayouts.current };
    copyOfLatestLayouts[currentBreakpoint] = layout;
    onLayoutChange(null, copyOfLatestLayouts);
    props.onLayoutChange(latestLayouts.current);
  };

  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        layouts={props.initialLayouts}
        breakpoints={breakpoints}
        cols={{ lg: 6 * A, md: 6 * A, sm: 4 * A, xs: 2 * A, xxs: 2 * A }}
        margin={[12, 12]}
        rowHeight={10}
        onLayoutChange={onLayoutChange}
        onDragStop={onDragStop}
        onResizeStop={onDragStop}
        compactType={null}
        allowOverlap={true}
        isDraggable={props.isEditEnabled}
        isResizable={props.isEditEnabled}
      >
        {children.map((child, i) => (
          <div key={i} className="widget">
            <Panel id={i}>{child}</Panel>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
});
CustomLayout["displayName"] = "CustomLayout";
export default CustomLayout;
