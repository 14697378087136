import { useState, useEffect, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  getAllSuperScreenLayouts,
  deleteSuperScreenLayout,
} from "../Games/networkCalls/networkCalls";
import { IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { AppContext } from "../../contexts/App.Context";
import { COLORS } from "../../utils/colors";
import ConfirmationDialog from "../../components/UI/ConfirmationDialog";

const SavedLayouts = ({ eventId, onLayoutChange, selected, onUpdate }) => {
  const [savedLayouts, setSavedLayouts] = useState([{ name: "Blank", _id: 0 }]);
  const [selectedLayoutId, setSelectedLayoutId] = useState(selected);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const appContext = useContext(AppContext);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showUpdateConfirmationDialog, setShowUpdateConfirmationDialog] =
    useState(false);
  const [updateConfirmMessage, setUpdateConfirmMessage] = useState("");
  const getData = async () => {
    const data = await getAllSuperScreenLayouts(eventId);
    setSavedLayouts([{ name: "Blank", _id: 0 }, ...data]);
    setSelectedLayoutId(selected || 0);
  };
  const deleteLayout = async () => {
    await deleteSuperScreenLayout(selectedLayoutId);
    setShowConfirmationDialog(false);

    appContext.triggerToast(true, {
      type: "success",
      message: "Layout deleted successfully.",
    });
    getData();
  };
  useEffect(() => {
    getData();
  }, [eventId, selected]);
  useEffect(() => {
    const layout = savedLayouts.find(
      (layout) => layout._id === selectedLayoutId
    );

    onLayoutChange(layout);
    setSelectedLayout(layout);
  }, [selectedLayoutId]);

  const isUpdateChanges = () => {
    if (selectedLayoutId !== 0) {
      const layout = savedLayouts.find(
        (layout) => layout._id === selectedLayoutId
      );
      setUpdateConfirmMessage(
        `Any changes to ${layout?.name} will be lost, do you want to save changes?`
      );
      setShowUpdateConfirmationDialog(true);
    } else {
      setShowUpdateConfirmationDialog(false);
      setUpdateConfirmMessage("");
    }
  };

  const onChange = (e) => {
    isUpdateChanges();
    setSelectedLayoutId(e.target.value);
  };

  return (
    <>
      <FormControl
        variant="standard"
        fullWidth
        sx={{ width: "100%", margin: "20px 0px" }}
      >
        <InputLabel>Select from Saved Layouts</InputLabel>
        <Select
          variant="standard"
          value={selectedLayoutId}
          onChange={onChange}
          sx={{ color: COLORS.gray, background: "white", padding: "10px" }}
        >
          {savedLayouts.map(({ _id, name }) => (
            <MenuItem
              value={_id}
              key={_id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              {name}
              {_id ? (
                <IconButton
                  sx={{ marginLeft: 10 }}
                  onClick={() => {
                    setShowConfirmationDialog(true);
                  }}
                >
                  <Tooltip title={"Delete"}>
                    <Delete />
                  </Tooltip>
                </IconButton>
              ) : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ConfirmationDialog
        title="Delete Confirmation"
        confirmButtonText="Delete"
        message={`Are you sure you want to delete "${
          selectedLayout && selectedLayout.name ? selectedLayout.name : ""
        }"?`}
        show={showConfirmationDialog}
        onConfirm={deleteLayout}
        onClose={()=>{setShowConfirmationDialog(false);}}
      />
      {/* <ConfirmationDialog
        title="Save Changes?"
        confirmButtonText="Save Changes"
        message={updateConfirmMessage}
        show={showUpdateConfirmationDialog}
        onConfirm={() => {
          onUpdate();
          setShowUpdateConfirmationDialog(false);
        }}
        onClose={()=>{setShowUpdateConfirmationDialog(false);}}
      /> */}
    </>
  );
};
export default SavedLayouts;
