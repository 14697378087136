export const liveUsersGlobalState = {
  blurringInterval: null,
  loggedInUserObj: null,
  previewStreamObject: null,
  previewVideoElementObject: null,
  currentPreviewUserId: "",
  currentOutputUserId: "",
  onVideoElementReceived: () => {},
  reloadPreview: () => {},
  updateLiveUserStatus: () => {},
  onCurrentStreamChanged: () => {},
  assignStreamToVideo: () => {},
};
export let blurringInterval = null;
export let loggedInUserObj = null;
export let previewStreamObject = null;
export let previewVideoElementObject = null;
export let currentPreviewUserId = "";
export let currentOutputUserId = "";
export let onVideoElementReceived = () => {};
export let reloadPreview = () => {};
export let updateLiveUserStatus = () => {};
export let onCurrentStreamChanged = () => {};
export let assignStreamToVideo = () => {};
