import React, { useState, useEffect, useContext } from "react";
import Layout from "../../Layout";
import {
  Avatar,
  Typography,
  IconButton,
  Card,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { connect } from "redux-zero/react";
import { URLS } from "../../constants";
import { uvenuFetcher } from "../../utils/uvenu-fetcher";
import { Delete, Edit } from "@mui/icons-material";
import Loader from "../../components/Loader/Loader";
import "./styles.css";
import { AppContext } from "../../contexts/App.Context";
import { format } from "date-fns";
import { COLORS } from "../../utils/colors";

function EventList(props) {
  const appContext = useContext(AppContext);
  const { currentUser } = props;
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedEvent, setCurrentEvent] = useState({});
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const handleClickEventDetails = (event) =>
    navigate("/eventDetails", { state: { event: event } });

  const deleteEvent = async () => {
    setLoading(true);
    try {
      const response = await uvenuFetcher({
        method: "DELETE",
        url: URLS.DELETE_EVENT,
        headers: {
          token: `${currentUser.token}`,
        },
        body: {
          eventId: selectedEvent._id,
        },
      });
      if (response.statusCode === 200) {
        await getAllEvents();
      } else {
        setLoading(false);
        appContext.triggerToast(true, {
          type: "error",
          message: response.json.message,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getAllEvents = async (e) => {
    setLoading(true);
    try {
      const response = await uvenuFetcher({
        method: "GET",
        url: URLS.GET_ALL_EVENTS,
        headers: {
          token: `${currentUser.token}`,
        },
      });
      if (response.statusCode === 200) {
        response.json && setEvents(response.json.data);
        setLoading(false);
      } else {
        setLoading(false);
        appContext.triggerToast(true, {
          type: "error",
          message: response.json.message,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }

    getAllEvents();
  }, []);

  const handleCloseConfirmationModal = () =>
    setConfirmationModalVisibility(false);

  const getEventStatus = (eventInfo) => {
    const currentDate = new Date().getTime();
    const startDate = new Date(eventInfo.startDate).getTime() * 1000;
    const endDate = new Date(eventInfo.endDate) * 1000;
    if (startDate > currentDate) return "Upcoming";
    else if (currentDate < endDate) return "In Progress";
    else return "Completed";
  };

  const formatDate = (date) =>
    format(new Date(date * 1000), "dd MMM yyyy hh:mm a");

  const EventCard = ({ data }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <div
        className="eventCardGridItem"
        onMouseEnter={(e) => setIsHovered(true)}
        onMouseLeave={(e) => setIsHovered(false)}
      >
        <div className="elActions">
          <Typography
            style={{
              fontSize: 12,
              padding: 5,
              borderRadius: 5,
              color:
                getEventStatus(data) === "In Progress"
                  ? COLORS.inProgressColor
                  : getEventStatus(data) === "Upcoming"
                  ? COLORS.upcomingColor
                  : COLORS.completedColor,
              backgroundColor:
                getEventStatus(data) === "In Progress"
                  ? COLORS.inProgressBackground
                  : getEventStatus(data) === "Upcoming"
                  ? COLORS.upcomingBackground
                  : COLORS.completedBackground,
            }}
          >
            {getEventStatus(data)}
          </Typography>
          <IconButton
            onClick={() => navigate("/editEvent", { state: { event: data } })}
            className="actionIconStyle"
          >
            <Tooltip title={"Edit Event"}>
              <Edit
                className="actionIconStyle"
                style={{
                  color: COLORS.gray,
                  "&:hover": {
                    color: COLORS.textHeader,
                  },
                }}
              />
            </Tooltip>
          </IconButton>

          <IconButton
            onClick={() => {
              setConfirmationModalVisibility(true);
              setCurrentEvent(data);
            }}
            className="actionIconStyle"
          >
            <Tooltip title={"Delete Event"}>
              <Delete
                className="actionIconStyle"
                style={{
                  color: COLORS.gray,
                  "&:hover": {
                    color: COLORS.error,
                  },
                }}
              />
            </Tooltip>
          </IconButton>
        </div>

        <Card
          elevation={2}
          className="elCardStyle"
          onClick={() =>
            handleClickEventDetails({ event: data, token: currentUser.token })
          }
          style={{
            borderColor: isHovered ? COLORS.primary : "transparent",
            backgroundColor: COLORS.popupBackground,
            aspectRatio: 1,
          }}
        >
          <Avatar
            variant="square"
            src={data.eventImageUrl}
            sx={{ borderRadius: "24px", width: "50%", height: "50%" }}
            imgProps={{
              display: "none",
            }}
          />
          <div className="eventCardText">
            <Typography
              className="eventName"
              style={{
                color: isHovered ? COLORS.primary : COLORS.textHeader,
                fontSize: "1.5em",
              }}
            >
              {data.name}
            </Typography>
            <div className="eventInfo">
              <Typography style={{ color: COLORS.textLabel, fontSize: "1em" }}>
                From
              </Typography>
              <Typography style={{ color: COLORS.textHeader, fontSize: "1em" }}>
                {formatDate(data.startDate)}
              </Typography>
            </div>
            <div className="eventInfo">
              <Typography style={{ color: COLORS.textLabel, fontSize: "1em" }}>
                To
              </Typography>
              <Typography style={{ color: COLORS.textHeader, fontSize: "1em" }}>
                {formatDate(data.endDate)}
              </Typography>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <Layout currentUser={currentUser} title="EVENTS">
      <Dialog
        open={confirmationModalVisibility}
        onClose={handleCloseConfirmationModal}
      >
        <Paper style={{ backgroundColor: COLORS.popupBackground }}>
          <DialogTitle style={{ color: COLORS.textHeader }}>
            {"Delete Event Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: COLORS.textHeader }}>
              {`Are you sure you want to delete event ${selectedEvent.name}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="deleteButton"
              style={{
                color: COLORS.textHeader,
                backgroundColor: COLORS.error,
                borderColor: COLORS.error,
                "&:hover": {
                  color: COLORS.error,
                },
              }}
              onClick={() => {
                deleteEvent();
                handleCloseConfirmationModal();
              }}
            >
              Delete
            </Button>
            <Button
              className="cancelButton"
              style={{
                backgroundColor: "transparent",
                color: COLORS.primary,
                borderColor: COLORS.primary,
              }}
              onClick={handleCloseConfirmationModal}
            >
              Cancel
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <div className="eventCardsGrid">
        {isLoading ? (
          <Loader />
        ) : (
          events.map((eventData, i) => (
            <EventCard data={eventData} key={eventData._id} />
          ))
        )}
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};
export default connect(mapStateToProps, null)(EventList);
