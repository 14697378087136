import React, { useContext, useEffect, useState } from "react";
import Modal from "./Modal";
import { Card, CardContent, Link, TextField, Typography } from "@mui/material";
import "./styles.css";
import { AppContext } from "../../../contexts/App.Context";
import { COLORS } from "../../../utils/colors";

export default (function ({
  modalVisible,
  event,
  handleContinue,
  initialUsername,
}) {
  const appContext = useContext(AppContext);
  const [username, setUsername] = useState("");

  useEffect(() => {
    setUsername(initialUsername);
  }, [initialUsername]);

  const onChangeValues = (newName) => {
    setUsername(newName);
  };

  return (
    <Modal
      open={modalVisible}
      event={event}
      handleContinue={() => {
        if (username.trim() === "") {
          appContext.triggerToast(true, {
            type: "warning",
            message: "Please enter your name",
          });
        } else {
          handleContinue(username);
        }
      }}
    >
      <div className="gupParentDiv">
        <Card
          className="gupRoot"
          // style={{ backgroundColor: COLORS.popupBackground }}
        >
          <CardContent className="adsCardContent">
            <Typography
              className="guestInfo"
              // style={{ color: COLORS.textHeader }}
            >
              {"Please enter your name"}
            </Typography>
            <Typography
              className="textLabel"
              // style={{ color: COLORS.textLabel }}
            >
              Full Name
              <span
                style={{
                  color: COLORS.error,
                }}
              >
                {" *"}
              </span>
            </Typography>
            <TextField
              size="medium"
              variant="standard"
              hiddenLabel
              inputProps={{
                maxLength: 20,
                style: {
                  borderRadius: 4,
                  marginBottom: 10,
                },
              }}
              fullWidth
              defaultValue={initialUsername}
              autoFocus
              id="guestusername"
              InputLabelProps={{ style: { color: COLORS.textHeader } }}
              onChange={(e) => {
                onChangeValues(e.target.value);
              }}
            />
            <Typography
              className="privacyInfo"
              // style={{ color: COLORS.textLabel }}
            >
              {"By continuing you agree to\nUvenu LLC’s "}
              <Link
                style={{ color: "blue" }}
                href={"https://www.uvenu.com/uvenu-terms-and-conditions"}
                target="_blank"
                underline="hover"
              >
                Privacy Policy
              </Link>
              {" and "}
              <Link
                style={{ color: "blue" }}
                href={"https://www.uvenu.com/uvenu-terms-and-conditions"}
                target="_blank"
                underline="hover"
              >
                Terms
              </Link>
              {"."}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Modal>
  );
});
