import { createTheme, responsiveFontSizes } from "@mui/material";
import { COLORS } from "./utils/colors";

let theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      light: COLORS.gray,
      main: COLORS.secondary,
    },
  },
  typography: {
    color: COLORS.textHeader,
    fontFamily: "Inter, sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    button: {
      textTransform: "none",
      color: COLORS.textHeader,
    },
  },
});

theme.spacing(4);

theme = responsiveFontSizes(theme);

export default theme;
