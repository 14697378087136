import React, { useRef, useState } from "react";
import {
  Avatar,
  Card,
  Tooltip,
  CardContent,
  Badge,
  TextField,
  Divider,
  Typography,
  Checkbox,
} from "@mui/material";
import {
  AddAPhotoOutlined as AddAPhotoOutlinedIcon,
  Close,
} from "@mui/icons-material";
import {
  getAdDescription,
  getAdInterval,
  getAdTitle,
  getCollectUserInfoAt,
  getFileMimeType,
  getHyperlink,
  getImageUri,
  validateUrl,
} from "./functions";

import "./styles.css";
import AdPreviewBox from "./AdPreviewBox";
import { COLORS } from "../../utils/colors";

export default (function ({ onChangeAdvert, initialPostGameAd }) {
  let selectedFile = useRef(null);
  const imageDataUri = getImageUri(initialPostGameAd);
  const mimeType = getFileMimeType(initialPostGameAd);
  const adInterval = getAdInterval(initialPostGameAd);
  const adTitle = getAdTitle(initialPostGameAd);
  const adText = getAdDescription(initialPostGameAd);
  const adHyperlink = getHyperlink(initialPostGameAd);
  const checkedStatus = getCollectUserInfoAt(initialPostGameAd);
  const headerText =
    initialPostGameAd.emailEntryConfigForGames?.headerText || "";
  const subHeaderText =
    initialPostGameAd.emailEntryConfigForGames?.subHeaderText || "";
  const [showPreview, setShowPreview] = useState(false);

  const delegateClickToFileElement = () =>
    document.getElementById(`selectPostGameAdObjImage`).click();

  const handleRemove = () => {
    onChangeAdvert(
      null,
      null,
      adInterval,
      0,
      true,
      adTitle,
      adText,
      adHyperlink,
      "",
      false
    );
  };

  const handleImageUpload = async (e) => {
    selectedFile.current = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile.current);
    reader.onloadend = () => {
      onChangeAdvert(
        reader.result,
        selectedFile.current,
        adInterval,
        0,
        false,
        adTitle,
        adText,
        adHyperlink,
        selectedFile.current.type,
        checkedStatus
      );
    };
  };

  return (
    <Card
      className="adRoot"
      style={{
        overflow: "unset",
        backgroundColor: COLORS.popupBackground,
      }}
    >
      <CardContent className="adsCardContent">
        <Badge
          style={{ margin: 30, marginTop: 5 }}
          overlap="rectangular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={
            <div
              className="badgeDiv"
              style={{
                display: imageDataUri ? null : "none",
              }}
              onClick={handleRemove}
            >
              <Tooltip title={"Remove"}>
                <Avatar className="closeAvatar">
                  <Close />
                </Avatar>
              </Tooltip>
            </div>
          }
        >
          <Badge
            overlap="rectangular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <div
                className="badgeDiv"
                onClick={delegateClickToFileElement}
                onChange={(e) => handleImageUpload(e)}
              >
                <input
                  id={`selectPostGameAdObjImage`}
                  hidden
                  type="file"
                  accept="image/*, video/*"
                />
                <Tooltip title={"Select Post Game Ad Image"}>
                  <Avatar className="selectAvatar">
                    <AddAPhotoOutlinedIcon />
                  </Avatar>
                </Tooltip>
              </div>
            }
          >
            <div onClick={() => imageDataUri && setShowPreview(true)}>
              {mimeType.includes("video") ? (
                <video
                  className="adMedia"
                  style={{
                    borderColor: COLORS.contactAvatar,
                  }}
                  autoPlay
                  playsInline
                  loop
                  controls={false}
                  muted
                  src={imageDataUri || "#"}
                  disableRemotePlayback={true}
                />
              ) : (
                <Avatar
                  src={imageDataUri || "#"}
                  variant={"square"}
                  className="adMedia"
                  style={{
                    borderColor: COLORS.contactAvatar,
                  }}
                  imgProps={{ style: { objectFit: "contain" } }}
                />
              )}
            </div>
          </Badge>
        </Badge>
        <Divider
          sx={{
            width: "100%",
            marginTop: 1,
            marginBottom: 1,
            backgroundColor: COLORS.textLabel,
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Ad Title
          <span
            style={{
              color: COLORS.error,
            }}
          >
            {" *"}
          </span>
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          value={adTitle}
          onChange={(e) => {
            onChangeAdvert(
              imageDataUri,
              selectedFile.current,
              adInterval,
              0,
              false,
              e.target.value,
              adText,
              adHyperlink,
              selectedFile.current ? selectedFile.current.type : mimeType,
              checkedStatus
            );
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Ad Description
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          value={adText}
          onChange={(e) => {
            onChangeAdvert(
              imageDataUri,
              selectedFile.current,
              adInterval,
              0,
              false,
              adTitle,
              e.target.value,
              adHyperlink,
              selectedFile.current ? selectedFile.current.type : mimeType,
              checkedStatus
            );
          }}
        />
        <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
          Hyperlink
        </Typography>
        <TextField
          fullWidth
          size="medium"
          variant="filled"
          hiddenLabel
          inputProps={{
            style: {
              fontSize: 14,
              color: COLORS.textHeader,
              "&:-webkit-autofill": {
                "transition-delay": 9999999999,
              },
              backgroundColor: COLORS.inputBackground,
              borderRadius: 4,
              marginBottom: 10,
            },
          }}
          value={adHyperlink}
          helperText={
            adHyperlink.length > 0 &&
            !validateUrl(adHyperlink) &&
            "Invalid hyperlink"
          }
          error={adHyperlink.length > 0 && !validateUrl(adHyperlink)}
          onChange={(e) => {
            onChangeAdvert(
              imageDataUri,
              selectedFile.current,
              adInterval,
              0,
              false,
              adTitle,
              adText,
              e.target.value,
              selectedFile.current ? selectedFile.current.type : mimeType,
              checkedStatus
            );
          }}
        />
        {imageDataUri && (
          <>
            <div
              className="emailCheckbox"
              onClick={() => {
                onChangeAdvert(
                  imageDataUri,
                  selectedFile.current,
                  adInterval,
                  0,
                  false,
                  adTitle,
                  adText,
                  adHyperlink,
                  selectedFile.current ? selectedFile.current.type : mimeType,
                  !checkedStatus
                );
              }}
            >
              <Typography
                className="textLabel"
                style={{ color: COLORS.textLabel }}
              >
                Require Email Address:
              </Typography>
              <Checkbox
                checked={checkedStatus}
                sx={{
                  color: COLORS.primary,
                  "&.Mui-checked": {
                    color: COLORS.primary,
                  },
                }}
              />
            </div>
            <Typography
              className="textLabel"
              style={{ color: COLORS.textLabel }}
            >
              Header message
              {checkedStatus ? (
                <span
                  style={{
                    color: COLORS.error,
                  }}
                >
                  {" *"}
                </span>
              ) : null}
            </Typography>
            <TextField
              fullWidth
              size="medium"
              variant="filled"
              hiddenLabel
              inputProps={{
                style: {
                  fontSize: 14,
                  color: COLORS.textHeader,
                  "&:-webkit-autofill": {
                    "transition-delay": 9999999999,
                  },
                  backgroundColor: COLORS.inputBackground,
                  borderRadius: 4,
                  marginBottom: 10,
                },
              }}
              value={headerText}
              onChange={(e) => {
                onChangeAdvert(
                  imageDataUri,
                  selectedFile.current,
                  adInterval,
                  0,
                  false,
                  adTitle,
                  adText,
                  adHyperlink,
                  selectedFile.current ? selectedFile.current.type : mimeType,
                  checkedStatus,
                  e.target.value,
                  subHeaderText
                );
              }}
            />
            <Typography
              className="textLabel"
              style={{ color: COLORS.textLabel }}
            >
              Sub-header message
            </Typography>
            <TextField
              fullWidth
              size="medium"
              variant="filled"
              hiddenLabel
              inputProps={{
                style: {
                  fontSize: 14,
                  color: COLORS.textHeader,
                  "&:-webkit-autofill": {
                    "transition-delay": 9999999999,
                  },
                  backgroundColor: COLORS.inputBackground,
                  borderRadius: 4,
                  marginBottom: 10,
                },
              }}
              value={subHeaderText}
              onChange={(e) => {
                onChangeAdvert(
                  imageDataUri,
                  selectedFile.current,
                  adInterval,
                  0,
                  false,
                  adTitle,
                  adText,
                  adHyperlink,
                  selectedFile.current ? selectedFile.current.type : mimeType,
                  checkedStatus,
                  headerText,
                  e.target.value
                );
              }}
            />
          </>
        )}
        <AdPreviewBox
          previewOpen={showPreview}
          setPreviewOpen={(status) => {
            setShowPreview(status);
          }}
          adImageDataUri={imageDataUri}
          adMimeType={mimeType}
        />
      </CardContent>
    </Card>
  );
});
