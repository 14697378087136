import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Typography,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CDN_URLS, URLS } from "../../constants";
import { uvenuFetcher } from "../../utils/uvenu-fetcher";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";
import UvenuLogo from "../../assets/uvenu_logo.png";
import { AppContext } from "../../contexts/App.Context";
import { COLORS } from "../../utils/colors";
import "./index.css";

function LoginForm(props) {
  const appContext = useContext(AppContext);
  const { currentUser, setCurrentUser } = props;
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) navigate("/events");
  }, [currentUser]);

  const doLogin = async (e) => {
    e.preventDefault();
    if (userId === "" || password === "") {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Please fill all the fields",
      });
      return;
    }

    setLoading(true);
    const payload = { userId, password };
    try {
      const response = await uvenuFetcher({
        method: "POST",
        url: URLS.ADMIN_LOGIN,
        body: payload,
      });
      if (response.statusCode === 200) {
        appContext.triggerToast(true, {
          type: "success",
          message: "Login successful",
        });

        setTimeout(() => {
          const data = {
            email: response.json.userId,
            token: response.json.token,
          };
          setCurrentUser(data);
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
        appContext.triggerToast(true, {
          type: "error",
          message: response.json.message,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        doLogin(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [userId, password]);

  return (
    <Grid container justifyContent="center">
      <div className="loginFormContainer">
        <img src={UvenuLogo} alt={"logo"} className="loginLogo" />

        <div
          className="loginForm"
          style={{
            backgroundColor: COLORS.popupBackground,
          }}
        >
          <Typography
            className="headerText"
            style={{ color: COLORS.textHeader }}
          >
            Admin Portal Login
          </Typography>

          <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
            Username<span style={{ color: COLORS.error }}>{" *"}</span>
          </Typography>
          <TextField
            fullWidth
            size="medium"
            variant="filled"
            hiddenLabel
            type="text"
            placeholder="username here"
            onChange={(e) => setUserId(e.target.value)}
            inputProps={{
              style: {
                min: 0,
                color: COLORS.textHeader,
                marginTop: 10,
                backgroundColor: COLORS.inputBackground,
                borderRadius: 4,
                // "&:-webkit-autofill": {
                //   transitionDelay: 9999999999,
                // },
              },
            }}
          />

          <Typography className="textLabel" style={{ color: COLORS.textLabel }}>
            Password<span style={{ color: COLORS.error }}>{" *"}</span>
          </Typography>
          <TextField
            fullWidth
            size="medium"
            variant="filled"
            hiddenLabel
            type="password"
            placeholder="password here"
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{
              style: {
                min: 0,
                color: COLORS.textHeader,
                marginTop: 10,
                backgroundColor: COLORS.inputBackground,
                borderRadius: 4,
                // "&:-webkit-autofill": {
                //   transitionDelay: 9999999999,
                // },
              },
            }}
          />

          <Button
            onClick={(e) => doLogin(e)}
            onSubmit={(e) => doLogin(e)}
            disabled={isLoading}
            variant="contained"
            className="loginButton"
            style={{ color: COLORS.textHeader }}
          >
            {isLoading ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              "Login"
            )}
          </Button>
        </div>
      </div>
    </Grid>
  );
}

function LoginFormWrapper(props) {
  return (
    <>
      <Grid
        container
        className="loginMain"
        style={{
          backgroundColor: COLORS.appBackground,
        }}
      >
        <img
          src={CDN_URLS.SHADOW_BACKGROUND}
          alt="bg"
          className="backgroundShadowImage"
          style={{ width: "100%" }}
        />
        <Grid item xs={4} className="loginSubContainer">
          <LoginForm {...props} />
        </Grid>
      </Grid>
    </>
  );
}

export default connect(
  (state) => ({ currentUser: state.currentUser, rememberMe: state.rememberMe }),
  actions
)(LoginFormWrapper);
