import { uvenuFetcher } from "./uvenu-fetcher";
import {
  URLS,
  updateAppInsightsConnectionString,
  updateCDNUrls,
  updateICEServers,
  updateSignallingUrl,
} from "../constants";

export const updateSignallingConfigs = async (props) => {
  const { serverConfig } = props;

  if (serverConfig) {
    // const currentTime = new Date().getTime();
    // const refreshConfigByUtc = new Date(
    //   serverConfig.refreshConfigByUtc
    // ).getTime();

    // if (currentTime < refreshConfigByUtc) {
    //   updateConfigs(serverConfig);
    //   return;
    // }

    updateConfigs(serverConfig);
  }
  const response = await uvenuFetcher({
    method: "GET",
    url: URLS.SERVER_CONFIG_URL,
  });
  if (response.statusCode === 200 && response.json) {
    const config = response.json.data;
    localStorage.setItem("serverConfig", JSON.stringify(config));
    updateConfigs(config);
  }
};

const updateConfigs = (config) => {
  updateICEServers(config.iceServers);
  updateSignallingUrl(config.signalingServerUrl);
  updateCDNUrls(config.cdnContentBaseUrl);
  if (config.appInsightsConnectionString) {
    updateAppInsightsConnectionString(config.appInsightsConnectionString);
  }
};
