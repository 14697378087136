import { memo } from "react";

const SolidColor = memo(({ color }) => {
  return (
    <div
      style={{
        backgroundColor: color || "black",
        display: "flex",
        position: "absolute",
        inset: 0,
      }}
    ></div>
  );
});
export default SolidColor;
