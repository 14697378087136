import React, { useState } from "react";
import CustomModal from "../BrandingOverlayManager/Modal";
import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import {
  getEnableSocialSharingLinks,
  getGalleryEnabled,
  getHashTags,
  getItemBackgroundColor,
  getRecordingEnabled,
  getStillsEnabled,
  getVideoDuration,
} from "./functions";
import { COLORS } from "../../utils/colors";
import "../BrandingOverlayManager/styles.css";
import { MuiColorInput } from "mui-color-input";

export default function ({
  modalVisible,
  handleCloseModal,
  savedSettings,
  setSavedSettings,
}) {
  let socialSubmissionSettings = savedSettings;

  const [videoDuration, setVideoDuration] = useState(
    getVideoDuration(savedSettings)
  );

  const [stillsEnabled, setStillsEnabled] = useState(
    getStillsEnabled(savedSettings)
  );
  const [recordingEnabled, setRecordingEnabled] = useState(
    getRecordingEnabled(savedSettings)
  );
  const [galleryEnabled, setGalleryEnabled] = useState(
    getGalleryEnabled(savedSettings)
  );

  const [enableSocialSharingLinks, setEnableSocialSharingLinks] = useState(
    getEnableSocialSharingLinks(savedSettings)
  );

  const [hashTags, setHashTags] = useState(getHashTags(savedSettings));

  const [selectedColor, setSelectedColor] = useState(
    getItemBackgroundColor(savedSettings)
  );

  const [
    showLocationForBulkUploadedMedia,
    setShowLocationForBulkUploadedMedia,
  ] = useState(savedSettings?.showLocationForBulkUploadedMedia || false);
  return (
    <CustomModal
      open={modalVisible}
      handleClose={() => {
        handleCloseModal();
      }}
      handleSubmit={() => {
        setSavedSettings(socialSubmissionSettings);
        handleCloseModal();
      }}
      title={"Social submission settings"}
    >
      <div className="advertParentDiv">
        <Card
          className="backgroundRoot"
          style={{ backgroundColor: COLORS.popupBackground }}
        >
          <CardContent>
            <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
              Media selection options:
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  if (stillsEnabled && !recordingEnabled && !galleryEnabled)
                    return;
                  setStillsEnabled(!stillsEnabled);
                  socialSubmissionSettings.stillsEnabled = !stillsEnabled;
                }}
              >
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Stills
                </Typography>
                <Checkbox
                  checked={stillsEnabled}
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  if (recordingEnabled && !stillsEnabled && !galleryEnabled)
                    return;
                  setRecordingEnabled(!recordingEnabled);
                  socialSubmissionSettings.recordingEnabled = !recordingEnabled;
                }}
              >
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Recording
                </Typography>
                <Checkbox
                  checked={recordingEnabled}
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  if (galleryEnabled && !stillsEnabled && !recordingEnabled)
                    return;
                  setGalleryEnabled(!galleryEnabled);
                  socialSubmissionSettings.galleryEnabled = !galleryEnabled;
                }}
              >
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Gallery
                </Typography>
                <Checkbox
                  checked={galleryEnabled}
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
              </div>
            </div>
            <Divider
              sx={{
                width: "100%",
                marginTop: 1,
                marginBottom: 1,
                backgroundColor: COLORS.textLabel,
              }}
            />

            {recordingEnabled && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Video recording limit(5-90):
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <TextField
                    variant="filled"
                    hiddenLabel
                    inputProps={{
                      style: {
                        fontSize: 14,
                        color: COLORS.textHeader,
                        "&:-webkit-autofill": {
                          "transition-delay": 9999999999,
                        },
                        backgroundColor: COLORS.inputBackground,
                        borderRadius: 4,
                      },
                    }}
                    sx={{ width: 60 }}
                    onInput={(e) => {
                      e.target.value = Math.max(
                        5,
                        Math.min(90, parseInt(e.target.value))
                      )
                        .toString()
                        .slice(0, 3);
                    }}
                    value={videoDuration}
                    id="duration"
                    placeholder="15 to 60 seconds"
                    type="number"
                    onChange={(e) => {
                      setVideoDuration(parseInt(e.target.value));
                      socialSubmissionSettings.videoDuration = parseInt(
                        e.target.value
                      );
                    }}
                  />
                  <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                    secs
                  </Typography>
                </div>
              </div>
            )}
            {recordingEnabled && (
              <Divider
                sx={{
                  width: "100%",
                  marginTop: 1,
                  marginBottom: 1,
                  backgroundColor: COLORS.textLabel,
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => {
                setEnableSocialSharingLinks(!enableSocialSharingLinks);
                socialSubmissionSettings.enableSocialSharingLinks =
                  !enableSocialSharingLinks;
              }}
            >
              <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                Enable social sharing options?
              </Typography>
              <Checkbox
                checked={enableSocialSharingLinks}
                sx={{
                  color: COLORS.primary,
                  "&.Mui-checked": {
                    color: COLORS.primary,
                  },
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => {
                setShowLocationForBulkUploadedMedia(
                  !showLocationForBulkUploadedMedia
                );
                socialSubmissionSettings.showLocationForBulkUploadedMedia =
                  !showLocationForBulkUploadedMedia;
              }}
            >
              <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                Show location for bulk uploaded media?
              </Typography>
              <Checkbox
                checked={showLocationForBulkUploadedMedia}
                sx={{
                  color: COLORS.primary,
                  "&.Mui-checked": {
                    color: COLORS.primary,
                  },
                }}
              />
            </div>
            <Divider
              sx={{
                width: "100%",
                marginTop: 1,
                marginBottom: 1,
                backgroundColor: COLORS.textLabel,
              }}
            />

            <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
              {"Hashtag(#)'s while social sharing"}
            </Typography>
            <TextField
              variant="filled"
              value={hashTags}
              id="hashtags"
              placeholder="enter seprating with spaces"
              fullWidth
              hiddenLabel
              inputProps={{
                style: {
                  fontSize: 14,
                  color: COLORS.textHeader,
                  "&:-webkit-autofill": {
                    "transition-delay": 9999999999,
                  },
                  backgroundColor: COLORS.inputBackground,
                  borderRadius: 4,
                },
              }}
              onChange={(e) => {
                setHashTags(e.target.value);
                socialSubmissionSettings.hashTags = e.target.value;
              }}
            />

            <Divider
              sx={{
                width: "100%",
                marginTop: 1,
                marginBottom: 1,
                backgroundColor: COLORS.textLabel,
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                Social Feed Card Background Color
              </Typography>
              <MuiColorInput
                sx={{
                  backgroundColor: COLORS.inputBackground,
                  borderRadius: 2,
                }}
                inputProps={{
                  style: { color: COLORS.textHeader },
                }}
                fullWidth
                value={selectedColor}
                onChange={(newColor) => {
                  setSelectedColor(newColor);
                  socialSubmissionSettings.itemBackgroundColor = newColor;
                }}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    </CustomModal>
  );
}
